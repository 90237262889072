import React from 'react';

export default ({ items }) => (
    <div className=" ListPainelComponent headerListPainelComponent ">
        {items.map((itemCollum, idx) => (
            <div
                key={idx}
                className={`col-${itemCollum.size}`}
                onClick={(e) => itemCollum.callback()}
            >
                {itemCollum.label}
            </div>
        ))}
    </div>
);
