/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import TitleComponent from '~/components/TitleComponent/TitleComponent';

/**
 * @Name: Modal
 * @Data: 2020
 * @Desc: Modal que se abre de forma suspensa.
 * @props:
 *
 */
const ModalComponent = (props) => {
  // Recebe as propriedades
  const {
    title,
    action,
    buttonText,
    message,
    children,
    id,
    size,
    modalType = 'modal-dialog ',
    style,
    className
  } = props;

  const [modalClass, setModal] = useState(modalType);

  const modal = useRef();

  useEffect(() => {
    setModal(`${modalType} ${size || ''}`);
  }, [size, modalType]);

  return (
    <>
      <div
        className={className + " modal fade ModalDefault"}
        id={id || 'ModalDefault'}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="ModalDefaultLabel"
        aria-hidden="true"
        style={style}
      >
        <div className={modalClass} role="document">
          <div ref={modal} className="modal-content">
            {children ? (
              <>{children}</>
            ) : (
              <>
                <div className="modal-header">
                  {title && (
                    <TitleComponent
                      title={title || 'Aviso'}
                      // subtitle={subtittle}
                    />
                  )}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">{message}</div>
                <div className="modal-footer">
                  <button
                    hidden={!buttonText}
                    type="button"
                    className="btn btn-primary"
                    onClick={action}
                    data-dismiss="modal"
                  >
                    {buttonText}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalComponent;
