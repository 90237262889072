import { extendObservable } from 'mobx';
import UserModel from './UserModel';
import AddressModel from './AddressModel';
import { entityTypes } from '~/helpers/utils/Selects';
import { validateEmail, validateCNPJ, validateCPF } from '~/helpers/utils/Validators';
import BaseModel from './BaseModel';
import CustomerType from './CustomerTypeModel';

/**Modelo que guarda infos dos customers */
class CustomerModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.registration = data.registration;
    this.entity = data.entity || entityTypes[0].value;
    this.gender = data.gender !== undefined ? data.gender : 'OUTROS';
    this.maritalStatus = data.maritalStatus !== undefined ? data.maritalStatus : 'NA';
    this.birthdate = data.birthdate;
    this.document = data.document;
    this.phone = data.phone;
    this.mobile = data.mobile;
    this.stateRegistration = data.stateRegistration;
    this.user = new UserModel(data.user);
    this.customerType = new CustomerType(data.customerType);
    this.shippingClass = data.shippingClass;
    this.availablePLP = data.availablePLP;
    this.exempt = data.exempt;
    if (this.exempt) this.stateRegistration = "";
    extendObservable(this, {
      addresses: data.addresses ? data.addresses.map((address) => new AddressModel(address)) : []
    });
  }

  get isValid() {
    if (this.firstName.length < 3) return false;
    if (this.lastName.length < 3) return false;
    if (this.user.password.length < 6) return false;
    if (this.user.username.length < 3) return false;
    if (!validateEmail(this.user.email).isValid) return false;
    if (this.isPJ) {
      if (!validateCNPJ(this.document).isValid) return false;
      if (this.registration.length < 3) return false;
    } else if (!validateCPF(this.document).isValid) return false;
    return true;
  }

  setPJ() {
    this.entity = entityTypes[1].value;
  }

  setPF() {
    this.entity = entityTypes[0].value;
  }

  get currentAddress() {
    return this.addresses.length > 0 ? this.addresses[0] : {};
  }

  get isPJ() {
    return this.entity === entityTypes[1].value;
  }

  /**Retorna o nome completo */
  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get email() {
    return this.user.email;
  }

  get entityName() {
    return this.entity === entityTypes[1].value ? entityTypes[1].label : entityTypes[0].label;
  }
}

export default CustomerModel;
