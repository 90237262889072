import BaseModel from './BaseModel';

class InterestRateModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.installment = data.installment;
    this.interest = data.interest * 100;
  }
}

export default InterestRateModel;

