import React, { useState } from 'react';
import HeaderListComponent from '~/components/HeaderListComponent';
import { ModalIdsEnum } from '~/helpers/utils/Enums';
import ModalConfirmationComponent from '~/components/ModalConfirmationComponent/ModalConfirmationComponent';

const HeaderListProductComponent = (props) => {
  const { date = [], onDelete, handleClickLoadingProductLook } = props;
  const [productLook, setProductLook] = useState('');

  const items = () => [
    { size: '11', callback: (e) => {}, label: 'NOME' },
    { size: '1', callback: (e) => {}, label: '' },
  ];

  const openModalDeleteProductLook = (element) => {
    setProductLook(element);
    window.$(`#${ModalIdsEnum.DELETE_PRODUCT_KIT}`).modal('show');
  };

  const deleteProductLook = () => {
    onDelete(productLook);
    window.$(`#${ModalIdsEnum.DELETE_PRODUCT_KIT}`).modal('hide');
  };

  return (
    <>
      <HeaderListComponent items={items()} />
      {date.length > 0 ? (
        date.map((element) => (
          <div className="ListProductComponent" key={element.productUuid}>
            <div
              className="col-11"
              onClick={() => handleClickLoadingProductLook(element)}
            >
              {element.productName}
            </div>
            <div className="col-1">
              <i
                className="fas fa-trash"
                onClick={() => openModalDeleteProductLook(element)}
              />
            </div>
          </div>
        ))
      ) : (
        <div />
      )}
      <ModalConfirmationComponent
        id={ModalIdsEnum.DELETE_PRODUCT_KIT}
        subtitle={[
          'Você deseja retirar o produto ',
          <b>{productLook.productName}</b>,
          ' da lista do look?',
        ]}
        onYesClick={() => deleteProductLook()}
      />
    </>
  );
};

export default HeaderListProductComponent;
