import { onlyNumbers } from '~/helpers/utils/Functions';
import BaseModel from '../BaseModel';

class BranchModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.addressComplement = data.addressComplement;
    this.addressName = data.addressName;
    this.block = data.block;
    this.city = data.city;
    this.country = data.country;
    this.document = onlyNumbers(data.document);
    this.email = data.email;
    this.endingZipCode = data.endingZipCode;
    this.externalId = data.externalId;
    this.hasDelivery = data.hasDelivery || false;
    this.hasPickup = data.hasPickup || false;
    this.holidayOpeningHours = data.holidayOpeningHours;
    this.isDistributionCenter = data.isDistributionCenter;
    this.isEmployeesOnly = data.isEmployeesOnly;
    this.isVisibleOnSite = data.isVisibleOnSite;
    this.name = data.name;
    this.number = data.number;
    this.openingHours = data.openingHours;
    this.phone = data.phone;
    this.phone2 = data.phone2;
    this.refAddress = data.refAddress;
    this.saturdayOpeningHours = data.saturdayOpeningHours;
    this.startingZipcode = data.startingZipcode;
    this.state = data.state;
    this.street = data.street;
    this.sundayOpeningHours = data.sundayOpeningHours;
    this.url = data.url;
    this.uuid = data.uuid;
    this.zipcode = onlyNumbers(data.zipcode);
    this.merchant = data.merchant;
    this.type = data.type;
  }
}

export default BranchModel;
