import { extendObservable } from 'mobx';

import ShippingServiceAPI from '../services/ShippingServiceAPI';
import EuroShippingService from '~/models/euro/EuroShippingService';

const initValues = {
  loading: false,
  shippingService: undefined,
  shippingsService: [],
};

class ShippingServiceStore {
  totalPages = 0;
  page = 0;
  size = 20;
  sort = 'name';
  filter = '';

  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
  }

  reset() {
    this.shippingsService = [];
    this.shippingService = undefined;
    this.totalPages = 0;
    this.page = 0;
    this.sort = 'name';
    this.size = 20;
  }

  /**Notificações. */
  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Cria novo Shipping Service */
  createService() {
    this.shippingService = new EuroShippingService();
  }

  /**Atualiza propriedades de shipping class */
  updatePropClass(prop, value) {
    if (prop === 'status') {
      this.shippingService.setStatusName(value);
    } else {
      this.shippingService[prop] = value;
    }
  }

  /**Busca shippings */
  async getListService() {
    this.loading = true;
    const response = await ShippingServiceAPI.getListServices({
      page: this.page,
      size: this.size,
      sort: this.sort,
    });
    if (!response.error) {
      this.shippingsService = response.content.map(
        (s) => new EuroShippingService(s)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.notificationStore.notifyError(response.error);
    this.loading = false;
    return response;
  }

  /** Get SShipping Service */
  async getService(uuid) {
    this.loading = true;
    const response = await ShippingServiceAPI.getService(uuid);
    if (!response.error) {
      this.shippingService = new EuroShippingService(response);
    } else this.notificationStore.notifyError(response.error);
    this.loading = false;
    return response;
  }

  /**Salva novo serviço de entrega */
  async saveShippingService() {
    this.loading = true;
    const response = await ShippingServiceAPI.saveShippingService(
      JSON.stringify(this.shippingService)
    );
    if (!response.error) this.shippingService = new EuroShippingService();
    else this.notificationStore.notifyError(response.error);
    this.loading = false;
    return response;
  }

  /**Atualiza serviço de entrega */
  async updateService() {
    this.loading = true;
    const response = await ShippingServiceAPI.updateService(
      this.shippingService.uuid,
      this.shippingService
    );
    if (!response.error) this.shippingService = new EuroShippingService();
    else this.notificationStore.notifyError(response.error);
    this.loading = false;
    return response;
  }

  /**Delete shipping */
  async deleteService(uuid) {
    this.loading = true;
    const response = await ShippingServiceAPI.deleteService(uuid);
    if (!response.error)
      this.shippingService = this.shippingService.filter(
        (shipping) => shipping.uuid !== uuid
      );
    else this.notificationStore.notifyError(response.error);
    this.loading = false;
    return response;
  }

  /**Retorna lista de categorias para uso no select */
  getListSelect(shipping = this.shippingsService) {
    return shipping.map((shipping) => ({
      value: shipping.uuid,
      label: shipping.name,
    }));
  }

  async searchByName(inputValue, size = 12) {
    this.page = 0;
    this.sort = 'name';
    this.filter = inputValue;
    this.size = size;
    await this.getListService();
    return this.getListSelect();
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 20, sort) {
    this.page = numPage;
    this.size = size;

    sort ? (this.sort = sort) : (this.sort = 'name');

    return await this.getListService();
  }
}

export default ShippingServiceStore;
