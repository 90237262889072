import { extendObservable, decorate, action } from "mobx";

import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import SportbayKitModel from '~/models/sportbay/SportbayKitModel';
import FileModel from '~/models/FileModel';

const initValues = {
  loading: false,
}

class KitStore {
  totalPages = 0;
  page = 0;
  size = 20;
  loading = false;
  toogleActive = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
    this.toastHelper = new ToastHelper();
    this.kit = new SportbayKitModel();
  }

  /**Função que atribui o valor do campo no model */
  handleKitChange(prop, value) {
    const { kit } = this;

    if (prop === 'active') {
      this.toogleActive = value;
    }

    kit[prop] = value;

    this.kit = new SportbayKitModel(kit);
  }

  /**Adiciona imagem ao produto */
  handleKitImages(tag, file) {
    const kit = this.kit;

    const fileItem = kit.files.find((file) => file.metaTags[0] === tag);
    //Se fileItem for null é uma nova imagem.
    if (!fileItem) {
      const newFileItem = new FileModel({ file, ...file, metaTags: [tag] });
      kit.files.push(newFileItem);
    }
    //FileItem existe, Atualiza valores do arquivo recebido
    else fileItem.updateFile(file);
  }

  /**Adiciona uma ordenação de desconto */
  handleAddDiscount(ordination, discountPercentage) {
    const kit = this.kit;

    kit.discounts.push({ ordination, discountPercentage });

    this.kit = new SportbayKitModel(kit);
  }

  /**Remove a opção da propriedade selecionada */
  handleRemoveOrdination(ordination) {
    let kit = this.kit;

    const discounts = kit.discounts.filter(
      (e) => e.ordination !== ordination
    );

    kit.discounts = discounts;

    this.kit = new SportbayKitModel(kit);
  }
}

export default KitStore;
