import { action, decorate, extendObservable } from 'mobx';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import MerchantEnum from '~/models/avenida/enums/MerchantEnum';
import AvenidaMerchantModel from '~/models/avenida/AvenidaMerchantModel';
import MerchantAPI from '~/services/MerchantAPI';

const initValues = {
  loading: false,
  merchant: undefined,
  merchants: [],
};

/** Store que manipula dados de merchants */
class MerchantStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  createEmptyMerchant() {
    this.merchant = undefined;
    this.merchant = new AvenidaMerchantModel(this.merchant);
  }

  /** Atualiza uma propriedade do merchant */
  updateProp(prop, value) {
    const { merchant } = this;
    merchant[prop] = value;

    this.merchant = new AvenidaMerchantModel(merchant);
  }

  getDefaultMerchant() {
    if (this.merchants) {
      return this.merchants.filter(
        (f) => f.name === MerchantEnum.AVENIDA_MERCHANT
      )[0];
    }
    return null;
  }

  /** Retorna lista de merchants no padrão utilizado pelo select component */
  getListSelect() {
    const merchants = this.merchants.map((mMerchant) => ({
      value: mMerchant.uuid,
      label: mMerchant.name,
    }));
    return merchants;
  }

  /** Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 20) {
    this.page = numPage;
    this.size = size;
    await this.getList();
    return true;
  }

  /** Busca lista de merchants */
  async getList() {
    this.loading = true;
    const params = { page: this.page, size: this.size };
    const response = await MerchantAPI.getList(params);
    this.loading = false;
    if (!response.error) {
      this.merchants = response.content.map(
        (mMerchant) => new AvenidaMerchantModel(mMerchant)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
      return this.merchants;
    }
    return response;
  }

  /** Salva novo merchant */
  async save() {
    this.loading = true;

    this.merchant.percentage = true;

    const data = JSON.stringify(this.merchant);
    const response = await MerchantAPI.save(data);
    if (!response.error) this.merchant = new AvenidaMerchantModel();
    this.loading = false;
    return response;
  }

  /**Busca apenas um determinado merchant */
  async getMerchant(uuid) {
    this.loading = true;
    const response = await MerchantAPI.getMerchantByUUID(uuid);
    if (!response.error) this.merchant = new AvenidaMerchantModel(response);
    else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async searchByName(inputValue) {
    if (!inputValue) {
      await this.getList();
      return this.getListSelect();
    }

    const params = {
      name: inputValue,
    };
    this.loading = true;

    const response = await MerchantAPI.getList(params);

    if (!response.error) {
      this.merchant = await response.content.map((lk) => lk);
      this.totalPages = response.totalPages;
      this.page = response.number;
      return this.merchant;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Atualiza os campos da loja */
  async update() {
    this.loading = true;

    this.merchant.percentage = true;

    const data = JSON.stringify(this.merchant);

    const response = await MerchantAPI.update(this.merchant.uuid, data);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Alterações gravadas com sucesso.'
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loading = false;
    return response;
  }
}

export default MerchantStore;

decorate(MerchantStore, {
  createEmptyMerchant: action,
});
