class DiscountModel {
  constructor(data = {}) {
    this['@class'] = "br.com.stoom.kernel.model.persistence.Discount";  
    this.uuid = data.uuid;
    this.deleted = data.deleted;
    this.productsAbsoluteDiscount = data.productsAbsoluteDiscount;
    this.productsPercentageDiscount = (data.productsPercentageDiscount!=null)?(data.productsPercentageDiscount)/100:data.productsPercentageDiscount;
    this.shippingAbsoluteDiscount = data.shippingAbsoluteDiscount;
    this.shippingPercentageDiscount = (data.shippingPercentageDiscount!=null)?(data.shippingPercentageDiscount)/100:data.shippingPercentageDiscount;
  }
}

export default DiscountModel;