import { extendObservable } from 'mobx';
import OrderModel from '../OrderModel';
import SportbayOrderItemModel from './SportbayOrderItemModel';
import SportbayRecomendedItemModel from './SportbayRecomendedItemModel';
import SportbayShippingService from './SportbayShippingService';
import SportbayShippingDetails from './SportbayShippingDetails';
import StatusChangesModel from './SportbayStatusChangesModel';
import PaymentDetailModel from './SportbayPaymentDetailModel';
import OrderStatusModel from './SportbayOrderStatusModel';

class SportbayOrderModel extends OrderModel {
  constructor(data = {}) {
    super(data);
    this['@class'] = 'br.com.stoom.sportbay.model.SportbayOrder';
    this.status_statusName = data.status_statusName;
    this.customer_firstName = data.customer_firstName;
    this.orderItems =
      data.orderItems &&
      data.orderItems.map((item) => new SportbayOrderItemModel(item));
    this.recommendedItems =
      data.recommendedItems &&
      data.recommendedItems.map((r) => new SportbayRecomendedItemModel(r));
    this.shippingDetails =
      data.shippingDetails && new SportbayShippingDetails(data.shippingDetails);
    this.shippingService =
      data.shippingService && new SportbayShippingService(data.shippingService);
    this.shippingOptions =
      data.shippingOptions &&
      data.shippingOptions.map((so) => new SportbayShippingDetails(so));
    this.statusChanges =
      data.statusChanges &&
      data.statusChanges.map((status) => new StatusChangesModel(status));
    this.paymentDetails =
      data.paymentDetails && new PaymentDetailModel(data.paymentDetails);
    this.orderKits = data.orderKits;
    this.totalKits = data.totalKits;
    this.totalDiscounts = data.totalDiscounts;
    this.totalProductsWithKits = data.totalProductsWithKits;
    extendObservable(this, {
      status: data.status && new OrderStatusModel(data.status),
    });
  }

  get getAdmin() {
    return this.customer.administrator;
  }
}

export default SportbayOrderModel;
