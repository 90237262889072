import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '~/hooks/useStores';

import HeaderListComponent from '~/components/HeaderListComponent';
import { ModalIdsEnum } from '~/helpers/utils/Enums';
import ModalConfirmationComponent from '~/components/ModalConfirmationComponent/ModalConfirmationComponent';
import ModalEditInstallmentComponent from '../ModalEditInstallmentComponent';

const HeaderListInstallmentsComponent = (props) => {
  const { date, onDelete } = props;
  const [interestCardEdit, setInterestCardEdit] = useState({});
  const [interestCardDelete, setInterestCardDelete] = useState(0);
  const { interestRateStore } = useStores();
  const items = () => [
    { size: '3', callback: (e) => {}, label: 'NÚMERO DA PARCELA' },
    { size: '7', callback: (e) => {}, label: 'PORCENTAGEM DE JUROS' },
    { size: '1', callback: (e) => {}, label: '' },
    { size: '1', callback: (e) => {}, label: '' },
  ];

  const openModalEditInstallment = (element) => {
    setInterestCardEdit(element);
    window.$(`#${ModalIdsEnum.EDIT_INSTALLMENT}`).modal('show');
  }

  const openModalDeleteInstallment = (element) => {
    setInterestCardDelete(element);
    window.$(`#${ModalIdsEnum.DELETE_INSTALLMENT}`).modal('show');
  };

  const deleteInstallment = () => {
    onDelete(interestCardDelete);
    window.$(`#${ModalIdsEnum.DELETE_INSTALLMENT}`).modal('hide');
  };

  return (
    <>
      <HeaderListComponent items={items()} />
      {date && date.length > 0 ? (
        date.map((element) => (
          <div className="ListInstallmentComponent" key={element.uuid}>
            <div className="col-3">{element.installment}</div>
            <div className="col-7">{element?.interest?.toFixed(2)}%</div>
            <div className="col-1">
              <i className="fas fa-edit" onClick={() => openModalEditInstallment(element)} />
            </div>
            <div className="col-1">
              {
                element.installment === interestRateStore.totalElements && interestRateStore.totalElements != 1 &&
                <i className="fas fa-trash" onClick={() => openModalDeleteInstallment(element)} />
              }
            </div>
          </div>
        ))
      ) : (
        <div />
      )}
      <ModalConfirmationComponent
        id={ModalIdsEnum.DELETE_INSTALLMENT}
        subtitle="Você deseja deletar essa parcela?"
        onYesClick={() => deleteInstallment()}
        // loading={loading}
      />
      <ModalEditInstallmentComponent
        id={ModalIdsEnum.EDIT_INSTALLMENT}
        interestCardEdit={interestCardEdit}
      />
    </>
  );
};

export default observer(HeaderListInstallmentsComponent);
