import CategoryModel from '../CategoryModel';

class GrupoAvenidaCategoryModel extends CategoryModel {
  constructor(data = {}) {
    super(data);

    this['@class'] =
      data.class ?? 'br.com.stoom.avenida.model.persistence.AvenidaCategory';
    this.ordering = data.ordering;
    this.properties = data.properties;
    this.categoryPropertyClasses = data.categoryPropertyClasses;
    this.childrenCategory =
      data.childrenCategory &&
      data.childrenCategory.map((cat) => new GrupoAvenidaCategoryModel(cat));
    this.externalId = data.externalId;
    this.bannerSeo = data.bannerSeo;
  }
}

export default GrupoAvenidaCategoryModel;
