import { extendObservable } from 'mobx';

import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import GlobalConfigModel from '~/models/avenida/GlobalConfigModel';
import GlobalConfigAPI from '../services/GlobalConfigAPI';

const initValues = {
  loading: false,
  config: {},
};

class GlobalConfigStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
    this.toastHelper = new ToastHelper();
  }

  /**
  @param {Object} data Objeto para salvar a configuração
  @param {Number} data.daysToShowHighlightedProducts Tempo de novidades
  @param {Number} data.daysToShowProductsWithoutStock Tempo de estoque ativo
  @return {VoidFunction}
  */

  async updateGlobalConfig(data) {
    this.loading = true;

    data = Object.assign(this.config, data);

    const response = await GlobalConfigAPI.save(data);

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Configurações atualizadas'
      );
    }

    this.loading = false;

    return null;
  }

  //Busca todos as configurações globais
  async getGlobalConfigs() {
    this.loading = true;
    const response = await GlobalConfigAPI.getConfigs();

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.config = new GlobalConfigModel(response.data);

    this.loading = false;

    return this.config;
  }
}

export default GlobalConfigStore;
