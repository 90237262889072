import { extendObservable } from 'mobx';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import SalesPersonModel from '~/models/SalesPersonModel';
import SalesPersonAPI from '~/services/SalesPersonAPI';
import BranchAPI from '../services/BranchAPI';

const SALESMAN_PASSWORD = "grupoavenida123";

const initValues = {
  loading: false,
  loadingBranch: false,
  salesPerson: new SalesPersonModel(),
  sellers: [],
  optionsBranch: [],
  branchUuid: ''
};

class SalesPersonStore {
  totalPages = 0;
  page = 0;
  size = 10;
  sort = 'created,desc';
  filter = '';

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort = 'created,desc') {
    this.page = numPage;
    this.size = size;
    sort && (this.sort = sort);
    await this.getList();
    return this.totalPages;
  }

  /**Busca lista de vendedores */
  async getList() {
    this.loading = true;
    const params = { size: this.size, page: this.page, sort: this.sort, name: this.filter };
    const response = await SalesPersonAPI.getList(params);

    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return [];
    }
    this.sellers = response.content.map((s) => new SalesPersonModel(s));
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  /**Funcao utilizada para alterar valor de atributo do vendedor */
  changeSalesPerson(prop, value) {
    const { salesPerson } = this;

    salesPerson[prop] = value;

    this.salesPerson = salesPerson;
  }

  /**Muda a final do vendedor apos o usuario clicar no async select */
  changeBranch(uuid) {
    this.salesPerson.branchUuid = uuid;
  }

  /**Funcao para chamar a api de buscar opcoes de lojas/filiais*/
  async getBranchOptions(name) {
    this.loadingBranch = true;
    const params = {
      name,
      sort: 'name',
      status: 'active'
    };

    const response = await BranchAPI.getAll(params);

    if (!response.error) {
      const optionsBranch = [];
      if (response.content && response.content.length > 0) {
        response.content.map((b) => {
          optionsBranch.push({ value: b.uuid, label: b.name });
          return false;
        });
      }
      this.optionsBranch = optionsBranch;
      this.loadingBranch = false;
      return optionsBranch;
    }

    this.loadingBranch = false;
    return [];
  }

  /**Salva um novo vendedor */
  async onSave() {
    this.loading = true;

    const { salesPerson } = this;

    const response = await SalesPersonAPI.save(salesPerson);

    this.loading = false;

    if (!response.error) {
      this.salesPerson = response.data;
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Vendedor adicionado com sucesso!');
      return true;
    }

    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return false;
  }

  /**Atualiza um vendedor */
  async onUpdate() {
    this.loading = true;

    const { salesPerson } = this;

    const response = await SalesPersonAPI.update(
      {...salesPerson, password:SALESMAN_PASSWORD}, 
      salesPerson.uuid
    );

    this.loading = false;

    if (!response.error) {
      this.salesPerson = response.data;
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Vendedor atualizado com sucesso!');
      return true;
    }

    this.toastHelper.notify(STATUS_HELPER.ERROR, 'Erro ao atualizar o vendedor');
    return false;
  }

  reset() {
    this.salesPerson = new SalesPersonModel();
    this.optionsBranch = [];
    this.branchUuid = '';
    this.sellers = [];
  }

  /**Função para ativar/inativar vendedor no grid */
  async activeSalesPerson(salesPerson) {
    const response = await SalesPersonAPI.update(salesPerson, salesPerson.uuid);

    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, `Vendedor ${salesPerson.active ? 'ativado' : 'inativado'} com sucesso`);
      return true;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, `Erro ao ${salesPerson.active ? 'ativar' : 'inativar'} o vendedor`);
    return false;
  }

  /**Função para deletar vendedor */
  async deleteSalesPerson(salesPersonUuid) {
    const response = await SalesPersonAPI.delete(salesPersonUuid);

    if (!response.error) {
      await this.getList();
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Vendedor deletado com sucesso');
      return true;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, 'Falha ao deletar o vendedor');
  }

  /**Funcao para buscar o vendedor pelo nome */
  async searchByName(inputValue, size = 10) {
    this.page = 0;
    this.filter = inputValue;
    this.size = size;

    await this.getList();
    return this.totalPages;
  }

  /**Retorna lista de vendedores para uso no select */
  getListSelect(sellers = this.sellers) {
    return sellers.map((s) => ({
      value: s.uuid,
      label: `${s.firstName} ${s.lastName}`,
    }));
  }

  /**Função para buscar um vendedor para ser usada da tela de update */
  async getSalesPersonByUuid(salesPersonUuid) {
    const response = await SalesPersonAPI.getSalesPersonByUuid(salesPersonUuid);
    if (!response.error) {
      this.salesPerson = response;

      return this.salesPerson;
    }

    return false;
  }

  async saveSalesPersonByCSV(formData){
    this.loading = true;
    const response = await SalesPersonAPI.saveSalesPersonByCSV(formData);
    this.loading = false;
    if(response?.error){
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return response;
    }

    if(response?.infos.length > 0){
      return { error: true, response };
    }else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Vendedores salvos com sucesso!");
      return {error: false, response };
    }
  }
}

export default SalesPersonStore;
