import { extendObservable, decorate, action } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import LookAPI from '~/services/LookAPI';
import ProductAPI from '~/services/ProductAPI';
import UploadAPI from '~/services/UploadAPI';

import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import LookModel from '~/models/LookModel';
import ProductModel from '~/models/ProductModel';
import FileModel from '~/models/FileModel';
import CategoryModel from '~/models/CategoryModel';

const initValues = {
  loading: false,
  look: {},
  looks: [],
  optionsProducts: [], // Opções para o async select
  optionsProductsAlternative: [], // Opções para o async select
  productsResponse: [], // Produtos que vem da chamada api
  productsAlternative: [], // Produtos alternativos do produto do look que estiver ativo
  product: undefined,
  productsUUID: [], //UUID dos produtos do look que serão enviados para retornar o preço
};

class LookStore {
  totalPages = 0;
  page = 0;
  size = 10;
  status = false;
  loadingSearchProduct = false;
  loading = false;
  toogleActive = false;
  isVisibleProductSelected = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
    this.toastHelper = new ToastHelper();
    this.look = new LookModel();
  }

  reset() {
    this.look = new LookModel();
    this.totalPages = 0;
    this.page = 0;
    this.looks = [];
    this.size = 10;
    this.sort = 'name';
    this.term = '';
    this.isVisibleProductSelected = false;
    this.productsUUID = [];
  }

  handleChangeComponentProduct() {
    this.isVisibleProductSelected = !this.isVisibleProductSelected;
  }

  /**Busca todos os look */
  async getList() {
    this.loading = true;

    let params = {};

    if (this.status === 'active' || this.status === 'inactive') {
      params = {
        page: this.page,
        size: this.size,
        status: this.status,
        term: this.term,
      };
    } else {
      params = {
        page: this.page,
        size: this.size,
        sort: this.sort,
        term: this.term,
      };
    }

    const response = await LookAPI.list(params);

    if (!response.error) {
      const data = response.content;

      this.looks = await data.map((lk) => new LookModel(lk));
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async searchByName(inputValue, size = 10) {
    this.term = inputValue;

    if (!inputValue) {
      await this.getList();
      return this.getListSelect();
    }

    this.page = 0;
    this.size = size;
    this.sort = 'name';

    const params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
      status:
        this.status === 'active' || this.status === 'inactive'
          ? this.status
          : '',
      term: this.term,
    };
    this.loading = true;

    const response = await LookAPI.listByName(params);

    if (!response.error) {
      this.looks = await response.content.map((lk) => new LookModel(lk));
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async searchByStatus(selectedValue, size = 10) {
    this.page = 0;
    this.size = size;
    this.status = selectedValue;
    await this.getList();
    return this.getListSelect();
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = sort ? sort : 'created,desc';

    await this.getList();
  }

  /**Retorna lista para uso no select */
  getListSelect(looks = this.looks) {
    return looks.map((lk) => ({
      value: lk.uuid,
      label: `${lk.name}`,
    }));
  }

  /**Função que atribui o valor do campo no model */
  handleLookChange(prop, value) {
    const { look } = this;

    if (prop === 'status') {
      this.toogleActive = value;
    }

    look[prop] = value;

    this.look = new LookModel(look);
  }

  /**Adiciona imagem ao produto */
  handleLookImages(tag, file) {
    const look = this.look;
    const fileItem = look.files.find((file) => file.metatag === tag);
    //Se fileItem for null é uma nova imagem.
    if (!fileItem) {
      const newFileItem = new FileModel({
        file,
        ...file,
        metatag: tag,
      });
      look.files.push(newFileItem);
    }
    //FileItem existe, Atualiza valores do arquivo recebido
    else fileItem.updateFile(file);
  }

  /**Adiciona uma ordenação de desconto */
  handleAddDiscount(ordering, percentage) {
    const look = this.look;

    look.itemsDiscount.push({ ordering, percentage });

    this.look = new LookModel(look);
  }

  /**Remove o desconto selecionado */
  async handleRemoveOrdering(ordering) {
    let look = this.look;
    let discountUuid = '';

    const itemsDiscount = look.itemsDiscount.filter((e) => {
      if (e.ordering === ordering && e.uuid) {
        discountUuid = e.uuid;
      }
      return e.ordering !== ordering;
    });

    if (discountUuid) {
      await LookAPI.handleRemoveDiscountLook(this.look.uuid, discountUuid);
    }

    look.itemsDiscount = itemsDiscount;

    this.look = new LookModel(look);

    this.toastHelper.notify(
      STATUS_HELPER.INFO,
      'Desconto deletado com sucesso.'
    );
  }

  /**Adiciona um produto alternativo */
  handleAddProductAlternative(productUuid, productName) {
    const productsAlternative = this.productsAlternative;

    productsAlternative.push({ productUuid, productName });

    this.productsAlternative = productsAlternative;
  }

  /**Remove o produto alternativo selecionado */
  async handleRemoveProductAlternative(product, uuidLook, productUuid) {
    if (uuidLook != undefined && product.uuid) {
      this.loading = true;
      const response = await LookAPI.handleRemoveProductAlternativeLook(
        productUuid,
        product.productUuid
      );

      if (!response.error) {
        const productsAlternative = this.productsAlternative.filter(
          (e) => e.productUuid !== product.productUuid
        );

        this.productsAlternative = productsAlternative;
        this.look.products.map((e) => {
          if (e.uuid === productUuid) {
            e.productsAlternative = this.productsAlternative;
          }
        });
      } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);

      this.loading = false;
    } else {
      const productsAlternative = this.productsAlternative.filter(
        (e) => e.productUuid !== product.productUuid
      );

      this.productsAlternative = productsAlternative;
    }
  }

  resetProductLook() {
    this.optionsProducts = [];
    this.product = undefined;
    this.loadingSearchProduct = false;
    this.productsAlternative = [];
  }

  /**Reseta as opções quando a string é menor 3 caracteres */
  resetProductOption() {
    this.optionsProducts = [];
    this.loadingSearchProduct = false;
  }

  /**Retorna lista para uso no select */
  getListSelect(products = this.productsResponse) {
    return products.map((prd) => ({
      value: prd.uuid,
      label: prd.name,
    }));
  }

  /**Busca por produto ativo por nome ou sku*/
  async searchProductActiveByNameSKU(query) {
    const formatQuery = `name:*${query}*,parent:true`;
    let productList = 0;
    this.loadingSearchProduct = true;

    const params = {
      search: formatQuery,
    };

    let response = await ProductAPI.listByType(params);

    if (
      this.look.products.length > 0 &&
      !response.error &&
      response.content.length > 0
    ) {
      this.look.products.map((p) => {
        response.content = response.content.filter((pr) => {
          return pr.uuid !== p.productUuid;
        });

        if (p.productsAlternative.length > 0) {
          p.productsAlternative.map((pdt) => {
            response.content = response.content.filter((pr) => {
              return pr.uuid !== pdt.productUuid;
            });
          });
        }
      });
    }

    if (!response.error) {
      this.productsResponse = await response.content.map(
        (prd) => new ProductModel(prd)
      );
      productList = this.productsResponse;
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loadingSearchProduct = false;

    return this.getListSelect(productList);
  }

  onSaveProductInLook(product) {
    const products = this.look.products;
    let productsUUID = this.productsUUID;
    products.push({
      productUuid: product.value,
      productName: product.label,
      productsAlternative: this.productsAlternative,
    });
    productsUUID.push(product.value);

    this.look.products = products;
    this.productsUUID = productsUUID;

    /* this.calculateProductsPriceInLook(); */

    this.toastHelper.notify(
      STATUS_HELPER.INFO,
      'Produto Adicionado com Sucesso no Look'
    );
  }

  // Atualiza o produto no look
  onUpdateProductInLook(product) {
    this.look.products.map((e) => {
      if (e.productUuid === product.value) {
        e.productsAlternative = this.productsAlternative;
      }
    });

    this.toastHelper.notify(
      STATUS_HELPER.INFO,
      'Produto Atualizado com Sucesso no Look'
    );
  }

  /**Remove o produto selecionado do look */
  async handleRemoveProductLook(product, uuidLook) {
    this.loading = true;
    if (uuidLook != undefined) {
      await LookAPI.handleRemoveProductLook(uuidLook, product.productUuid);
    }

    const products = this.look.products.filter(
      (e) => e.productUuid !== product.productUuid
    );

    const productsUUID = this.productsUUID.filter(
      (e) => e !== product.productUuid
    );

    this.productsUUID = productsUUID;
    this.look.products = products;

    if (products.length === 0) {
      this.handleLookChange('status', false);
      // if (uuidLook != undefined) {
      //   await LookAPI.disableLook(uuidLook);
      // }
    }

    /* this.calculateProductsPriceInLook(); */
    this.loading = false;
  }

  /**Seta os produtos alternativos no update do produto do look */
  setCurrentProductsAlternative(productUuid) {
    let productsAlternative;

    this.look.products.map((e) => {
      if (e.productUuid === productUuid) {
        productsAlternative = e.productsAlternative;
      }
    });

    this.productsAlternative = productsAlternative;
  }

  /**Busca apenas um determinado produto */
  async get(value, prop = 'uuid') {
    this.loadingSearchProduct = true;
    const response = await ProductAPI.get(prop, value);
    if (!response.error) {
      const product = new ProductModel(response);
      const categories = await this.getCategories(product.uuid);
      product.categories = categories;
      this.product = new ProductModel(product);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loadingSearchProduct = false;
    return this.product;
  }

  async getCategories(productUuid) {
    const response = await ProductAPI.getCategories(productUuid);
    let categories = [];
    if (!response.error)
      categories = response.map((cat) => new CategoryModel(cat));
    else this.toastHelper.notify(STATUS_HELPER.error, response.error);
    return categories;
  }

  /**Salva um novo look */
  async save() {
    this.loading = true;
    // const files = this.look.files;

    // this.look.files = [];

    const data = JSON.stringify(this.look);
    let response = await LookAPI.save(
      data
        .replace('_name', 'name')
        .replace('_size', 'size')
        .replace('_preview', 'preview')
    );

    if (!response.error) {
      const look = new ProductModel(response.data);
      // this.sendNewFiles(files, look.uuid);
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Look Cadastrado');
    } else {
      // this.look.files = files;
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Não foi possível cadastrar o look'
      );
    }
    this.loading = false;
    return response;
  }

  /**Atualiza Look */
  async update() {
    this.loading = true;

    // const files = this.look.files;
    // if (files.length > 0) this.sendFiles(this.look.uuid, files);

    const data = JSON.stringify(this.look);

    const response = await LookAPI.update(
      this.look.uuid,
      data
        .replace('_name', 'name')
        .replace('_size', 'size')
        .replace('_preview', 'preview')
    );

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Alterações gravadas com sucesso.'
      );
    } else {
      // this.look.files = files;
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loading = false;
    return response;
  }

  /**Envia arquivo da imagem do look */
  async sendFiles(lookUuid, files) {
    const newFiles = files.filter((file) => !file.uuid);
    const updateds = files.filter(
      (file) => file.uuid && file.localModified && file.file
    );
    newFiles.length > 0 && (await this.sendNewFiles(newFiles, lookUuid));
    updateds.length > 0 && (await this.updateFiles(updateds));
  }

  async updateFiles(updatedFiles) {
    const promiseUpdate = updatedFiles.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      // await UploadAPI.updateFile(file);
    });
    const response = await this.handlePromises(
      promiseUpdate,
      'Falha atualizar arquivos.'
    );
    setTimeout(() => {
      updatedFiles.forEach((file) =>
        this.notificationStore.removeItemUpload(file)
      );
    }, 3300);
    return response;
  }

  /**Funcão útil para dar feedbeack sobre envio de arquivos. */
  async handlePromises(promises, message) {
    const response = await Promise.all(promises);
    const errors = response.filter((r) => r && r.error !== undefined);
    if (errors && errors.length > 0) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, message);
    }
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  // Envia nova imagem
  async sendNewFiles(newFiles, productUuid) {
    const promiseNew = newFiles.map(async (file) => {
      this.rootStore.notificationStore.addItemUpload(file);
      await UploadAPI.sendNewFilesLook(productUuid, file);
    });
    const response = await this.handlePromises(
      promiseNew,
      'Falha anexar arquivos.'
    );
    setTimeout(() => {
      newFiles.forEach((file) =>
        this.rootStore.notificationStore.removeItemUpload(file)
      );
    }, 3300);
    return response;
  }

  /**Busca apenas um determinado look */
  async getLook(lookUuid) {
    this.loading = true;
    const response = await LookAPI.findByUUID(lookUuid);
    if (!response.error) {
      const look = new LookModel(response);
      this.look = new LookModel(look);
      const productsUUID = [];
      if (response.products.length > 0) {
        response.products.map((pdt) => {
          productsUUID.push(pdt.productUuid);
        });
      }
      this.productsUUID = productsUUID;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return this.look;
  }

  handleChangeImageLook(path) {
    const look = this.look;
    const metaTags = 'kit';

    let file = { path, metaTags, size: 1, name: uuidv4(), metatag: 'kit' };

    file = new FileModel(file);

    if (look.files && look.files.length > 0) {
      look.files = [file];
    } else if (look.files && look.files.length === 0) {
      look.files.push(file);
    }

    this.look = new LookModel(look);
  }

  async calculateProductsPriceInLook() {
    this.loading = true;
    const look = this.look;
    let uuids = '';

    if (this.productsUUID.length > 0) {
      this.productsUUID.map((uuid, index) => {
        if (index === 0) {
          uuids += uuid;
        } else {
          uuids += `,${uuid}`;
        }
      });

      const params = {
        uuids: uuids,
      };

      const response = await LookAPI.calculateProductsPriceInLook(params);

      if (!response.error) {
        look.price = response.price;
        this.look = new LookModel(look);
      } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      look.price = '';
      this.look = new LookModel(look);
    }

    this.loading = false;
    return this.look;
  }
}

export default LookStore;
