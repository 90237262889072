import BaseAPI, { URLS } from './BaseAPI';

class NewsletterAPI {
  static _exception(_e, message) {
    return { error: message };
  }

  /**
   * @param  {string} data - Send email newsletter to store customers
   */
  static async send(data) {
    try {
      const response = await BaseAPI.post(`${URLS.NEWS_LETTER}/send?${data}`);
      if (response.status === 200) return response;
      return { error: 'Erro ao enviar a newsletter' };
    } catch (e) {
      return this._exception(e, 'Erro ao enviar a newsletter');
    }
  }

  /**
   * @param  {string} data - salva um template de email
   */
  static async save(data) {
    try {
      const response = await BaseAPI.post(`${URLS.NEWS_LETTER}`, data);
      if (response.status === 200) return response;
      return { error: 'Erro inesperado ao cadastrar newsletter' };
    } catch (e) {
      return this._exception(e, 'Falha ao cadastrar newsletter');
    }
  }

  /**
   * @param  {object} data - data of newsletter
   * @param  {string} uuid - uuid of newsletter
   */
  static async update(data, uuid) {
    try {
      const response = await BaseAPI.put(`${URLS.NEWS_LETTER}/${uuid}`, data);
      if (response.status === 200) return response;
      return { error: 'Erro inesperado ao atualizar a newsletter' };
    } catch (e) {
      return this._exception(e, 'Falha ao atualizar a newsletter');
    }
  }

  /**
   * @param  {Object} params - params for list of newsletters
   * @param {number} params.size - size of list
   * @param {number} params.page - page of list
   */
  static async get(params) {
    try {
      const response = await BaseAPI.get(`${URLS.NEWS_LETTER}`, params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar as newsletters' };
    } catch (e) {
      return this._exception(e, 'Erro inesperado ao buscar a newsletters');
    }
  }
  /**
   * @param  {string} uuid - params for list of newsletters
   */

  static async getById(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.NEWS_LETTER}/${uuid}`);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar a newsletters' };
    } catch (e) {
      return this._exception(e, 'Erro inesperado ao buscar a newsletters');
    }
  }

  /**
   * @param  {string} uuid - id of newsletter
   */
  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.NEWS_LETTER}/${uuid}`);
      if (response.status === 200) return response;
      return { error: 'Erro inesperado ao deletar newsletter' };
    } catch (e) {
      return this._exception(e, 'Erro inesperado ao deletar newsletter');
    }
  }
}
export default NewsletterAPI;
