import React from 'react';

/**
 * @Name: Item de Lista do Painel
 * @Data: 2019
 * @Desc: O Componente de lista (item)  dos cards
 * @props:
 *
 */

const ListPainelComponent = (props) => {
  // Recebe as propriedades
  const { name, required, onEdit, onDelete, children } = props;

  return (
    <>
      <div className="ListPainelComponent">
        {children || (
          <>
            <div className="col-8" onClick={onEdit}>
              {name}
            </div>
            <div className="col-2">{required ? 'Sim' : 'Não'}</div>
            <div className="col-1">
              <i className="fas fa-edit" onClick={onEdit} />
            </div>
            {onDelete && (
              <div className="col-1">
                <i className="fas fa-trash" onClick={onDelete} />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ListPainelComponent;
