import React, { useState, useEffect } from 'react';

const TextareaComponent = ({ onChange, onBlur,  className, name, id, rows, defaultValue, placeholder = 'Descrição Completa do Produto', disabled = false, maxlength = '5000', required = false }) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (e) => {
    if(onChange){
      e.stopPropagation();
      setValue(e.target.value);
      onChange(e.target.value);
    }
  };

  const handleBlur = (e) => {
    if(onBlur){
      e.stopPropagation();
      setValue(e.target.value);
      onBlur(e.target.value);
    }
  };

  return (<textarea
    onChange={(e) => handleChange(e)}
    onBlur={(e) => handleBlur(e)}
    className={className}
    name={name}
    id={id}
    rows={rows}
    placeholder={placeholder}
    value={value}
    disabled={disabled}
    maxLength={maxlength}
    required={required}
  />
  );
};

export default TextareaComponent;
