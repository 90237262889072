import ProductModel from '../ProductModel';
import FileModel from './FileModel';
import CategoryModel from './CategoryModel';

class GrupoAvenidaProductModel extends ProductModel {
  constructor(data = {}) {
    super(data);
    this.displayVariationWindow = data.displayVariationWindow ?? false;
    this.nameLogix = data.nameLogix;
    this.colorNameLogix = data.colorNameLogix;
    this.colorCodeLogix = data.colorCodeLogix;
    this.display = data.display;
    this.themeCode = data.themeCode;
    this.coordinateCode = data.coordinateCode;
    this.gift = data.gift;
    this.unit = data.unit;
    this.pathVideo = data.pathVideo;
    this.barCodes = data.barCodes;
    this.size = data.size;
    this.parent = data.parent || false;
    this.productProperties = data.productProperties ?? [];
    this.categories = data.categories
      ? data.categories.filter((cat) => {
          if (cat.type === 'VITRINE') {
            return new CategoryModel(cat);
          }
          return [];
        })
      : [];
    this.collections = data.categories
      ? data.categories.filter((cat) => {
          if (cat.type === 'COLECAO') {
            return new CategoryModel(cat);
          }
          return [];
        })
      : [];
    this.color = data.color;
    this.productVariations = data.productVariations
      ? data.productVariations.map((a) => new GrupoAvenidaProductModel(a))
      : [];

    this.files = data.files
      ? data.files.map((file) => new FileModel(file))
      : [];
  }

  getFile(tag) {
    return this.files.find(
      (file) => file.path && file.metaTags.some((metaTag) => metaTag === tag)
    );
  }

  getFiles(tag) {
    return this.files.filter(
      (file) => file.path && file.metaTags.some((metaTag) => metaTag === tag)
    );
  }
}

export default GrupoAvenidaProductModel;
