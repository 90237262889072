import React from 'react';

/** 
  * @Name: CardPainelEmptyComponent
  * @Data: 2019
  * @Desc: Condição de um card de painel vazio 
  * @props:
  *       
  */

const CardPainelEmptyComponent = (props) =>{


    return (
        <React.Fragment>
             <div className="card-manager-empty">
                        <div className="empty-card">
                            Adicionar um painel aqui
                        </div>
            </div>
        </React.Fragment>
    )
}

export default CardPainelEmptyComponent;