import React from 'react';
import InputComponent from '../InputComponent';

/**Input password */
export default (props) => {
  const {
    minLength = '6',
    autoComplete = 'off',
    maxLength = '12',
    name = 'password',
    id = 'password',
  } = props;
  /**Criptografa a senha */

  return (
    <InputComponent
      {...props}
      autoComplete={autoComplete}
      maxLength={maxLength}
      type="password"
      name={name}
      id={id}
      minLength={minLength}
      required
    />
  );
};
