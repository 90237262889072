import React, { useState } from 'react';
import HeaderListComponent from '../../../containers/PropertyGroupContainer/component/HeaderListComponent';

const HeaderComponent = ({ onClick }) => {

  const sortActive = () => {
    onClick('active');
  };

  const sortName = () => {
    onClick('name');
  };

  const items = () => [
    { size: '8', callback: () => sortName(), label: 'Nome' },
    { size: '2', callback: () => sortActive(), label: 'Ativo' },
    { size: '1', callback: () => {}, label: '' },
  ];

  return <HeaderListComponent items={items()} />;
};

export default HeaderComponent;
