/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '~/hooks/useStores';

import FormGroupComponent from '~/components/FormGroupComponent/FormGroupComponent';
import LabelComponent from '~/components/LabelComponent/LabelComponent';
import { InputNumbersComponent } from '~/components/InputComponent';
import CollumComponent from '~/components/CollumComponent/CollumComponent';
import ButtonComponent from '~/components/ButtonComponent/ButtonComponent';

/**
 * @Name: Modal
 * @Data: 2020
 * @Desc: Modal que se abre de forma suspensa.
 * @props:
 *
 */
const ModalEditInstallmentComponent = (props) => {
  // Recebe as propriedades
  const {
    id,
    size,
    modalType = 'modal-dialog ',
    style,
    className,
    interestCardEdit,
  } = props;
  const { interestRateStore } = useStores();
  const [interest, setInterest] = useState(0);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [modalClass, setModal] = useState(modalType);

  const modal = useRef();

  useEffect(() => {
    setModal(`${modalType} ${size || ''}`);
  }, [size, modalType]);

  useEffect(() => {
    setInterest(interestCardEdit.interest)
  }, [interestCardEdit]);

  const handleSubmit = async () => {
    setLoadingUpdate(true);

    const response = await interestRateStore.handleUpdateInstallment(interestCardEdit, interest);

    setLoadingUpdate(false);

    if (response) {
      window.$(`#${id}`).modal('hide');
    }

  }

  return (
    <>
      <div
        className={className + " modal fade ModalEditInstallment"}
        id={id || 'ModalDefault'}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="ModalDefaultLabel"
        aria-hidden="true"
        style={style}
      >
        <div className={modalClass} role="document">
          <div ref={modal} className="modal-content">
            <h2>Edição da {interestCardEdit?.installment}º parcela</h2>
            <div className="w-100">
              <FormGroupComponent className="form-group row dropdown">
                <LabelComponent name="Juros da Parcela" htmlFor="installmentEdit" size={12} />
                <CollumComponent size="lg" cols="12">
                  <InputNumbersComponent
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value) {
                        value = parseFloat(value)
                      }
                      setInterest(value);
                    }}
                    id="installmentEdit"
                    className="form-control input-largest"
                    name="installmentEdit"
                    value={interest?.toFixed(2)}
                    placeholder="Digite o juros da parcela"
                    type="number"
                    step="0.01"
                    disabled={loadingUpdate}
                  />
                </CollumComponent>
              </FormGroupComponent>
            </div>
            <div className="container-button-edit-installment">
              <ButtonComponent
                type="button"
                loading={loadingUpdate}
                disabled={loadingUpdate}
                click={() => handleSubmit()}
              >
                Salvar
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(ModalEditInstallmentComponent);
