import BaseModel from './BaseModel';

class AlternativeProductModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.productUuid = data.productUuid;
    this.productName = data.productName;
  }
}

export default AlternativeProductModel;
