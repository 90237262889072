import BaseAPI, { URLS } from './BaseAPI';

class GlobalConfigAPI {
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
     *     Lista todas as configurações
  @param {Object} data Objeto para salvar a configuração
  @param {Number} data.daysToShowHighlightedProducts Tempo de novidades
  @param {Number} data.daysToShowProductsWithoutStock Tempo de estoque ativo
  */

  static async save(data) {
    try {
      const response = await BaseAPI.put(URLS.GLOBAL_CONFIG, data);

      if (response.status === 200) return response;
      return { error: 'Errosss inesperado ao salvar as configurações da loja' };
    } catch (e) {
      return this._exception(
        e,
        'Erro inesperado ao salvar as configurações da loja'
      );
    }
  }

  /**
   * Lista todas as configurações
   */
  static async getConfigs() {
    try {
      const response = await BaseAPI.get(URLS.GLOBAL_CONFIG);
      if (response.status === 200) return response;
      return { error: 'Erro inesperado ao carregar as configurações da loja' };
    } catch (e) {
      return this._exception(
        e,
        'Erro inesperado ao carregar as configurações da loja'
      );
    }
  }
}

export default GlobalConfigAPI;
