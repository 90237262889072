import BaseAPI, { URLS } from './BaseAPI';

class ShippingAPI {
  /**Método util para retorno de erro */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * @param  {string} uuid - Uuid da transportadora à ser procurada
   */
  static async getClass(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.SHIPPING}/classes/${uuid}`);
      if (response.status === 200) return response.data;
      const error =
        response.status === 404
          ? 'Transportadora não encontrado'
          : 'Erro inexperado ao buscar Transportadora';
      return { error };
    } catch (e) {
      return this._exception(e, 'Erro ao buscar Transportadora');
    }
  }

  /**
   * @param  {string} uuid - Uuid da transportadora à ser deletada
   */
  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.SHIPPING}/${uuid}`);
      if (response.status === 204) return response.data;
      const error =
        response.status === 404
          ? 'Transportadora não encontrado'
          : 'Erro inexperado ao excluir Transportadora';
      return { error };
    } catch (e) {
      return this._exception(e, 'Erro ao excluir Transportadora');
    }
  }

  /**Busca lista de transportadoras */
  static async getListClass(params) {
    try {
      const response = await BaseAPI.get(`${URLS.SHIPPING}`, params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inexperado ao buscar transportadoras' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar transportadoras');
    }
  }

  /**Busca lista de transportadoras não paginadas */
  static async getAll() {
    try {
      const response = await BaseAPI.get(`${URLS.SHIPPING}/all`);
      if (response.status === 200) return response.data;
      return { error: 'Erro inexperado ao buscar transportadoras' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar transportadoras');
    }
  }
}
export default ShippingAPI;
