import React, { useState, useEffect } from 'react';
import { maskCell, maskPhone } from '~/helpers/utils/Masks';
import InputComponent from '../InputComponent';

/* Input password */
export default (props) => {
  const [currenMask, setMask] = useState(maskCell);
  const {
    minLength = '14',
    name = 'phone',
    id = 'phone',
    defaultValue,
  } = props;

  useEffect(() => {
    if (defaultValue) {
      const with9 = defaultValue.length === 15;

      setMask(with9 ? maskCell : maskPhone);
    }
  }, [defaultValue]);

  // Ao digitar verifico se está aumentando ou diminuindo o tamanho dos values,
  // e assim trocamos as mascaras.
  const handleKeyDown = (e) => {
    const with9 = e.target.value.length + 1 > 14;

    setMask(with9 ? maskCell : maskPhone);
  };

  // Passa valores para o phone

  return (
    <InputComponent
      onKeyDown={handleKeyDown}
      name={name}
      id={id}
      minLength={minLength}
      mask={currenMask}
      {...props}
    />
  );
};
