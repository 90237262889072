import BaseAPI, { URLS } from './BaseAPI';

class InterestRateAPI {
  /**Retorno generico de exceções */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * @param  {object} params - parametros de paginação
   */
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.INTEREST_RATE}`, params);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar lista de parcelas' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar lista de parcelas');
    }
  }

  /**
   * Request que cria nova parcela.
   * @param  {Object} interestRate - Objeto da parcela que será criada
   */
  static async save(interestRate) {
    try {
      const response = await BaseAPI.post(URLS.INTEREST_RATE, interestRate);
      if (response.status === 200 || response.status === 201) return response;
      return { error: 'Erro inesperado ao cadastrar a parcela' };
    } catch (e) {
      return this._exception(e, 'Falha ao cadastrar a parcela');
    }
  }

  /**
   * Request que atualiza uma parcela.
   * @param  {Object} interestRate - Objeto da parcela que será atualizada
   * @param  {String} uuid - Uuid da parcela que será atualizada
   */
  static async update(interestRate, uuid) {
    try {
      const response = await BaseAPI.put(
        `${URLS.INTEREST_RATE}/${uuid}`,
        interestRate
      );
      if (response.status === 200 || response.status === 201) return response;
      return { error: 'Erro inesperado ao atualizar a parcela' };
    } catch (e) {
      return this._exception(e, 'Falha ao atualizar a parcela');
    }
  }

  /**
   * Request que deleta uma parcela.
   * @param  {String} uuid - Uuid da parcela que será atualizada
   */
  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.INTEREST_RATE}/${uuid}`);
      if (response.status === 200 || response.status === 204) return response;
      return { error: 'Erro inesperado ao deletar a parcela' };
    } catch (e) {
      return this._exception(e, 'Falha ao deletar a parcela');
    }
  }

  /**
   * Request que busca parcela pelo uuid.
   * @param  {String} uuid - Uuid da parcela
   */
  static async getInterestRateByUuid(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.INTEREST_RATE}/${uuid}`);
      if (response.status === 201 || response.status === 200) {
        return response.data;
      }
      return { error: 'Erro ao recuperar informação da parcela!' };
    } catch (e) {
      return this._exception(e, 'Erro ao recuperar informação da parcela!');
    }
  }

  static async getInstallmentByUuid(categoryUuid) {
    try {
      const { data, status } = await BaseAPI.get(
        `${URLS.INTEREST_RATE}/categories/${categoryUuid}`
      );
      return { ...data, status };
    } catch (error) {
      return this._exception(
        error,
        'Erro ao recuperar informações de parcelamento'
      );
    }
  }

  static async createInstallment(payload) {
    try {
      const { data, status } = await BaseAPI.post(
        `${URLS.INTEREST_RATE}`,
        payload
      );

      return { ...data, status };
    } catch (error) {
      return this._exception(
        error,
        'Erro ao adicionar informações de parcelamento'
      );
    }
  }

  static async updateInstallment(categoryUuid, payload) {
    try {
      const { data, status } = await BaseAPI.put(
        `${URLS.INTEREST_RATE}/categories/${categoryUuid}`,
        payload
      );

      return { ...data, status };
    } catch (error) {
      return this._exception(
        error,
        'Erro ao atualizar informações de parcelamento'
      );
    }
  }

  static async deleteInstallment(categoryUuid) {
    try {
      const { data, status } = await BaseAPI.delete(
        `${URLS.INTEREST_RATE}/categories/${categoryUuid}`
      );

      return { ...data, status };
    } catch (error) {
      return this._exception(
        error,
        'Erro ao deletar informações de parcelamento'
      );
    }
  }
}

export default InterestRateAPI;
