import React, { useState, useEffect } from 'react';
import BaseSelect from 'react-select';
import FixRequiredSelect from './FixRequiredSelect';
/**
 * @Name: SelectComponent
 * @Data: 2019
 * @Desc: Select padrão do sistema.
 * @param  {} props - todas propriedades do select html e/ou quais outras forem necessárias.
 * @param  {} items - São as opções de seleção do componente
 * @param  {} defaults - defaults é um objeto literal que deverá obrigatóriamente ter como atributos *defaultName e *defaultValue
 * @param {} children - Implementação livre filha do select
 *
 * @obs Quando children for recebido como props, a implementação option padrão será ignorada.
 */

const Select = (props) => (
  <FixRequiredSelect {...props} SelectComponent={BaseSelect} />
);

const SelectComponent = (props) => {
  //Props
  const {
    items = [],
    multiple,
    onChange,
    message = 'Sem opções.',
    placeholder = 'Selecione',
    isClearable,
    defaultValue,
    className,
    required,
    styles,
  } = props;

  const [values, setValues] = useState(null);
  useEffect(() => {
    setValues(defaultValue);
  }, [defaultValue]);

  //Lida com alterações
  const handleChanges = (selecteds) => {
    setValues(selecteds);
    let values = selecteds;
    if (multiple) values = selecteds || [];
    return onChange && onChange(values);
  };

  //Estilos customizados do react-select
  const customStyles = {
    control: (base, state) => ({
      ...base,
      '&:hover': { borderColor: 'gray' },
      border: '1px solid lightgray',
      boxShadow: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      border: 'none',
      backgroundColor: state.isSelected ? '#00698F' : '#fff',
      ':active': {
        backgroundColor: '#00698F',
        color: '#ffffff',
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  return (
    <Select
      {...props}
      styles={styles || customStyles}
      isClearable={isClearable}
      placeholder={placeholder}
      options={items}
      className={className}
      value={values}
      isMulti={multiple}
      required={required}
      onChange={handleChanges}
      defaultValue={defaultValue}
      noOptionsMessage={() => message}
    />
  );
};
export default SelectComponent;
