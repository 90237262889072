import React from 'react';

/**
 * @Name: ColComponent
 * @Data: 2019
 * @Desc: Div com classe bootstrap que agrupa diversos componentes em uma coluna
 * @param  {string} className="col"
 * @param  {string} size - (lg, md ou sm)
 * @param  {string} cols - ( 0 a 12)
 * @param  {any} children
 * @param  {any} style
 **/

const CollumComponent = (props) => {
  // Recebe as propriedades
  const { className, size, cols, children, style } = props;
  return (
    <div className={className ? className : `col-${size}-${cols}`} style={style}>
      {children}
    </div>
  );
};

export default CollumComponent;
