import BaseModel from './BaseModel';

class CustomerGroupModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.uuid = data.uuid;
    this.name = data.name;
    this.excludes = data.excludes;
    this.criterias = data.criterias
      ? data.criterias.map((prop) => new CriteriaModel(prop))
      : [];
  }
}

export class CriteriaModel {
  constructor(data = {}) {
    this.uuid = data.uuid;
    this.criteria = data.criteria;
    this.value = data.value;
    this.customerUuid = data.customerUuid;
  }
}

export default CustomerGroupModel;
