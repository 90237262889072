import BaseAPI, { URLS } from '~/services/BaseAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';

/**
 * @function getProducts função para buscar os produtos
 * @param {String} filters filtros para busca de produto
 */

const StockAPI = {
  getProducts: async (filters, page, branch) => {
    try {
      const { data: response } = await BaseAPI.get(URLS.STOCKS, {
        search: filters,
        size: 10,
        branch,
        page,
      });
      return response;
    } catch (error) {
      const toast = new ToastHelper();

      toast.notify(STATUS_HELPER.ERROR, 'Estoque de produtos não encontrados');
    }
  },

  getBranches: async () => {
    try {
      const { data: response } = await BaseAPI.get(URLS.BRANCHES, {
        size: 999,
      });
      return response;
    } catch (error) {
      const toast = new ToastHelper();

      toast.notify(STATUS_HELPER.ERROR, 'Filiais não encontradas');
    }
  },
};

export default StockAPI;
