import BaseAPI, { URLS } from './BaseAPI';

class CustomerGroupAPI {
  /**Retorno generico de excessões */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
  * @param  {object} params - parametros de paginação
  */
  static async getList(params) {
    try {
      const response = await BaseAPI.get(URLS.CUSTOMER_GROUP, params);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar lista de grupo de clientes' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar lista de grupo de clientes');
    }
  }

  /**
   * Request que cria novo grupo de cliente.
   * @param  {Object} customerGroup - Objeto do grupo que sera criado
   */
   static async save(customerGroup) {
    try {
      const response = await BaseAPI.post(URLS.CUSTOMER_GROUP, customerGroup);
      if (response.status === 200 || response.status === 201) return response;
      return { error: 'Erro inesperado ao cadastrar o grupo de cliente' };
    } catch (e) {
      return this._exception(e, 'Falha ao cadastrar o grupo de cliente');
    }
  }

  /**
   * Request que atualiza um grupo de cliente.
   * @param  {Object} customerGroup - Objeto do grupo de cliente que será atualizado
   * @param  {String} uuid - Uuid do grupo de cliente que será atualizada
   */
   static async update(customerGroup, uuid) {
    try {
      const response = await BaseAPI.put(`${URLS.CUSTOMER_GROUP}/${uuid}`, customerGroup);
      if (response.status === 200 || response.status === 201) return response;
      return { error: 'Erro inesperado ao atualizar o grupo de cliente' };
    } catch (e) {
      return this._exception(e, 'Falha ao atualizar o grupo de cliente');
    }
  }

  /**
   * Request que deleta um grupo de cliente.
   * @param  {String} uuid - Uuid do grupo de cliente que será deletado
   */
   static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.CUSTOMER_GROUP}/${uuid}`);
      if (response.status === 200 || response.status === 201) return response;
      return { error: 'Erro inesperado ao deletar o grupo de cliente' };
    } catch (e) {
      return this._exception(e, 'Falha ao deletar o grupo de cliente');
    }
  }

  /**
   * Request que busca grupo de cliente pelo uuid.
   * @param  {String} uuid - Uuid da grupo de cliente
   */
  static async getCustomerGroupByUuid(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.CUSTOMER_GROUP}/${uuid}`);
      if (response.status === 201 || response.status === 200) return response.data;
      return { error: 'Erro ao recuperar informação do grupo de cliente!' }
    } catch (e) {
      return this._exception(e, 'Erro ao recuperar informação do grupo de cliente!');
    }
  }

  /**
   * Request que busca os tipos de criterio para ser usado no select.
   */
   static async getCriteriaOptions() {
    try {
      const response = await BaseAPI.get(`${URLS.CUSTOMER_GROUP}/criterias`);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar lista de grupo de clientes' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar lista de grupo de clientes');
    }
  }

  /**
   * Request que busca os nome de cliente para ser usado no criterio.
   * @param  {object} params - parametros de paginação
   */
   static async getCustomerByName(params) {
    try {
      const response = await BaseAPI.get(`${URLS.CUSTOMER_AVENIDA}`, params);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar lista de clientes' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar lista de clientes');
    }
  }

  /**
   * Request que ativa/inativa um grupo de cliente.
   * @param  {String} uuid - Uuid do grupo de cliente que será atualizada
   */
   static async statusChange(uuid) {
    try {
      const response = await BaseAPI.patch(`${URLS.CUSTOMER_GROUP}/${uuid}/setactive`);
      if (response.status === 200 || response.status === 204) return response;
      return { error: 'Erro inesperado ao atualizar o grupo de cliente' };
    } catch (e) {
      return this._exception(e, 'Falha ao atualizar o grupo de cliente');
    }
  }
}

export default CustomerGroupAPI;
