import BaseAPI, { URLS } from './BaseAPI';

class SalesPersonAPI {
  /**Retorno generico de excessões */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
  * @param  {object} params - parametros de paginação
  */
  static async getList(params) {
    try {
      const response = await BaseAPI.get(URLS.SALES_PERSON, params);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar lista de vendedores' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar lista de vendedores');
    }
  }

  /**
   * Request que cria novo vendedor.
   * @param  {Object} salesPerson - Objeto do vendedor que será criado
   */
   static async save(salesPerson) {
    try {
      const response = await BaseAPI.post(URLS.SALES_PERSON, salesPerson);
      if (response.status === 200 || response.status === 201) return response;
      return { error: 'Erro inesperado ao cadastrar o vendedor' };
    } catch (e) {
      if(e?.response?.status == 409)
        return this._exception(e, 'Vendedor já existente');  
      return this._exception(e, 'Falha ao cadastrar o vendedor');
    }
  }

  /**
   * Request que atualiza um vendedor.
   * @param  {Object} salesPerson - Objeto do vendedor que será atualizado
   * @param  {String} uuid - Uuid do vendedor que será atualizado
   */
   static async update(salesPerson, uuid) {
    try {
      const response = await BaseAPI.put(`${URLS.SALES_PERSON}/${uuid}`, salesPerson);
      if (response.status === 200 || response.status === 201) return response;
      return { error: 'Erro inesperado ao atualizar o vendedor' };
    } catch (e) {
      return this._exception(e, 'Falha ao atualizar o vendedor');
    }
  }

  /**
   * Request que deleta um vendedor.
   * @param  {String} uuid - Uuid do vendedor que será deletado
   */
   static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.SALES_PERSON}/${uuid}`);
      if (response.status === 200 || response.status === 204) return response;
      return { error: 'Erro inesperado ao deletar o vendedor' };
    } catch (e) {
      return this._exception(e, 'Falha ao deletar o vendedor');
    }
  }

  /**
   * Request que busca vendedor pelo uuid.
   * @param  {String} uuid - Uuid do vendedor
   */
  static async getSalesPersonByUuid(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.SALES_PERSON}/${uuid}`);
      if (response.status === 201 || response.status === 200) return response.data;
      return { error: 'Erro ao recuperar informação do vendedor!' }
    } catch (e) {
      return this._exception(e, 'Erro ao recuperar informação do vendedor!');
    }
  }

  static async saveSalesPersonByCSV(formData) {
    try {
      const response = await BaseAPI.post(`${URLS.SALES_PERSON}/upload-csv`, formData);

      if (response.status === 201 || response.status === 200) return response.data;
      return { error: 'Erro ao fazer upload do arquivo!' }
    } catch (e) {
      return this._exception(e, 'Erro ao fazer upload do arquivo!');
    }
  }
}

export default SalesPersonAPI;
