import { extendObservable } from 'mobx';
import { currencyToNumber } from '~/helpers/utils/Functions';
import BaseModel from './BaseModel';

class WeightRangeModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    extendObservable(this, {
      startWeight: data.startWeight,
      endWeight: data.endWeight,
      volumeKey: data.volumeKey,
      cost: data.cost,
      value: data.value,
      deliveryTime: data.deliveryTime,
    });
  }

  convertToNumber = () => {
    if (this.startWeight) this.startWeight = currencyToNumber(this.startWeight);
    if (this.endWeight) this.endWeight = currencyToNumber(this.endWeight);
    if (this.volumeKey) this.volumeKey = currencyToNumber(this.volumeKey);
    if (this.cost) this.cost = currencyToNumber(this.cost);
    if (this.value) this.value = currencyToNumber(this.value);
    if (this.deliveryTime)
      this.deliveryTime = currencyToNumber(this.deliveryTime);
  };
}

export default WeightRangeModel;
