import { extendObservable } from 'mobx';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import SalesAPI from '~/services/SalesAPI';

const initValues = {
  loading: false,
  salesDetails: [],
  sales: []
};

class SalesStore {
  totalPages = 0;
  page = 0;
  size = 10;
  sort = 'modified,desc';
  filter = '';

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  /**Consulta o relatórios de vendas*/
  async getSales(from, to) {
    this.loading = true;
    const params = {
      paymentsTypes: 'CREDIT_CARD;INVOICE',
      status: 'CART;NEW;ANALYSIS;CANCELED',
      from,
      to,
    };
    const response = await SalesAPI.getSales(params);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    else {
      this.sales = response;
      if (this.sales.percentageOrdersConverted === 'NaN')
        this.sales.percentageOrdersConverted = 0;
      this.getSalesDetails(from, to);
    }
    this.loading = false;
  }

  /**Consulta os detalhes do relatórios de vendas*/
  async getSalesDetails(from, to, page) {
    this.loading = true;
    const params = {
      paymentsTypes: 'CREDIT_CARD;INVOICE',
      status: 'CART;NEW;ANALYSIS;CANCELED',
      from,
      to,
      page,
      size: 10,
    };
    const response = await SalesAPI.getSalesDetails(params);
    this.salesDetails = [];

    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    else {
      response.content
        .sort((a, b) =>
          a.product_name.toUpperCase() > b.product_name.toUpperCase() ? 1 : -1
        )
        .map((i) => this.salesDetails.push(i));
    }
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.loading = false;
  }
}

export default SalesStore;
