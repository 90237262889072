import React from 'react';

/**
  * @Name: ActionIconComponent
  * @Data: 2019
  * @Desc: Icone de ação do sistema (fica no canto superior direito normalmente)
  * @props:
  *
  */

const ActionIconComponent = (props) => {
  // Recebe as propriedades
  const { icon, onClick } = props;
  return (
    <React.Fragment>
      <button type="button" className="close ative" aria-label="Close" onClick={onClick}>
        <i className={icon}></i>
      </button>
    </React.Fragment>
  )
}

export default ActionIconComponent;
