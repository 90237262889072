import BaseModel from './BaseModel';
const password = process.env.REACT_APP_SALES_PERSON_PASSWORD || 'grupoavenida123';

class SalesPersonModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.branchUuid = data.branchUuid || data?.branch?.uuid || undefined;
    this.branchName = data.branchName || data?.branch?.name || undefined;
    this.registrationNumber = data.registrationNumber;
    this.email = data.email;
    this.password = password;
  }
}

export default SalesPersonModel;
