import BaseAPI, { URLS } from './BaseAPI';

class ProductAPI {
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * Request que cria novo produto.
   * @param  {Object} product - Objeto do produto que será criado
   */
  static async save(product) {
    try {
      const response = await BaseAPI.post(URLS.PRODUCTS, product);
      if (response.status === 201) return response;
      return { error: 'Erro inesperado ao cadastrar o produto' };
    } catch (e) {
      return this._exception(e, 'Falha ao cadastrar o produto');
    }
  }

  /**
   * Request que habilita uma variação de um modelo.
   * @param  {string} productUuid - identificador do produto (nesse caso a variação)
   */
  static async activateVariation(productUuid) {
    try {
      const response = await BaseAPI.put(
        `${URLS.PRODUCTS}/${productUuid}/activate`
      );
      if (response.status === 200) return response;
      return { error: 'Erro inesperado ao habilitar a variação' };
    } catch (e) {
      return this._exception(e, 'Falha ao habilitar a variação');
    }
  }

  /**
   * Request que desabilita uma variação de um modelo.
   * @param  {string} productUuid - identificador do produto (nesse caso a variação)
   */
  static async disableVariation(productUuid) {
    try {
      const response = await BaseAPI.put(
        `${URLS.PRODUCTS}/${productUuid}/deactivate`
      );
      if (response.status === 200) return response;
      return { error: 'Erro inesperado ao desabilitar a variação' };
    } catch (e) {
      return this._exception(e, 'Falha ao desabilitar a variação');
    }
  }

  /**
   * Request que lista Produtos
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async list(params = {}) {
    try {
      const response = await BaseAPI.get(URLS.PRODUCTS, params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar produtos' };
    } catch (e) {
      return this._exception(e, 'Falha ao listar produtos');
    }
  }

  /**
   * Request que lista Produtos
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async getProductBySku(skuCode) {
    try {
      const response = await BaseAPI.get(
        `/manager/avenida-products?search=skuCode:*${skuCode}*,parent:false,active:true`
      );
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar produtos' };
    } catch (e) {
      return this._exception(e, 'Falha ao listar produtos');
    }
  }

  /** Retorna categories de um produto  */
  /**
   * @param  {string} uuid - Uuid do produto que quer as categorias
   */
  static async getCategories(produtUuid) {
    try {
      const url = `${URLS.PRODUCTS_KERNEL}/${produtUuid}/categories`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return {
        error: `${
          response.status === 404
            ? 'Produto naõ encontrado'
            : 'Produto não está em nenhuma categoria'
        }`,
      };
    } catch (e) {
      return this._exception(e, 'Produto sem categorias');
    }
  }

  /** Retorna categories de um produto  */
  /**
   * @param  {string} uuid - Uuid do produto que quer as categorias
   */
  static async getVariations(produtUuid) {
    try {
      const url = `${URLS.PRODUCTS}/${produtUuid}/variations`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return {
        error: `${
          response.status === 404
            ? 'Produto naõ encontrado'
            : 'Produto não está em nenhuma categoria'
        }`,
      };
    } catch (e) {
      return this._exception(e, 'Produto sem categorias');
    }
  }

  /** Monta uma query de acordo aos dados  */
  /**
   * @param  {object} prop - propriedade da busca (padrão uuid)
   * @param  {valor} value - valor a res pesquisado.
   */
  static async get(prop, value) {
    try {
      const url = `${URLS.PRODUCTS}/${value}/`;
      const response = await BaseAPI.get(url, { by: prop });
      if (response.status === 200) return response.data;
      return {
        error: `${
          response.status === 404
            ? 'Produto naõ encontrado'
            : 'falha ao buscar produto'
        }`,
      };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar produto');
    }
  }

  /**
   * Busca imagem do produto pelas metatags e products
   * @param  {string} productUuid -Uuid do produto
   * @param  {Array} metaTags - tags do produto.
   */
  static async getFile(productUuid, metaTags) {
    try {
      const array = Array.isArray(metaTags) ? metaTags : [metaTags];
      const url = `products/${productUuid}/${URLS.FILES}/`;
      const response = await BaseAPI.get(url, array);
      if (response.status === 200) return response.data;
      return {
        error: `${
          response.status === 404
            ? 'Produto naõ encontrado'
            : 'falha ao buscar imagem do produto'
        }`,
      };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar produto');
    }
  }

  /**
   * Request que lista Produtos
   * @param {string} productUuid Uuid do produto
   * @param {string} princingUuid Princing que será removido do produto
   * delete /manager/products/<uuid>/pricing/<uuid>
   */
  static async removeProcuctPricing(productUuid, princingUuid) {
    try {
      const response = await BaseAPI.delete(
        `${URLS.PRODUCTS}/${productUuid}/pricing/${princingUuid}`
      );
      if (response.status === 200) return response.data;
      return { error: 'Falha ao vincular grupo com produto' };
    } catch (e) {
      return this._exception(e, 'Falha ao vincular grupo com produto');
    }
  }

  /**
   * Request que lista Produtos
   * @param {string} productUuid Uuid do produto
   * @param {ProductPropertyModel} group Grupo que contém contém o princing do produto
   * @PostMapping("/products/{uuid}/pricing")
   */
  static async addProcuctPricing(productUuid, group) {
    try {
      const url = `${URLS.PRODUCTS}/${productUuid}/pricing`;
      const response = await BaseAPI.post(url, group);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao vincular grupo com produto' };
    } catch (e) {
      return this._exception(e, 'Falha ao vincular grupo com produto');
    }
  }

  /**
   * Request que lista Produtos Modelo
   * @param {string} name nome do produto
   * @GetMapping("/products/products/parents?name=engrenagem")
   */
  static async getParentProducts(name) {
    try {
      const url = `${URLS.PRODUCTS}/parents`;
      const response = await BaseAPI.get(url, { name });
      if (response.status === 200) return response.data;

      return { error: 'Falha ao vincular grupo com produto' };
    } catch (e) {
      return this._exception(e, 'Falha ao vincular grupo com produto');
    }
  }

  /**
   * Request que lista Produtos pelo tipo (MODEL ou VARIATION)
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async listByType(params = {}) {
    try {
      const url = `${URLS.PRODUCTS}`;
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar produtos' };
    } catch (e) {
      return this._exception(e, 'Falha ao listar produtos');
    }
  }

  /**
   * Atualizada campos de um produto.
   * @param  {Object} params
   */
  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.PRODUCTS}/${uuid}`, data);
      if (response.status === 200) return response;
      return { error: 'Erro inesperado ao atualizar produto' };
    } catch (e) {
      return this._exception(e, 'Erro ao atualizar produto');
    }
  }

  /**
   * Deleta produto.
   * @param  {string} uuid
   */
  static async delete(uuid) {
    try {
      const url = `${URLS.PRODUCTS}/${uuid}`;
      const response = await BaseAPI.delete(url);
      if (response.status === 204) return response.data;
      return {
        error:
          'Não foi possível deletar o produto! Verifique se ele está vinculado a categorias! Ou possui vendas.',
      };
    } catch (e) {
      return this._exception(
        e,
        'Não foi possível deletar o produto! Verifique se ele está vinculado a categorias! Ou possui vendas.'
      );
    }
  }

  /**
   * Request que lista Produtos
   * @param {Object} params São os parametros da busca, nesse caso podendo ser tanto o nome como o sku;
   */
  static async searchProductActiveByNameSKU(params = {}) {
    try {
      const response = await BaseAPI.get(`${URLS.PRODUCTS}/type/MODEL`, params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar produtos' };
    } catch (e) {
      return this._exception(e, 'Falha ao listar produtos');
    }
  }

  /**
   * Request que salva os valores dos atributos das categorias no produto.
   * @param  {Object} data - Objeto que contém o uuid do produto e um array com os atributos
   */
  static async saveProductFeatureValue(data) {
    try {
      const response = await BaseAPI.post('manager/product/feature', data);
      if (response.status === 201) return response;
      return {
        error:
          'Erro inesperado ao cadastrar os valores dos atributos de comparativo no produto',
      };
    } catch (e) {
      return this._exception(
        e,
        'Falha ao cadastrar os valores dos atributos de comparativo no produto'
      );
    }
  }

  /**
   * Request que salva os valores dos atributos das categorias no produto.
   * @param  {Object} data - Objeto que contém o uuid do produto e um array com os atributos
   */
  static async updateProductFeatureValue(data) {
    try {
      const response = await BaseAPI.put('manager/product/feature', data);
      if (response.status === 201) return response;
      return {
        error:
          'Erro inesperado ao atualizar os valores dos atributos de comparativo no produto',
      };
    } catch (e) {
      return this._exception(
        e,
        'Falha ao atualizar os valores dos atributos de comparativo no produto'
      );
    }
  }

  /**
   * Request que salva os valores dos atributos das categorias no produto.
   * @param  {Object} data - Objeto que contém o uuid do produto e um array com os atributos
   */
  static async listProductFeature(uuidProduct) {
    try {
      const response = await BaseAPI.get(
        `manager/product/${uuidProduct}/feature`
      );
      if (response.status === 200) return response;
      return {
        error:
          'Erro inesperado ao listar os valores dos atributos de comparativo no produto',
      };
    } catch (e) {
      return this._exception(
        e,
        'Falha ao listar os valores dos atributos de comparativo no produto'
      );
    }
  }

  /**
   * Request que retorna variações disponíveis filtradas de acordo com o term
   * @param {string} name nome do produto
   * @GetMapping("/products/products/variations?name=engrenagem")
   */
  static async getAvailableProductVariations(term) {
    try {
      const url = `${URLS.PRODUCTS}/available-variations`;
      const response = await BaseAPI.get(url, { skuCode: term });
      if (response.status === 200) return response.data.content;

      return { error: 'Falha ao retornar as variações disponiveis' };
    } catch (e) {
      return this._exception(e, 'Falha ao retornar as variações disponiveis');
    }
  }

  /**
   * Request que desassocia uma variação de um modelo.
   * @param  {string} productUuid - identificador do produto (nesse caso a variação)
   */
  static async disassociateVariation(productUuid) {
    try {
      const response = await BaseAPI.put(
        `${URLS.PRODUCTS}/${productUuid}/disassociate`
      );
      if (response.status === 200) return response;
      return { error: 'Erro inesperado ao desassociar a variação' };
    } catch (e) {
      return this._exception(e, 'Falha ao desassociar a variação');
    }
  }

  /**
   * Request que lista os atributos de comparativo da categoria.
   * @param  {string} categoryUuid - identificador da categoria
   */
  static async listCategoryFeatures(categoryUuid) {
    try {
      const url = `manager/category/${categoryUuid}/feature`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;

      return { error: 'Falha ao retornar os atributos da categoria' };
    } catch (e) {
      return this._exception(e, 'Falha ao retornar os atributos da categoria');
    }
  }

  /**
   * Request que lista os atributos de comparativo da categoria.
   * @param  {string} uuidProduct - identificador do produto
   */
  static async setCategoryFeatureValue(uuidProduct) {
    try {
      const url = `manager/product/${uuidProduct}/feature`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;

      return { error: 'Falha ao retornar os atributos do comparativo' };
    } catch (e) {
      return this._exception(
        e,
        'Falha ao retornar os atributos do comparativo'
      );
    }
  }

  /**
   * Retorna atributos do produto de acordo com o tipo
   * @param {('colors'|'units'|'sizes')} type
   */

  static async getProductsProps(type) {
    const exception = {
      colors: 'Falha ao retornar as cores dos produtos',
      sizes: 'Falha ao retornar os tamanhos dos produtos',
      units: 'Falha ao retornar as unidades de medida dos produtos',
    };

    try {
      const url = `manager/${type}?size=99`;

      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;

      return { error: exception[type] };
    } catch (e) {
      return this._exception(e, exception[type]);
    }
  }
}

export default ProductAPI;
