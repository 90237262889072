import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import MiniLoadingComponent from '~/components/MiniLoadingComponent/MiniLoadingComponent';
import StockAPI from '~/services/StockAPI';

export default function SelectBranch({ setBranch, selected }) {
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleBranches = async () => {
    setLoading(true);
    const response = await StockAPI.getBranches();

    if (response) {
      setBranches(
        response.content.map(({ externalId }) => ({
          label: externalId,
          value: externalId,
        }))
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    handleBranches();
  }, []);

  return loading ? (
    <div className="text-center">
      <MiniLoadingComponent />
    </div>
  ) : (
    <>
      <label htmlFor="skuCode">Filial</label>
      <Select
        placeholder="Selecione a filial"
        value={selected ? { value: selected, label: selected } : null}
        onChange={({ value }) => setBranch(value)}
        options={branches}
      />
    </>
  );
}
