import React from 'react';

const MiniLoadingComponent = () => {
  return (
    <span className="mini-loading">
      <span
        className="spinner-border spinner-border-sm "
        role="status"
        aria-hidden="true"
      />
    </span>
  );
};

export default MiniLoadingComponent;
