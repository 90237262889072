import BaseAPI, { URLS } from './BaseAPI';

class BranchAPI {
  /**Método util para retorno de erro */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * @param  {object} data - Dados da filial a ser criada
   */
  static async create(data) {
    try {
      const response = await BaseAPI.post(`${URLS.BRANCHES}`, data);
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return this._exception(e, 'Erro ao cadastrar filial.');
    }
  }

  /**
   * @param  {string} uuid - Uuid da filial a ser atualizada
   * @param  {object} data - Dados da filial a ser atualizada
   */
  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.BRANCHES}/${uuid}`, data);
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return this._exception(e, 'Erro ao cadastrar filial.');
    }
  }

  /**
   */
  static async getAll(params) {
    try {
      const response = await BaseAPI.get(`${URLS.BRANCHES}`, { ...params });
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return this._exception(e, 'Erro ao cadastrar filial.');
    }
  }

  /**
   * @param  {string} uuid - Uuid da branch a ser buscada
   */
  static async getBranchByUuid(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.BRANCHES}/${uuid}`);
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return this._exception(e, 'Erro ao buscar filial.');
    }
  }
}
export default BranchAPI;
