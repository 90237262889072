import BaseModel from '../BaseModel';
import SportbayAlternativeProductModel from './SportbayAlternativeProductModel';

class SportbayProductKitModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.productUuid = data.productUuid;
    this.productName = data.productName;
    this.productsAlternative = data.productsAlternative
      ? data.productsAlternative.map((props) => new SportbayAlternativeProductModel(props))
      : [];
  }
}

export default SportbayProductKitModel;
