/* eslint-disable no-undef */
import React from 'react';
import ButtonComponent from '../ButtonComponent/ButtonComponent';
import ModalComponent from '../ModalComponent/ModalComponent';
import TitleComponent from '../TitleComponent/TitleComponent';

/**
 * @Name: Modal
 * @Data: 2020
 * @Desc: Modal que se abre de forma suspensa.
 * @props:
 *
 */
const ModalConfirmationComponent = (props) => {
  const { id, title = 'Aviso', subtitle, onYesClick, loading = false } = props;

  const closeModal = () => {
    $(`#${id}`).modal('hide');
  };

  return (
    <>
      <ModalComponent id={id}>
        <div className=" ModalConfirmationComponent modal-body">
          <div className="title-container">
            <TitleComponent title={title} subtitle={subtitle} />
          </div>

          <div className="button-container">
            <ButtonComponent click={closeModal}>Não</ButtonComponent>
            <ButtonComponent loading={loading} click={onYesClick}>
              Sim
            </ButtonComponent>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default ModalConfirmationComponent;
