import BaseModel from './BaseModel';
import BannerFileModel from './BannerFileModel';

/**Modelo banner */
class BannerModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.tag = data.tag;
    this.nameTag = data.nameTag;
    this.categoryUUID = data.categoryUUID;
    this.active = data.active || false;
    this.responsive = data.responsive || false;
    this.files = data.files
      ? data.files.map((file) => new BannerFileModel(file))
      : [];
  }
}

export default BannerModel;
