import PropertyClassModel from '../PropertyClassModel';

/**
 * Tipo de variação (ex: cor)
 */
class PropertyClassAvenidaModel extends PropertyClassModel {
  constructor(data = {}) {
    super(data);

    this['@class'] =
      'br.com.stoom.avenida.model.persistence.AvenidaPropertyClass';
    this.required = data.required;
  }
}

export default PropertyClassAvenidaModel;
