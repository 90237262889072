import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import CardPainelComponent from '~/components/CardPainelComponent/CardPainelComponent';
import ListPainelComponent from '~/components/ListPainelComponent/ListPainelComponent';
import PaginationComponent from '~/components/PaginationComponent/PaginationComponent';
import SelectComponent from '~/components/SelectComponent/SelectComponent';
import HeaderComponent from './HeaderComponent';
import SearchComponent from '~/components/SearchComponent';

class ManagerLookContainer extends React.Component {
  constructor(props) {
    super(props);
    this.lookStore = props.lookStore;
    this.menuStore = props.menuStore;
  }

  componentDidMount() {
    const { size = 10 } = this.props;
    this.lookStore.setPage(0, size);
  }

  componentWillUnmount() {
    this.lookStore.reset();
  }

  onClick = (e) => {
    this.props.menuStore.setCurrentMenu('/cadastrar-look');
    this.props.history.push('/cadastrar-look');
  };

  findByName = (inputValue) =>
    this.lookStore.searchByName(inputValue, this.props.size);

  changeSort = (sort) => {
    let newSort;
    const currentSort = this.lookStore.sort;

    switch (sort) {
      case 'name':
        newSort = currentSort == 'name' ? 'name,desc' : 'name';
        break;
      case 'active':
        newSort = currentSort == 'active' ? 'active,desc' : 'active';
        break;
      default:
        newSort = '';
        break;
    }
    this.lookStore.sort = newSort;
    this.lookStore.getList(newSort);
  };

  findByStatus = (selectedValue) => {
    this.lookStore.searchByStatus(selectedValue, this.props.size);
  };

  onEdit(e, uuid) {
    e.preventDefault();
    this.props.history.push(`/editar-look/${uuid}`);
  }

  render() {
    const { looks, totalPages, loading, page, filter } = {
      ...this.lookStore,
    };

    const optionsSelectStatus = [
      {
        value: 'all',
        label: 'Todos',
      },
      {
        value: 'active',
        label: 'Ativo',
      },
      {
        value: 'inactive',
        label: 'Inativo',
      },
    ];

    const {
      large = true,
      title = 'Gerenciamento de looks',
      subtitle = 'Listagem dos looks cadastrados',
      size = 10,
      paginate = true,
    } = this.props;
    return (
      <>
        <CardPainelComponent
          loading={loading}
          large={large}
          title={title}
          subtitle={subtitle}
          iconNew={{ click: this.onClick }}
        >
          <div className="row">
            <SearchComponent changeSearch={this.findByName} value={filter} />
            <div className="col-lg-3 margin-search-filter dropdown-select-status">
              <SelectComponent
                onChange={(e) => this.findByStatus(e.value)}
                placeholder="Filtrar por status"
                name="status"
                id="status"
                items={optionsSelectStatus}
                disabled={loading}
              />
            </div>
          </div>
          <section>
            <HeaderComponent onClick={this.changeSort} />
            {looks &&
              looks.map((kt) => (
                <ListPainelComponent key={kt.uuid}>
                  <React.Fragment>
                    <div
                      className="col-8"
                      onClick={(e) => this.onEdit(e, kt.uuid)}
                    >
                      {kt.name}
                    </div>
                    <div
                      className="col-2"
                      onClick={(e) => this.onEdit(e, kt.uuid)}
                    >
                      {kt.status ? 'Ativo' : 'Inativo'}
                    </div>
                    <div className="col-1">
                      <i
                        className="fas fa-edit"
                        onClick={(e) => this.onEdit(e, kt.uuid)}
                      ></i>
                    </div>
                  </React.Fragment>
                </ListPainelComponent>
              ))}

            <PaginationComponent
              forcePage={page}
              pages={totalPages}
              onChange={(selectedItem) =>
                this.lookStore.setPage(selectedItem.selected, size)
              }
            />
          </section>
        </CardPainelComponent>
      </>
    );
  }
}

export default inject(
  'lookStore',
  'menuStore'
)(withRouter(observer(ManagerLookContainer)));
