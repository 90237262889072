import { extendObservable } from 'mobx';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import CampaignAPI from '~/services/CampaignAPI';
import CustomerGroupAPI from '~/services/CustomerGroupAPI';
import CampaignModel, { FixedPrice } from '~/models/CampaignModel';
import DiscountModel from '~/models/CampaignModel/Discount';
import { isArrayEmpty, onlyUnique } from '~/helpers/utils/Functions';
import UploadAPI from '../services/UploadAPI';
import { v4 as uuidv4 } from 'uuid';

import CampaignModelsEnum from '~/models/CampaignModel/enums/CampaignModelsEnum';
import {
  DateRestriction,
  ProductCategoryRestriction,
  PriceRestriction,
  CustomerGroupRestriction,
  ZipCodeRestriction,
  AvenidaPointOfSaleRestriction,
  AvenidaDeliveryRestriction,
  CustomerRestriction
} from '~/models/CampaignModel/RestrictionList';
import DeliveryTypeRestrictionEnum from '~/models/voucher/enums/DeliveryTypeRestriction';

const initValues = {
  loading: false,
  campaign: new CampaignModel({ active: false }),
  files: [],
  campaigns: [],
  products: [],
  productXML: [],
  isAllProducts: false,
  infiniteCampaign: false,
  dateRestriction: new DateRestriction(),
  productCategoryRestriction: new ProductCategoryRestriction(),
  avenidaPointOfSaleRestriction: new AvenidaPointOfSaleRestriction(),
  priceRestriction: new PriceRestriction(),
  customerGroupRestriction: new CustomerGroupRestriction(),
  zipCodeRestriction: new ZipCodeRestriction(),
  customerRestriction: new CustomerRestriction(),
  deleteImageUuid: '',
  typeApplication: [],
  typeCampaing: '',
  linkToCustomerGroup: false,
  optionsCustomerGroup: [],
  deliveryRestriction: undefined,
  buyXPayY: {
    x: 0,
    y: 0,
  },
};

/**Store que manipula dados de Montadoras. */
class CampaignStore {
  totalPages = 0;
  page = 0;
  size = 10;
  sort = 'created,desc';

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  /**Função que atribui o valor do campo no model de campanha */
  handleCampaignChange(prop, value) {
    const { campaign } = this;

    campaign[prop] = value;

    this.campaign = campaign;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, useLogin = true, sort) {
    this.page = numPage;
    this.size = size;
    sort && (this.sort = sort);
    await this.getList(useLogin);
    return this.totalPages;
  }

  /**Busca lista de campanhas */
  async getList(useLogin = true) {
    if (useLogin) this.loading = true;
    const params = { size: this.size, page: this.page, sort: this.sort };
    const response = await CampaignAPI.getList(params);
    if (useLogin) this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return [];
    }
    this.campaigns = response.content;
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  /**Adiciona o produto na lista de restrição */
  addProduct(product) {
    if (isArrayEmpty(this.productCategoryRestriction.products)) {
      this.productCategoryRestriction.products = [];
    }

    this.productCategoryRestriction.products.push(product);
    this.productCategoryRestriction.categories = [];
  }

  /**Adiciona Propriedade na categoria */
  addPropertieSelected(propertie) {
    if (isArrayEmpty(this.avenidaPointOfSaleRestriction?.pointsOfSale)) {
      this.avenidaPointOfSaleRestriction.pointsOfSale = [];
    }

    if (!this.avenidaPointOfSaleRestriction['pointsOfSale'].includes(propertie.value)) {
      this.avenidaPointOfSaleRestriction['pointsOfSale'].push(propertie.value);
      const typeApplication = this.typeApplication;
      typeApplication.push({ value: propertie.value });
    }
  }

  removePropertieSelected(propertie) {
    if (isArrayEmpty(this.avenidaPointOfSaleRestriction?.pointsOfSale)) {
      this.avenidaPointOfSaleRestriction.pointsOfSale = [];
    }

    if (this.avenidaPointOfSaleRestriction['pointsOfSale'].includes(propertie.value)) {
      const indexToRemove = this.avenidaPointOfSaleRestriction['pointsOfSale'].findIndex(i => i === propertie.value);
      if (indexToRemove > -1) {
        const updatedAvenidaPointOfSaleRestrictionPointsOfSale = [...this.avenidaPointOfSaleRestriction['pointsOfSale']];
        updatedAvenidaPointOfSaleRestrictionPointsOfSale.splice(indexToRemove, 1);

        this.avenidaPointOfSaleRestriction['pointsOfSale'] = [...updatedAvenidaPointOfSaleRestrictionPointsOfSale];

        const typeApplicationIndexToRemove = this.typeApplication.findIndex(typeApp => typeApp.value === propertie.value);
        if (typeApplicationIndexToRemove > -1) {
          const updatedTypeApplication = [...this.typeApplication];
          updatedTypeApplication.splice(typeApplicationIndexToRemove, 1);
          this.typeApplication = [...updatedTypeApplication]
        }
      }
    }
  }

  handleChangeDeliveryTypeSelected(type) {

    let optionTypes = [];

    if(type == DeliveryTypeRestrictionEnum.ALL)
      optionTypes = [ "SHIPMENT", "PICKUP"];

    if(type == DeliveryTypeRestrictionEnum.PICKUP)
      optionTypes = ["PICKUP"];

    if(type == DeliveryTypeRestrictionEnum.SHIPMENT)
      optionTypes = [ "SHIPMENT"];

    this.deliveryRestriction = new AvenidaDeliveryRestriction({ optionTypes });
  }

  /**Altera as propriedades de ZipCode*/
  handleChangeRestrictionProduct(prop, value) {
    value = value.replace(',', '.');
    this.productCategoryRestriction[prop] = value;
  }

  /**Remove o produto que está na lista de restrição */
  removeProduct(product) {
    if (isArrayEmpty(this.productCategoryRestriction.products)) {
      return;
    }

    this.productCategoryRestriction.products =
      this.productCategoryRestriction.products.filter(
        (f) => f.uuid !== product.uuid
      );
    this.productCategoryRestriction.categories = [];
    this.renderAttribute = !this.renderAttribute;
  }

  /** Limpa todos os produtos da lista de restrição */
  clearProducts() {
    this.productCategoryRestriction.products = [];
  }

  /**Função chamada ao setar todos os produtos no aplicável á */
  checkedAllProducts() {
    this.isAllProducts = true;
    this.productCategoryRestriction.categories = [];
    this.productCategoryRestriction.products = undefined;
  }

  /**Função chamada depois de mudar o modelo de campanha */
  selectedModelCampaign(modelSelected) {
    this.typeCampaing = modelSelected;
    this.isAllProducts = false;
    this.dateRestriction = new DateRestriction();
    this.avenidaPointOfSaleRestriction = new AvenidaPointOfSaleRestriction();
    this.productCategoryRestriction = new ProductCategoryRestriction();
    this.campaign.discounts = [new DiscountModel()];
    this.campaign.fixedPrice = new FixedPrice();
    this.zipCodeRestriction = new ZipCodeRestriction();
    this.customerRestriction = new CustomerRestriction();
    this.handleCampaignChange('type', modelSelected);
  }

  /**Função que seta se a campanha é infinita ou não */
  checkedInfiniteCampaign(checked) {
    this.infiniteCampaign = checked;

    if (checked) {
      this.dateRestriction.maxDate = undefined;
    }
  }

  /**Função que seta se a campanha é infinita ou não */
  checkedInfiniteCampaign(checked) {
    this.infiniteCampaign = checked;

    if (checked) {
      this.dateRestriction.maxDate = undefined;
    }
  }

  /**Seta a categoria que vai receber a campanha */
  setCategory(categories) {
    const selected = categories.map((category) => {
      return {
        uuid: category.uuid,
        categoryName: category.categoryName,
        '@class': category['@class'],
      };
    });

    this.categoriesRemove = this.productCategoryRestriction.categories.filter(
      (cat) => !selected.some((mCat) => mCat.uuid === cat.uuid)
    );
    this.categoriesAdd = selected.filter(
      (cat) =>
        !this.productCategoryRestriction.categories.some(
          (mCat) => mCat.uuid === cat.uuid
        )
    );

    this.productCategoryRestriction.categories = [
      ...this.productCategoryRestriction.categories,
      ...this.categoriesAdd,
    ]
      .filter((f) => this.categoriesRemove.indexOf(f) < 0)
      .filter(onlyUnique);
    this.productCategoryRestriction.products = [];

    return [
      ...this.productCategoryRestriction.categories,
      ...this.categoriesAdd,
    ]
      .filter((f) => this.categoriesRemove.indexOf(f) < 0)
      .filter(onlyUnique);
  }

  /**Remove a categoria da campanha */
  removeCategory() {
    this.productCategoryRestriction.categories = [];
    this.productCategoryRestriction.products = [];
  }

  /**Altera o a data da restrição */
  handleChangeDate(prop, value) {
    if (prop === 'maxDate') {
      this.dateRestriction.maxDate = value;
    } else {
      this.dateRestriction.minDate = value;
    }
  }

  /**Altera as propriedades de desconto */
  handleChangeDiscount(prop, value) {
    const updatedCampaign = { ...this.campaign };

    if (typeof value !== 'number') value = value.replace(',', '.');
    if (updatedCampaign.discounts.length > 0)
      updatedCampaign.discounts[0][prop] = value / 100;

    this.campaign = new CampaignModel(updatedCampaign);
  }

  /**Altera as propriedades de FixedPrice */
  handleChangeFixedPrice(prop, value) {
    const { campaign } = this;
    value = value.replace(',', '.');
    campaign.fixedPrice[prop] = value;
  }

  handleChangeZipCode(prop, value) {
    if (prop === 'initialZipCode') {
      this.zipCodeRestriction.initialZipCode = value;
    } else {
      this.zipCodeRestriction.finalZipCode = value;
    }
  }

  handleCustomerDocuments(prop, value) {
    if(prop == 'documents')
      this.customerRestriction.documents = value.map((doc) => doc.key);
    if(prop == 'excludes')
      this.customerRestriction.excludes = value;
  }

  /**Altera as propriedades de ZipCode*/
  handleChangeRestrictionZipCode(prop, value) {
    value = value.replace(',', '.');
    this.zipCodeRestriction[prop] = value;
  }

  /**Reseta a campanha ao entrar na pagina de criação/edição */
  reset() {
    this.campaign = new CampaignModel({ active: false });
    this.isAllProducts = false;
    this.dateRestriction = new DateRestriction();
    this.productCategoryRestriction = new ProductCategoryRestriction();
    this.avenidaPointOfSaleRestriction = new AvenidaPointOfSaleRestriction();
    this.customerGroupRestriction = new CustomerGroupRestriction();
    this.zipCodeRestriction = new ZipCodeRestriction();
    this.customerRestriction = new CustomerRestriction();
    this.infiniteCampaign = false;
    this.deleteImageUuid = '';
    this.linkToCustomerGroup = false;
    this.optionsCustomerGroup = [];
    this.buyXPayY = {
      x: 0,
      y: 0,
    };
  }

  /**Salva uma nova campanha */
  async onSave() {
    this.loading = true;
    const { campaign } = this;
    if (this.infiniteCampaign) {
      this.dateRestriction.maxDate = '2121-05-03T00:00:00.000Z';
    }

    const restrictions = this.setRestrictionsInBody(this.campaign.type);
    
    if(this.deliveryRestriction)
      restrictions.push(this.deliveryRestriction);
    
    campaign.restrictions = [...restrictions];

    if (this.linkToCustomerGroup) {
      campaign.restrictions.push(this.customerGroupRestriction);
    }
    
    if(this.customerRestriction && this.customerRestriction.documents.length > 0) {
      campaign.restrictions.push(this.customerRestriction);
    }

    campaign.banner = {
      tag: 'SLIDESHOW_PRINCIPAL',
      nameTag: campaign.name + uuidv4(),
      categoryUUID: '',
      active: true,
      responsive: false,
    };
    const response = await CampaignAPI.save(campaign);

    this.loading = false;

    if (!response.error) {
      this.campaign = response.data;
      this.sendNewFiles(this.campaign.banner.uuid, this.campaign);
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Campanha adicionada com sucesso!'
      );
      this.reset();
      return true;
    }

    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      'Erro ao adicionar a campanha'
    );
    return false;
  }

  /**Funcao para atualizar a campanha */
  async onUpdate() {
    this.loading = true;

    if (this.infiniteCampaign) {
      this.dateRestriction.maxDate = '2121-05-03T00:00:00.000Z';
    }

    const restrictions = this.setRestrictionsInBody(this.campaign.type);

    const { campaign } = this;

    if(this.deliveryRestriction)
      restrictions.push(this.deliveryRestriction);

    campaign.restrictions = restrictions;

    if (this.linkToCustomerGroup) {
      campaign.restrictions.push(this.customerGroupRestriction);
    }

    if(this.customerRestriction && this.customerRestriction.documents.length > 0) {
      campaign.restrictions.push(this.customerRestriction);
    }

    this.reset();
    const response = await CampaignAPI.update(campaign, campaign.uuid);

    this.loading = false;

    if (!response.error) {
      // Logica para atualizar a imagem se necessario imagem
      if (this.deleteImageUuid) {
        const deleteImage = await this.handleRemoveUpdateImage();

        if (deleteImage) {
          this.sendNewFiles(this.campaign.banner.uuid, this.campaign);
        }
      }
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Campanha atualizada com sucesso!'
      );

      return true;
    }

    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      'Erro ao atualizar a campanha'
    );
    return false;
  }

  /**Função para ativar/inativar campanha no grid */
  async activeCampaign(campaign) {
    const response = await CampaignAPI.switchActiveFlag(campaign.uuid);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        `Campanha ${campaign.active ? 'ativada' : 'inativada'} com sucesso`
      );
      return true;
    }
    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      `Erro ao ${campaign.active ? 'ativar' : 'inativar'} a campanha`
    );
    return false;
  }

  /**Função para deletar campanha */
  async deleteCampaign(campaignUuid) {
    const response = await CampaignAPI.delete(campaignUuid);

    if (!response.error) {
      await this.getList();
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Campanha deletada com sucesso'
      );
      return true;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, 'Falha ao deletar a campanha');
  }

  /**Função para buscar uma campanha para ser usada da tela de update */
  async getCampaignByUuid(campaignUuid) {
    const response = await CampaignAPI.getCampaignByUuid(campaignUuid);
    if (!response.error) {
      this.campaign = new CampaignModel(response);
      if (!!response?.discounts[0]?.buyXPayY?.x) {
        this.buyXPayY.x = response.discounts[0].buyXPayY.x;
        this.buyXPayY.y = response.discounts[0].buyXPayY.y;
      }
      if (response?.restrictions && response?.restrictions?.length > 0) {
        this.organizeRestrictions(response?.restrictions);

        const productsRestriction = response.restrictions?.map(restriction => restriction['@class'].includes('ProductCategoryRestriction'));
        if (productsRestriction && productsRestriction?.products?.length > 0) {
          this.productCategoryRestriction.products = [...productsRestriction.products];
        }
      }

      return this.campaign;
    }

    return false;
  }

  /**Manda uma lista de SKU e retorna SKU existente */
  async getListProductSku(listProduct) {
    const response = await CampaignAPI.getListProductSku(listProduct);
    if (!response.error) {
      return response;
    }
    return false;
  }

  /**Função para entrar nas restricoes e direcionar para o objetos */
  async organizeRestrictions(restrictions) {
    restrictions.map((r) => {
      switch (r['@class']) {
        case 'br.com.stoom.kernel.model.persistence.restriction.ProductCategoryRestriction':
          this.productCategoryRestriction = r;
          break;
        case 'br.com.stoom.kernel.model.persistence.restriction.DateRestriction':
          this.dateRestriction = r;
          if (r.maxDate === '2121-05-03T00:00:00.000+0000') {
            this.infiniteCampaign = true;
          }
          break;
        case 'br.com.stoom.kernel.model.persistence.restriction.PriceRestriction':
          this.priceRestriction = r;
          break;
        case 'br.com.stoom.avenida.model.persistence.restriction.AvenidaPointOfSaleRestriction':
          this.avenidaPointOfSaleRestriction = r;
          break;
        case 'br.com.stoom.avenida.model.persistence.restriction.AvenidaGroupRestriction':
          this.linkToCustomerGroup = true;
          this.customerGroupRestriction = r;
          break;
        case 'br.com.stoom.avenida.model.persistence.restriction.AvenidaZipCodeRestriction':
          this.zipCodeRestriction = r;
          break;
        case 'br.com.stoom.avenida.model.persistence.restriction.AvenidaDeliveryRestriction':
            this.deliveryRestriction = r;
          break;  
        case 'br.com.stoom.kernel.model.persistence.restriction.CustomerRestriction':
          this.customerRestriction = r;
          break;
        default:
          break;
      }
    });
  }

  /**Verificao da application type */
  applicationTypeVerify() {
    let applicationType = false;

    if (
      this.productCategoryRestriction.categories &&
      this?.productCategoryRestriction?.categories?.length > 0
    ) {
      this.productCategoryRestriction.categories[0].type === 'VITRINE'
        ? (applicationType = 'CATEGORIA')
        : (applicationType = 'COLECAO');
    } else if (
      this.productCategoryRestriction.products &&
      this.productCategoryRestriction.products.length > 0
    ) {
      applicationType = 'VARIACAO';
    } else {
      applicationType = 'VARIACAO';
      this.isAllProducts = true;
    }
    return applicationType;
  }

  /**Funcao utilizada para alterar valor da restricao buyXProductRestriction*/
  changeBuyXPayYRestriction(field, value) {
    if (field === 'x&y') {
      this.buyXPayY.x = value;
      this.buyXPayY.y = value - 1;

      const buyXPayY = {
        x: Number(value),
        y: Number(value - 1),
      };

      this.campaign.discounts[0] = new DiscountModel({
        buyXPayY: { ...buyXPayY },
      });
      return;
    }
    this.buyXPayY[field] = value;

    this.campaign.discounts[0] = new DiscountModel({
      productsPercentageDiscount: 1,
      buyXPayY: {
        x: Number(this.buyXPayY.x),
        y: Number(this.buyXPayY.y),
      },
    });
  }

  handleChangeDiscount(prop, value) {
    const { campaign } = this;
    value = value.replace(',', '.');
    if (campaign.discounts.length > 0)
      campaign.discounts[0][prop] = value / 100;

    this.campaign = new CampaignModel(campaign);
  }

  /**Funcao utilizada para alterar valor da restricao de priceRestriction */
  changePriceRestriction(prop, value) {
    const { priceRestriction } = this;
    priceRestriction[prop] = value;
    this.priceRestriction = priceRestriction;
  }

  /**Seta as restricoes de acordo com a campanha para usar no post/put */
  setRestrictionsInBody(type) {
    switch (type) {
      case CampaignModelsEnum.DESCONTO_PERCENTUAL:
        this.campaign.bean = 'Discount_Campaign';

        if (this.isAllProducts) {
          if (this.avenidaPointOfSaleRestriction?.pointsOfSale?.length > 0) {
            return [this.dateRestriction, this.avenidaPointOfSaleRestriction];
          }
          return [this.dateRestriction];
        } else {
          if (this.avenidaPointOfSaleRestriction?.pointsOfSale?.length > 0) {
            return [
              this.dateRestriction,
              this.productCategoryRestriction,
              this.avenidaPointOfSaleRestriction,
            ];
          }
          return [
            this.dateRestriction,
            this.productCategoryRestriction,
          ];
        }

      case CampaignModelsEnum.X_OFF_Y_UNIDADE:
        this.campaign.bean = 'Avenida_Buy_X_Pay_Y_Campaign';

        if (this.campaign.discounts.length > 0)
          this.campaign.discounts[0].amountToPay = 0;

        if (this.isAllProducts) {
          if (this.avenidaPointOfSaleRestriction?.pointsOfSale?.length > 0) {
            return [this.dateRestriction, this.avenidaPointOfSaleRestriction];
          }
          return [this.dateRestriction];
        } else {
          if (this.avenidaPointOfSaleRestriction?.pointsOfSale?.length > 0) {
            return [
              this.dateRestriction,
              this.productCategoryRestriction,
              this.avenidaPointOfSaleRestriction,
            ];
          }
          return [
            this.dateRestriction,
            this.productCategoryRestriction,
          ];
        }
      case CampaignModelsEnum.LEVE_X_PAGUE_Y:
        this.campaign.bean = 'Avenida_Buy_X_Pay_Y_Campaign';
        if (this.campaign.discounts.length > 0)
          this.campaign.discounts[0].amountPay = parseInt(
            this.campaign.discounts[0].amountPay
          );
        if (this.isAllProducts) {
          if (this.avenidaPointOfSaleRestriction?.pointsOfSale?.length > 0) {
            return [this.dateRestriction, this.avenidaPointOfSaleRestriction];
          }
          return [this.dateRestriction];
        } else {
          if (this.avenidaPointOfSaleRestriction?.pointsOfSale?.length > 0) {
            return [
              this.dateRestriction,
              this.productCategoryRestriction,
              this.avenidaPointOfSaleRestriction,
            ];
          }
          return [
            this.dateRestriction,
            this.productCategoryRestriction,
          ];
        }

      case CampaignModelsEnum.VALOR_BASE_COM_X_OFF:
        this.campaign.bean = 'Discount_Campaign';
        this.priceRestriction.maxPrice = 9999999999;
        this.priceRestriction.minPrice = parseFloat(
          this.priceRestriction.minPrice
        );
        if (this.avenidaPointOfSaleRestriction?.pointsOfSale?.length > 0) {
          return [
            this.dateRestriction,
            this.priceRestriction,
            this.productCategoryRestriction,
            this.avenidaPointOfSaleRestriction,
          ];
        }

        return [
          this.dateRestriction,
          this.productCategoryRestriction,
          this.priceRestriction,
        ];

      case CampaignModelsEnum.Fixed_Price_Campaign:
        let restriction = [];
        this.campaign.bean = 'Fixed_Price_Campaign';

        restriction.push(this.dateRestriction);

        if (
          this.productCategoryRestriction.products ||
          this.productCategoryRestriction.categories
        ) {
          restriction.push(this.productCategoryRestriction);
        }

        if (
          this.zipCodeRestriction?.initialZipCode &&
          this.zipCodeRestriction?.finalZipCode
        ) {
          restriction.push(this.zipCodeRestriction);
        }
        if (this.avenidaPointOfSaleRestriction?.pointsOfSale?.length > 0) {
          return [...restriction, this.avenidaPointOfSaleRestriction];
        }
        return [...restriction];


      case CampaignModelsEnum.Progressive_Discount:
        this.campaign.bean = 'Progressive_Discount_Campaign';
        if (this.avenidaPointOfSaleRestriction?.pointsOfSale?.length > 0) {
          return [
            this.dateRestriction,
            this.productCategoryRestriction,
            this.avenidaPointOfSaleRestriction,
          ];
        }

        return [
          this.dateRestriction,
          this.productCategoryRestriction,
        ];
      default:
        return [];
    }
  }

  /**Salva o file de imagem no bannerFile */
  handleUploadImageBannerFile(file) {
    this.files = file;
  }

  /** Upload de novas imagens */
  async sendNewFiles(bannerUuid, campaign) {
    const objectBannerFile = {
      name: campaign.name + uuidv4(),
      ordering: 1,
      promotionUUID: campaign.uuid,
      link: `/campanha/${campaign.uuid}`,
      description: 'Descrição',
      subDescription: 'Sub-Descrição',
    };

    const response = await UploadAPI.sendNewFileBanner(
      bannerUuid,
      objectBannerFile,
      this.files
    );

    if (!response.error) {
      return true;
    }

    return false;
  }

  /**Funcão útil paradá feedback sobre envio de arquivos. */
  async handlePromises(promises, message) {
    const response = await Promise.all(promises);
    const errors = response.filter((r) => r && r.error !== undefined);
    if (errors && errors.length > 0) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, message);
    }
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /** Remove uma imagem que está salva no backend*/
  async handleRemoveUpdateImage() {
    const response = await CampaignAPI.deleteImageBanner(
      this.campaign.banner.uuid,
      this.deleteImageUuid
    );

    if (!response.error) {
      return true;
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return false;
    }
  }

  /**Funcao para restar o array de restricao de categoria/produto */
  changeApplicationType(isEdit) {
    if (!isEdit) {
      this.productCategoryRestriction.products = [];
      this.productCategoryRestriction.categories = [];
    }
  }

  /**Armazena o uuid da imagem que precisa ser deletada no update */
  onDeleteImageUpdateCampaign() {
    const { campaign } = this;
    this.deleteImageUuid = campaign?.banner?.files[0]?.uuid;
  }

  handleChangeLinkToCustomerGroup(checked) {
    let { linkToCustomerGroup } = this;
    linkToCustomerGroup = checked;
    this.linkToCustomerGroup = linkToCustomerGroup;
  }

  /**Busca grupo de cliente pelo nome */
  async findCustomerGroupByName(name) {
    const params = {
      name,
      sort: 'created,desc',
      size: 20,
      page: 0,
    };

    const response = await CustomerGroupAPI.getList(params);

    if (!response.error) {
      const options = [];
      if (response.content && response.content.length > 0) {
        response.content.map((b) => {
          options.push({ value: b.uuid, label: b.name });

          return false;
        });
      }
      this.optionsCustomerGroup = options;
      return options;
    }

    return [];
  }

  handleChangeCustomerGroup(uuid) {
    const { customerGroupRestriction } = this;

    if (uuid) {
      customerGroupRestriction.customerGroups = [
        {
          '@class':
            'br.com.stoom.avenida.model.persistence.AvenidaCustomerGroup',
          uuid,
        },
      ];
    } else {
      customerGroupRestriction.customerGroups = [];
    }

    this.customerGroupRestriction = customerGroupRestriction;
    this.optionsCustomerGroup = [];
  }

  /**Altera as propriedades de FixedPrice */
  handleChangeRestrictionGrup(prop, value) {
    value = value.replace(',', '.');
    this.customerGroupRestriction[prop] = value;
  }

  addProgressiveDiscount() {
    if (this.campaign.discounts) {
      const newCampaign = Object.assign({}, this.campaign, {
        discounts: this.campaign.discounts.concat(new DiscountModel()),
      });
      this.campaign = newCampaign;
    }
  }

  removeProgressiveDiscount(index) {
    if (this.campaign.discounts) {
      const discounts = this.campaign.discounts;

      discounts.splice(index, 1);

      const newCampaign = Object.assign({}, this.campaign, {
        discounts: discounts,
      });

      this.campaign = newCampaign;
    }
  }

  handleChangeProgressiveDiscount(index, prop, value) {
    if (this.campaign.discounts) {
      let discounts = this.campaign.discounts.map(
        (discount) =>
          new DiscountModel({
            ...discount,
            productsPercentageDiscount: discount.productsPercentageDiscount,
          })
      );

      value = value.replace(',', '.');
      if (prop == 'productsPercentageDiscount')
        discounts[index][prop] = value / 100;
      else discounts[index][prop] = value;

      const newCampaign = Object.assign({}, this.campaign, {
        discounts: discounts,
      });

      this.campaign = new CampaignModel(newCampaign);
    }
  }
}

export default CampaignStore;
