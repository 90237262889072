import React from 'react';

// arrowSide can be up or down
export default ({ items, columnSelected, arrowSide }) => (
  <div className=" ListPainelComponent headerListPainelComponent ">
    {items.map((itemCollum, idx) => {
      let classSelected = '';
      if (columnSelected && columnSelected === itemCollum.id) {
        classSelected = ' selected';
      }

      return (
        <div
          key={`heade-list-${idx}-${itemCollum.id}`}
          className={`col-${itemCollum.size}${classSelected} ${arrowSide}`}
          id={itemCollum.id}
          onClick={(e) => itemCollum.callback(e)}
        >
          {itemCollum.label}
        </div>
      );
    })}
  </div>
);
