import BaseAPI, { URLS } from './BaseAPI';

class BannerAPI {
  /**Método util para retorno de erro */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * Request que lista os banners
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async list(params = {}) {
    try {
      const response = await BaseAPI.get('manager/avenida-banners', params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar banners' };
    } catch (e) {
      return this._exception(e, 'Falha ao listar banners');
    }
  }

  static async checkBanner(params = {}) {
    try {
      const response = await BaseAPI.get('manager/banners', params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar banners' };
    } catch (e) {
      return this._exception(e, 'Falha ao listar banners');
    }
  }

  /**
   * Request que cria novo banner.
   * @param  {Object} banner - Objeto do banner que será criado
   */
  static async save(banner) {
    try {
      const response = await BaseAPI.post('manager/avenida-banners/', banner);
      if (response.status === 201) return response;
      return { error: 'Erro inesperado ao cadastrar o banner' };
    } catch (e) {
      return this._exception(e, 'Falha ao cadastrar o banner');
    }
  }

  /**
  * Atualizada campos de um banner.
  * @param  {Object} data - Objeto com os campos do banner
  * @param  {String} bannerUuid - uuid do banner
  */
  static async update(bannerUuid, data) {
    try {
      const response = await BaseAPI.put(`manager/avenida-banners/${bannerUuid}`, data);
      if (response.status === 200) return response;
      return { error: 'Erro inesperado ao atualizar o banner' };
    } catch (e) {
      return this._exception(e, 'Erro ao atualizar o banner');
    }
  }

  /**
   * Request que busca o banner pelo uuid
   * @param {String} bannerUuid uuid do banner
   */
  static async getByUuid(bannerUuid) {
    try {
      const url = `${URLS.BANNER}/${bannerUuid}`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar o banner' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar o banner');
    }
  }

  /**
   * Deleta imagem do banner
   * @param  {string} bannerUuid - uuid do banner
   * @param  {string} bannerFileUuid - uuid do objeto que a imagem foi salva
   */
  static async deleteImageBanner(bannerUuid, bannerFileUuid) {
    try {
      const url = `${URLS.BANNER}/${bannerUuid}/image/${bannerFileUuid}`;
      const response = await BaseAPI.delete(url);
      if (response.status === 204) return response.data;
      return {
        error: 'Não foi possível deletar a imagem do banner.',
      };
    } catch (e) {
      return this._exception(
        e, 'Não foi possível deletar a imagem do banner.',
      );
    }
  }

  /**
  * Ativa um banner pelo uuid.
  * @param  {String} bannerUuid - uuid do banner
  */
  static async activeBannerByUuid(bannerUuid) {
    try {
      const response = await BaseAPI.put(`${URLS.BANNER}/${bannerUuid}/active`);
      if (response.status === 204) return response;
      return { error: 'Erro inesperado ao ativar o banner' };
    } catch (e) {
      return this._exception(e, 'Erro ao ativar o banner');
    }
  }

  /**
  * Desativa um banner pelo uuid.
  * @param  {String} bannerUuid - uuid do banner
  */
  static async inactiveBannerByUuid(bannerUuid) {
    try {
      const response = await BaseAPI.put(`${URLS.BANNER}/${bannerUuid}/inactive`);
      if (response.status === 204) return response;
      return { error: 'Erro inesperado ao desativar o banner' };
    } catch (e) {
      return this._exception(e, 'Erro ao desativar o banner');
    }
  }

  static async getTags() {
    try {
      const response = await BaseAPI.get('manager/avenida-banners/tags');
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar banners' };
    } catch (e) {
      return this._exception(e, 'Falha ao listar banners');
    }
  }
}

export default BannerAPI;
