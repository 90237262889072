import BaseAPI, { URLS } from './BaseAPI';

class CategoryAPI {
  /**Retorno generico de excessões */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * Deleta Categoria.
   * @param  {string} uuid da categoria
   */
  static async delete(uuid) {
    try {
      const url = `${URLS.CUSTOM_CATEGORIES}/${uuid}`;
      const response = await BaseAPI.delete(url);
      if (response.status === 200) return response.data;
      return { error: 'Não foi possível deletar categoria' };
    } catch (e) {
      return this._exception(
        e,
        'Não foi possível deletar categoria verifique vinculos'
      );
    }
  }

  /**
   * Request que busca categoria por uuid
   * @param  {string} uuid - uuid do usuário
   */
  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.CATEGORIES}/${uuid}`);
      if (response.status === 200) return response.data;
      return { error: 'Erro ao buscar categoria.' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar Categoria.');
    }
  }

  /**
   * Request que atualiza dados da categoria
   * @param  {string} uuid - uuid do usuário
   * @param  {object} data - dados da categoria
   */
  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.CUSTOM_CATEGORIES}/${uuid}`, data);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao atualizar categoria.' };
    } catch (e) {
      if (e.response?.status === 500) {
        return { status: e.response.status, error: e.response.data.message };
      }
      return this._exception(e, 'Falha ao adicionar categoria.');
    }
  }

  /**
   * Request que cria novo produto.
   * @param  {Object} values - Objeto da categoria que será criado
   */
  static async save(values) {
    try {
      const response = await BaseAPI.post(URLS.CUSTOM_CATEGORIES, values);
      if (response.status === 201) return response.data;
      return { error: 'Falha ao cadastrar categoria' };
    } catch (e) {
      if (e.response?.status === 500 || e.response?.status === 409) {
        return { status: e.response.status, error: e.response.data.message };
      }
      return this._exception(e, 'Falha ao adicionar categoria.');
    }
  }

  /**
   * Request que vincula produtos em uma categoria.
   * @param  {Object} uuid - Uuid da categoria
   * @param  {Array} produts - Objeto produto que será invulado.
   */
  static async addProductsCategory(uuid, produts) {
    try {
      const url = `${URLS.CATEGORIES}/${uuid}/products`;
      const arrayProducts = Array.isArray(produts) ? produts : [produts];
      const response = await BaseAPI.put(url, arrayProducts);

      if (response.status === 200) return response;
      return { error: `Falha ao vincular categoria e produto` };
    } catch (e) {
      return this._exception(e, 'Falha ao vincular categoria e produto');
    }
  }

  /**
   * Request que vincula produtos em uma categoria.
   * @param  {Object} uuid - Uuid da categoria
   * @param  {Array} produts - Objeto produto que será invulado.
   */
  static async removeProductsCategory(uuid, produts) {
    try {
      const url = `${URLS.CATEGORIES}/${uuid}/products`;
      const arrayProducts = Array.isArray(produts) ? produts : [produts];
      const response = await BaseAPI.delete(url, arrayProducts);
      if (response.status === 200) return response;
      return { error: `Falha ao desvincular categoria e produto` };
    } catch (e) {
      return this._exception(e, 'Falha ao desvincular categoria e produto');
    }
  }

  /**
   * @param  {object} params - parametros de paginação
   */
  static async getList(params) {
    try {
      const response = await BaseAPI.get(URLS.CATEGORIES, params);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar lista de categorias' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar lista de categorias');
    }
  }

  /**
   */
  static async getTree() {
    try {
      const response = await BaseAPI.get(`${URLS.CUSTOM_CATEGORIES}/tree?size=1000`);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar lista de categorias' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar lista de categorias');
    }
  }

  static async getCategoriesTree() {
    try {
      const response = await BaseAPI.get(`manager/avenidaCategories/tree`);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar lista de categorias' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar lista de categorias');
    }
  }

  /**
   * Request que salva atributos na categoria.
   * @param  {Object} data - Objeto que contém o id da categoria o um array com as features
   */
  static async saveFeaturesOnCategory(feature) {
    try {
      const response = await BaseAPI.post(
        `${URLS.CATEGORIES}/feature`,
        feature
      );
      if (response.status === 201) return response;
      return { error: 'Erro inesperado ao cadastrar o atributo na categoria' };
    } catch (e) {
      return this._exception(e, 'Falha ao cadastrar o atributo na categoria');
    }
  }

  /**
   * Request que lista os atributos da categoria.
   * @param  {String} uuid - Uuid da categoria
   */
  static async getAllFeaturesCategory(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.CATEGORIES}/${uuid}/feature`);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao listar atributos da categorias' };
    } catch (e) {
      return this._exception(e, 'Falha ao listar atributos da categorias');
    }
  }

  /**
   * Desabilita o atributo da categoria correspondente.
   * @param  {String} uuid - id do atributo
   */
  static async delete(uuid) {
    try {
      const url = `/manager/categories/${uuid}`;
      const response = await BaseAPI.delete(url);
      if (response.status === 200) return response.data;
      return {
        error: 'Não foi possível deletar o atributo.',
      };
    } catch (e) {
      return this._exception(e, 'Não foi possível deletar o atributo.');
    }
  }

  /**
   * @param  {object} data - Salvar produtos
   */
  static async saveComparative(data) {
    try {
      const response = await BaseAPI.post(`${URLS.CATEGORY}/feature`, data);
      if (response.status === 201) return response.data;
      return { error: 'Falha ao associar categoria' };
    } catch (e) {
      if (e.response?.status === 500) {
        return { status: e.response.status, error: e.response.data.message };
      }
      return this._exception(e, 'Falha ao associar categoria.');
    }
  }

  /**
   * Request que busca categoria por uuid
   * @param  {string} uuid - uuid do usuário
   */
  static async getCategoriesFeatures(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.CATEGORY}/${uuid}/feature`);
      if (response.status === 200) return response.data;
      return { error: 'Erro ao buscar categoria.' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar Categoria.');
    }
  }

  /**
   * Deleta um item do comparativo.
   * @param  {string} uuid da categoria
   */
  static async removeComparativeSelected(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.CATEGORY}/feature/${uuid}`);
      if (response.status === 200) return response.data;
      return { error: 'Não foi possível deletar categoria' };
    } catch (e) {
      return this._exception(
        e,
        'Não foi possível deletar categoria verifique vinculos'
      );
    }
  }
}

export default CategoryAPI;
