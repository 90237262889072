import React from 'react';
import { Link } from 'react-router-dom';

const GridComponent = ({ branches, products, productStocks }) => (
  <div className="table-responsive">
    <table className="table ">
      <thead className="thead-light">
        <tr>
          <th scope="col">SKU</th>
          {branches?.map((branch) => (
            <th key={branch} scope="col">
              {branch}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {products.map((product, index) => (
          <tr key={product.productUuid}>
            <th scope="row">
              <Link to={`/editar-produto/${product.productUuid}`}>
                {product.skuCode}
              </Link>
            </th>

            {productStocks[index].map((branch, index) => (
              <td key={`${branch}${index}`}>
                {branch ? branch.totalStock : 0}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default GridComponent;
