import BaseAPI, { URLS } from "./BaseAPI";

export const VoucherBatchAPI = () => {
  const _exception = (e, message) => {
    return { error: message }
  }

  const createVoucher = async (data) => {
    try {
      const response = await BaseAPI.post(URLS.VOUCHERBATCH, data);
      if (response.status === 201 || response.status === 200) return response.data;
      return { error: 'Erro ao cadastrar cupom de desconto!' }
    } catch (e) {
      return e.response.status === 409
        ? _exception(e, 'Já existe um cupom cadastrado com esse nome!')
        : _exception(e, 'Erro ao cadastrar cupom de desconto!');
    }
  }

  const putVoucher = async (data, voucherId) => {
    try {
      const response = await BaseAPI.put(`${URLS.VOUCHERBATCH}/${voucherId}`, data);
      if (response.status === 201 || response.status === 200) return response.data;
      return { error: 'Erro ao cadastrar cupom de desconto!' }
    } catch (e) {
      return _exception(e, 'Erro ao cadastrar cupom de desconto!');
    }
  }

  const deleteVoucher = async (voucherId) =>{
    try {
      const response = await BaseAPI.delete(`${URLS.VOUCHERBATCH}/${voucherId}`);
      if (response.status === 201 || response.status === 200) return response.data;
      return { error: 'Erro ao excluir cupom de desconto!' }
    } catch (e) {
      return _exception(e, 'Erro ao excluir cupom de desconto!');
    }
  }

  const listVouchers = async (params) => {
    const paramsEntries = Object.entries(params);
    let urlParams = '';

    paramsEntries.forEach((param, index) => {
      if (param[1] || param[1] === 0) {
        urlParams += `${param[0]}=${param[1]}${index === (paramsEntries.length - 1) ? '' : '&'}`;
      }
    });

    try {
      const response = await BaseAPI.get(`/manager/avenida-voucherBatches?${urlParams}`);
      if (response.status === 201 || response.status === 200) return response.data;
      return { error: 'Erro ao listar cupons!' }
    } catch (e) {
      return _exception(e, 'Erro ao listar cupons!');
    }
  }

  const getVoucherInfo = async (voucherId) => {
    try {
      const response = await BaseAPI.get(`${URLS.VOUCHERBATCH}/${voucherId}`);
      if (response.status === 201 || response.status === 200) return response.data;
      return { error: 'Erro ao recuperar informação do cupom!' }
    } catch (e) {
      return _exception(e, 'Erro ao recuperar informação do cupom!');
    }
  }


  const updateVoucher = async (voucherId, voucher) => {
    try {
      const response = await BaseAPI.put(`${URLS.VOUCHERBATCH}/${voucherId}`, voucher);
      if (response.status === 201 || response.status === 200) return response.data;
      return { error: 'Erro ao atualizar cupom!' }
    } catch (e) {
      return _exception(e, 'Erro ao atualizar cupom!');
    }
  }

  const setVoucherStatus = async (voucherId) => {
    try {
      const response = await BaseAPI.patch(`${URLS.VOUCHERBATCH}/${voucherId}`);
      if (response.status === 201 || response.status === 200) return response.data;
      return { error: 'Erro ao atualizar cupom!' }
    } catch (e) {
      return _exception(e, 'Erro ao atualizar cupom!');
    }
  }

  return {
    createVoucher,
    putVoucher,
    listVouchers,
    getVoucherInfo,
    updateVoucher,
    deleteVoucher,
    setVoucherStatus
  };
}
