import { extendObservable } from 'mobx';

import VoucherBatchModel from '~/models/voucher/VoucherBatch';
import DiscountModel from '~/models/voucher/Discount';
import VoucherModel from '~/models/voucher/Voucher';

import {
  CustomerRestriction,
  DateRestriction,
  FirstPurchaseRestriction,
  ProductCategoryRestriction,
  ZipCodeRestriction,
  PriceRestriction,
  AvenidaDeliveryRestriction,
  GroupRestriction,
} from '~/models/voucher/RestrictionList';
import DeliveryTypeRestrictionEnum from '~/models/voucher/enums/DeliveryTypeRestriction';

import { VoucherBatchAPI } from '~/services/VoucherBatchAPI';

import ToastHelper, { STATUS_HELPER } from '../helpers/ToastHelper';
import { isArrayEmpty } from '~/helpers/utils/Functions';

const voucherBatchAPI = VoucherBatchAPI();

/**Valores inicias de variaveis observadas */
const initValues = {
  loading: false,
  totalPages: 0,
  page: 0,
  size: 15,
  productCategoryRestriction: new ProductCategoryRestriction(),
  sort: 'created,desc',
  vouchers: [],
  isAllProducts: false,
  selectedVoucher: null,
};

class VoucherBatchStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, initValues);
  }

  createVoucher = async (data, voucherId = null) => {
    const restrictionsList = [];
    const completedCoupon = new VoucherBatchModel({
      name: data.couponName,
      maxUses: data.numberOfUses,
      active: data.active,
    });

    const discount = new DiscountModel(
      data.isShippingFree
        ? {
            shippingAbsoluteDiscount:
              data.discountType === 'absoluto' ? data.disccount : 0,
            shippingPercentageDiscount:
              data.discountType === 'percentual' ? data.disccount : 0,
          }
        : {
            productsAbsoluteDiscount:
              data.discountType === 'absoluto' ? data.disccount : 0,
            productsPercentageDiscount:
              data.discountType === 'percentual' ? data.disccount : 0,
          }
    );

    delete data.isShippingFree;

    completedCoupon.discount = discount;
    const voucher = new VoucherModel({
      identifier: data.couponName,
    });

    const categoryProductRestriction = new ProductCategoryRestriction({
      excludes: data.excludeProduct,
    });

    completedCoupon.vouchers.push(voucher);

    if (data.group) {
      const groupRestriction = new GroupRestriction(data.group, {
        excludes: data.excludeGroup,
      });

      restrictionsList.push(groupRestriction);
    }

    if (data.expirationDate.from && data.expirationDate.to) {
      const dateRestriction = new DateRestriction({
        minDate: data.expirationDate.from,
        maxDate: data.expirationDate.to,
        excludes: data.excludeData,
      });

      restrictionsList.push(dateRestriction);
    }

    if (data?.deliveryType) {

      let optionTypes = [];

      if(data?.deliveryType == DeliveryTypeRestrictionEnum.ALL)
        optionTypes = [ "SHIPMENT", "PICKUP"];

      if(data?.deliveryType == DeliveryTypeRestrictionEnum.PICKUP)
        optionTypes = ["PICKUP"];

      if(data?.deliveryType == DeliveryTypeRestrictionEnum.SHIPMENT)
        optionTypes = [ "SHIPMENT"];

      const avenidaDeliveryRestriction = new AvenidaDeliveryRestriction({optionTypes});

      restrictionsList.push(avenidaDeliveryRestriction);
    }
    delete data?.deliveryType;

    if (data?.basePrice) {
      const priceRestriction = new PriceRestriction({
        minPrice: data.basePrice,
        excludes: data.excludePrice,
      });
      restrictionsList.push(priceRestriction);
    }

    if (data.zipCode?.initial || data.zipCode?.final) {
      const zipCodeRestriction = new ZipCodeRestriction({
        initialZipCode: data.zipCode.initial,
        finalZipCode: data.zipCode.final,
        excludes: data.excludeZipCode
      });
      restrictionsList.push(zipCodeRestriction);
    }

    if (data.orderType === 'onlyFirst') {
      const firstPurchaseRestriction = new FirstPurchaseRestriction({
        excludes: data.excludeFirstBuy,
      });

      restrictionsList.push(firstPurchaseRestriction);
    }

    if (data.documentList.length > 0) {
      const customerRestriction = new CustomerRestriction({
        documents: data.documentList.map((doc) => doc.key),
        emails: [],
        excludes: data.excludeCustomer
      });

      restrictionsList.push(customerRestriction);
    }

    if (data.categoryList.length > 0) {
      const categories = data.categoryList.map((category) => ({
        '@class': 'br.com.stoom.avenida.model.persistence.AvenidaCategory',
        uuid: category.uuid,
      }));

      categoryProductRestriction.categories = categories;
    }

    if (data.productList.length > 0) {
      const productListModel = data.productList.map((prod) => {
        return {
          uuid: prod.key,
        };
      });

      categoryProductRestriction.products = productListModel;
    }

    if (data.categoryList.length > 0 || data.productList.length > 0) {
      restrictionsList.push(categoryProductRestriction);
    }

    // /manager/voucherBatches
    completedCoupon.restrictions = [...restrictionsList];

    let response;
    if (voucherId) {
      response = await voucherBatchAPI.putVoucher(completedCoupon, voucherId);
    } else {
      response = await voucherBatchAPI.createVoucher(completedCoupon);
    }
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        `${
          voucherId
            ? 'Cupom atualizado com suceso!'
            : 'Cupom criado com suceso!'
        }`
      );
      this.selectedVoucher = null;
      return true;
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    // this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return false;
  };

  /**Adiciona o produto na lista de restrição */
  addProduct(product) {
    if (isArrayEmpty(this.productCategoryRestriction.products)) {
      this.productCategoryRestriction.products = [];
    }

    this.productCategoryRestriction.products.push(product);
    this.productCategoryRestriction.categories = [];
  }

  /**Remove o produto que está na lista de restrição */
  removeProduct(product) {
    if (isArrayEmpty(this.productCategoryRestriction.products)) {
      return;
    }

    this.productCategoryRestriction.products =
      this.productCategoryRestriction.products.filter(
        (f) => f.uuid !== product.uuid
      );
    this.productCategoryRestriction.categories = [];
    this.renderAttribute = !this.renderAttribute;
  }

  /**Lista os vouchers*/
  listVouchers = async (sort = 'created,desc', size = this.size) => {
    const params = {
      page: this.page,
      size: size ?? this.size,
      sort: sort ?? 'created,desc',
    };
    this.loading = true;
    const response = await voucherBatchAPI.listVouchers(params);
    this.loading = false;

    try {
      if (!response.error) {
        this.vouchers = await response.content;
        this.totalPages = response.totalPages;
        this.page = response.number;
      } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } catch (err) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, err.message);
    }
  };

  /**Recupera informações do voucher */
  getVoucherInfo = async (voucherId) => {
    this.loading = true;
    const response = await voucherBatchAPI.getVoucherInfo(voucherId);
    this.loading = false;

    try {
      if (!response.error) {
        this.selectedVoucher = response;
        return response;
      } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } catch (err) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, err.message);
    }
  };

  /**Trocar a pagina da tabela de listagem de vouchers. */
  setPage = async (numPage, size = 15, sort = 'created,desc') => {
    this.page = numPage;
    this.size = size;

    await this.listVouchers(sort, size);
  };

  /**Atualiza o cupom */
  updateVoucher = async (data, voucherId) => {
    const updatedVoucherList = [...this.vouchers];
    const updatedVoucher = updatedVoucherList.find(
      (voucher) => voucher.uuid === voucherId
    );
    const updatedVoucherIndex = updatedVoucherList.findIndex(
      (voucher) => voucher.uuid === voucherId
    );

    const key = Object.keys(data)[0];

    if (updatedVoucher && updatedVoucherIndex > -1) {
      Object.assign(updatedVoucher, data);
      let response;
      if (key === 'deleted') {
        updatedVoucherList.splice(updatedVoucherIndex, 1);
        this.loading = true;
        response = await voucherBatchAPI.deleteVoucher(voucherId);
        this.loading = false;
      } else {
        updatedVoucherList.splice(updatedVoucherIndex, 1, updatedVoucher);
        this.loading = true;
        response = await voucherBatchAPI.updateVoucher(
          voucherId,
          updatedVoucher
        );
        this.loading = false;
      }

      this.vouchers = updatedVoucherList;

      try {
        if (!response.error) {
          if (key !== 'deleted') {
            this.selectedVoucher = response;
          }
        } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      } catch (err) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, err.message);
      }
    }
  };

  setVoucherStatus = async (voucherId) => {
    this.loading = true;
    const response = await voucherBatchAPI.setVoucherStatus(voucherId);
    this.loading = false;
    return response;
  };
}

export default VoucherBatchStore;
