import React, { useEffect, useState } from 'react';
import { Form } from '@unform/web';
import { observer } from 'mobx-react';
import { useStores } from '~/hooks/useStores';

import CardPainelComponent from '~/components/CardPainelComponent/CardPainelComponent';
import ButtonComponent from '~/components/ButtonComponent/ButtonComponent';
import FormGroupComponent from '~/components/FormGroupComponent/FormGroupComponent';
import UnformInputComponent from '~/components/UnformComponent/UnformInputComponent';
import LabelComponent from '~/components/LabelComponent/LabelComponent';
import { InputNumbersComponent } from '~/components/InputComponent';
import CollumComponent from '~/components/CollumComponent/CollumComponent';
import ListDiscountsComponent from './components/ListDiscountsComponent';
import PaginationComponent from '~/components/PaginationComponent/PaginationComponent';
import LoadingComponent2 from '~/components/LoadingComponent2';

const ConfigStore = () => {
  const [interest, setInterest] = useState(0);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingAddInstallment, setLoadingAddInstallment] = useState(false);
  const { globalConfig, interestRateStore } = useStores();

  const { loading, config } = globalConfig;

  const { installments, page, totalPages } = interestRateStore;

  const handleForm = async (data) => {
    await globalConfig.updateGlobalConfig(data);
  };

  useEffect(() => {
    async function getInterestRate() {
      setLoadingPage(true);
      await interestRateStore.setInstallmentPage(0, 12);
      setLoadingPage(false);
    }

    getInterestRate();
  }, []);

  useEffect(() => {
    globalConfig.getGlobalConfigs();
  }, [globalConfig]);

  /**Remove uma parcela */
  const handleRemoveInstallment = (interestRate) => {
    interestRateStore.handleRemoveInstallment(interestRate);
    setInterest(0);
    // setRenderComponent(!renderComponent);
  };

  const handleAddInstallment = async () => {
    setLoadingAddInstallment(true);
    await interestRateStore.handleAddInstallment(interest);
    setLoadingAddInstallment(false);
    setInterest(0);
    // setRenderComponent(!renderComponent);
  };

  return (
    <div className="ConfigStoreContainer">
      <fieldset disabled={loading} className="w-100">
        <CardPainelComponent
          title="Configurações da loja"
          loading={loadingPage}
        >
          <div>
            <Form className="w-100" onSubmit={handleForm}>
              <FormGroupComponent row>
                <UnformInputComponent
                  id="daysToShowHighlightedProducts"
                  className="form-control"
                  defaultValue={config?.daysToShowHighlightedProducts}
                  label="Dias de exibição de produtos em novidades"
                  name="daysToShowHighlightedProducts"
                  required
                  type="number"
                  min="0"
                />
              </FormGroupComponent>
              <FormGroupComponent row>
                <UnformInputComponent
                  id="daysToShowProductsWithoutStock"
                  className="form-control"
                  required
                  defaultValue={config?.daysToShowProductsWithoutStock}
                  label="Dias de exibição de produtos sem estoque"
                  name="daysToShowProductsWithoutStock"
                  type="number"
                  min="0"
                />
              </FormGroupComponent>

              <FormGroupComponent row>
                <UnformInputComponent
                  id="minimumQuantityOfProductsDisplayedOnShowcase"
                  className="form-control"
                  required
                  defaultValue={config?.minimumQuantityOfProductsDisplayedOnShowcase}
                  label="Quantidade mínima de produtos exibidos na vitrine"
                  name="minimumQuantityOfProductsDisplayedOnShowcase"
                  type="number"
                  min="1"
                  max="4"
                />
              </FormGroupComponent>

              <div className="container-button-saved">
                <ButtonComponent loading={loading} type="submit">
                  SALVAR
                </ButtonComponent>
              </div>
            </Form>
          </div>
        </CardPainelComponent>
      </fieldset>
    </div>
  );
};
export default observer(ConfigStore);
