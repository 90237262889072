import React from 'react';

/** 
  * @Name: FormGroupComponent
  * @Data: 2019
  * @Desc: Div com classe bootstrap que agrupa diversos componentes 
  * @props:
  *      
  */

const FormGroupComponent = (props) => {
    // Recebe as propriedades
    const { className = "form-group", row = true, children, invalidText= "Campo Inválido", hidden } = props;
    const includesRow = className.includes('row') ? false : row;
    return (
        <div className={`${className} ${includesRow ? "row" : ""}`}  hidden={hidden} >
            {children}
            
            <div className="invalid-feedback">
                {invalidText}
            </div>
        </div>
    )
}

export default FormGroupComponent;