import BaseAPI, { URLS } from './BaseAPI';

class LookAPI {
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * Request que lista Looks
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async list(params = {}) {
    try {
      const response = await BaseAPI.get(URLS.LOOK, params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar looks' };
    } catch (e) {
      return this._exception(e, 'Falha ao listar looks');
    }
  }

  /**
   * Request que lista Looks pelo nome
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async listByName(params = {}) {
    try {
      const response = await BaseAPI.get(`${URLS.LOOK}`, params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar looks' };
    } catch (e) {
      return this._exception(e, 'Falha ao listar looks');
    }
  }

  /**
   * Request que cria novo look.
   * @param  {Object} look - Objeto do look que será criado
   */
  static async save(look) {
    try {
      const response = await BaseAPI.post(URLS.LOOK, look);
      if (response.status === 201) return response;
      return { error: 'Erro inesperado ao cadastrar o look' };
    } catch (e) {
      return this._exception(e, 'Falha ao cadastrar o look');
    }
  }

  /**
   * Atualizada campos de um look.
   * @param  {Object} params
   */
  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(URLS.LOOK, data);
      if (response.status === 200) return response;
      return { error: 'Erro inesperado ao atualizar o look' };
    } catch (e) {
      return this._exception(e, 'Erro ao atualizar o look');
    }
  }

  /**
   * Request que busca um look pelo uuid
   * @param {String} lookUuid uuid do look
   */
  static async findByUUID(lookUuid) {
    try {
      const response = await BaseAPI.get(`${URLS.LOOK}/${lookUuid}`);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao busca o look' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar o look');
    }
  }

  // Deletar produtos no look
  static async handleRemoveProductLook(uuidLook, uuidProduct) {
    try {
      const url = `${URLS.LOOK}/${uuidLook}/product/${uuidProduct}`;
      const response = await BaseAPI.delete(url);
      if (response.status === 200) return response.data;
      return { error: 'Erro ao remover produto' };
    } catch (e) {
      return this._exception(e, 'Erro ao remover produto no pedido.');
    }
  }

  /**
   * Request que lista o preço do Look de acordo com os produtos
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async calculateProductsPriceInLook(params = {}) {
    try {
      const response = await BaseAPI.get(`${URLS.LOOK}/price`, params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar preço do look' };
    } catch (e) {
      return this._exception(e, 'Falha ao listar preço do look');
    }
  }
}

export default LookAPI;
