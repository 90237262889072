import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ButtonComponent from '~/components/ButtonComponent/ButtonComponent';
import CardPainelComponent from '~/components/CardPainelComponent/CardPainelComponent';
import PaginationComponent from '~/components/PaginationComponent/PaginationComponent';
import StockAPI from '../../services/StockAPI';
import GridComponent from './components/GridComponent/GridComponent';
import SelectBranch from './components/SelectBranch';

export default function Stocks() {
  const [name, setName] = useState();
  const [skuCode, setSkuCode] = useState();
  const [barCode, setbarCode] = useState();
  const [status, setStatus] = useState();
  const [branch, setBranch] = useState(null);
  const [products, setProducts] = useState([]);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [productStocks, setProductsStocks] = useState([]);

  //formata os parametros para a API

  const formatParams = () => {
    const enumStatus = {
      active: true,
      inative: 'false',
    };

    const active = enumStatus[status];

    const barCodes_barCode = barCode;

    const filters = {
      name,
      skuCode,
      barCodes_barCode,
      active,
    };

    const stringFilter = Object.entries(filters).map(([key, value]) => {
      const isLike = key === 'active' ? value : `*${value}*`;
      return value && `${key}:${isLike}`;
    });

    return stringFilter.filter(Boolean).join(',');
  };

  /**Buscar pelos campos do form */
  const handleForm = async (event) => {
    event.preventDefault();

    const stringParams = formatParams();

    handleProducts(stringParams, 0, branch);
  };

  //Função para buscar de produtos e organiza a listagem
  const handleProducts = useCallback(
    async (stringParams, page, branchParam) => {
      stringParams = stringParams?.length ? stringParams : 'all';

      setLoading(true);

      const response = await StockAPI.getProducts(
        stringParams,
        page,
        branchParam
      );

      formatDataTable(response);

      setLoading(false);
    },
    []
  );

  const formatDataTable = useCallback((data) => {
    if (data) {
      const productsStockSorted = data.content.map((product) => ({
        ...product,
        stockList: product.stockList,
      }));

      setProducts(productsStockSorted);

      let branchList = [];

      productsStockSorted.map((stock) =>
        stock.stockList.map((branch) => branchList.push(branch.branch))
      );

      branchList = Array.from(new Set(branchList));

      setBranches(branchList);

      setTotalPages(data?.totalPages);
      setCurrentPage(data.number);
    } else {
      setProducts([]);
    }
  }, []);

  const resetForm = async () => {
    setName('');
    setSkuCode('');
    setbarCode('');
    setStatus('');
    setBranch(null);
    await handleProducts();
  };

  //buscar os produtos por paginação

  const handleByPagination = (page) => {
    const params = formatParams();
    handleProducts(params, page, branch);
  };

  useEffect(() => {
    handleProducts();
  }, [handleProducts]);

  //Exibi o valor de estoque  por filial
  const handleStockValue = () => {
    const valuesList = products.map((product) =>
      branches.map((branch) => {
        return product.stockList.find((stock) => stock.branch === branch);
      })
    );

    setProductsStocks(valuesList);
  };

  useEffect(() => {
    if (products.length && branches.length) {
      handleStockValue();
    }
  }, [products, branches]);

  const memoGrid = useMemo(
    () => (
      <GridComponent
        branches={branches}
        products={products}
        productStocks={productStocks}
      />
    ),
    [branches, productStocks]
  );

  return (
    <CardPainelComponent
      title="Gerenciamento de Estoque"
      subtitle="Listagem de estoque por filial"
      loading={loading}
    >
      <fieldset disabled={loading}>
        <form
          onSubmit={(event) => handleForm(event)}
          className="row form-group justify-content-between align-items-end "
        >
          <div className="col-2">
            <label htmlFor="name">Nome</label>
            <input
              type="text"
              name="name"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control"
              placeholder="Digite o nome do produto"
            />
          </div>
          <div className="col-2">
            <label htmlFor="skuCode">Código SKU</label>
            <input
              type="text"
              name="skuCode"
              id="skuCode"
              value={skuCode}
              onChange={(event) => setSkuCode(event.target.value)}
              className="form-control"
              placeholder="Digite o número do SKU"
            />
          </div>

          <div className="col-2">
            <SelectBranch setBranch={setBranch} selected={branch} />
          </div>
          <div className="col-2">
            <label htmlFor="barCode">Código de Barras</label>
            <input
              type="text"
              name="barCode"
              id="barCode"
              value={barCode}
              onChange={(event) => setbarCode(event.target.value)}
              className="form-control"
              placeholder="Digite o código de barras"
            />
          </div>
          <div className="col-1">
            <label htmlFor="active">Status</label>
            <select
              name="active"
              id="active"
              value={status}
              className="form-control"
              onChange={(event) => setStatus(event.target.value)}
            >
              <option value="">Todos</option>
              <option value="active">Ativo</option>
              <option value="inative">Inativo</option>
            </select>
          </div>
          <div className="col-3 row">
            <div className="col-6">
              <ButtonComponent type="submit">
                <i className="fas fa-search mr-2" />
                Buscar
              </ButtonComponent>
            </div>
            <div className="col-6">
              <ButtonComponent type="button" click={() => resetForm()}>
                <i className="fas fa-trash mr-2" />
                Limpar
              </ButtonComponent>
            </div>
          </div>
        </form>
      </fieldset>

      <div className="text-center">
        {!loading && products.length === 0 && (
          <div className="h4 mt-5"> Nenhum produto encontrado</div>
        )}
      </div>

      {!loading && products.length > 0 && (
        <>
          {memoGrid}

          <PaginationComponent
            pages={totalPages}
            initialPage={currentPage}
            onChange={(selectedItem) =>
              handleByPagination(selectedItem.selected)
            }
          />
        </>
      )}
    </CardPainelComponent>
  );
}
