import RestrictionsModel from '../Restrictions';

export class ProductCategoryRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.kernel.model.persistence.restriction.ProductCategoryRestriction';
    this.categories = data.categories;
    this.products = data.products;
    this.excludes = data.excludes;
  }
}

export class DateRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.kernel.model.persistence.restriction.DateRestriction';
    this.minDate = new Date(data.minDate);
    this.maxDate = new Date(data.maxDate);
    this.excludes = data.excludes ? data.excludes : false;
  }
}

export class CustomerRestriction extends RestrictionsModel {
  constructor(data = { emails: [], documents: [] }) {
    super(data);
    this['@class'] =
      'br.com.stoom.kernel.model.persistence.restriction.CustomerRestriction';
    this.emails = data.emails;
    this.documents = data.documents;
    this.excludes = data.excludes ? data.excludes : false;
  }
}

export class FirstPurchaseRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.kernel.model.persistence.restriction.FirstPurchaseRestriction';
    this.excludes = data.excludes ? data.excludes : false;
  }
}

export class ZipCodeRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.avenida.model.persistence.restriction.AvenidaZipCodeRestriction';
    this.initialZipCode = data.initialZipCode ? data.initialZipCode : null;
    this.finalZipCode = data.finalZipCode ? data.finalZipCode : null;
    this.excludes = data.excludes ? data.excludes : false;
  }
}

export class PriceRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.kernel.model.persistence.restriction.PriceRestriction';
    this.minPrice = data.minPrice ? data.minPrice : 0;
    this.maxPrice = data.maxPrice ? data.maxPrice : 9999999999;
    this.excludes = data.excludes ? data.excludes : false;
  }
}

export class AvenidaDeliveryRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.avenida.model.persistence.restriction.AvenidaDeliveryRestriction';
    this.excludes = data.excludes ? data.excludes : false;
    this.optionTypes = data.optionTypes;
  }
}

export class GroupRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.avenida.model.persistence.restriction.AvenidaGroupRestriction';
    this.excludes = data.excludes ? data.excludes : false;

    this.customerGroups = [
      {
        '@class': 'br.com.stoom.avenida.model.persistence.AvenidaCustomerGroup',
        uuid: data.value,
      },
    ];
  }
}
