import { extendObservable, decorate, action } from 'mobx';

import AvenidaCategoryModel from '~/models/avenida/CategoryModel';
import CategoryAPI from '../services/CategoryAPI';
import BannerAPI from '../services/BannerAPI';
import { categoryTypes } from '~/helpers/utils/Selects';
import ProductModel from '~/models/ProductModel';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import UploadAPI from '../services/UploadAPI';
import FileModel from '~/models/FileModel';
import { CategoriesEnum } from '~/helpers/utils/Enums';

/**Valores inicias de variaveis observadas */
const initValues = {
  category: undefined,
  bannerSeo: undefined,
  categories: [],
  flatedCategories: [],
  loading: false,
  parentCategories: [],
  parentSelected: [],
  collectionCategories: [],
  showCaseCategories: [],
  catedoryDropdownOptions: []
};
class CategoryStore {
  // totalPages = 0;
  //page = 0;
  //size = 20;
  /**Constructor */
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, initValues);
    this.toastHelper = new ToastHelper();
  }

  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Reset values */
  reset() {
    this.category = undefined;
    this.categories = [];
    this.bannerSeo = undefined;
  }

  /**Atualiza propriedades da categoria */
  updateProp(prop, value) {
    switch (prop) {
      /*  case 'parentCategory':
                  const parent = value ? new CategoryModel({uuid: value.value, categoryName: value.label}) : undefined;
                  this.category.parentCategory = parent;
                  this.category = new CategoryModel(this.category);
                  break;*/
      /*  case 'childrenCategory':
                  const categories = value.map(v => new CategoryModel({uuid: v.value, categoryName:v.label }));
                  category.childrenCategory = categories;
                  this.category = new CategoryModel(category);
                  break;*/
      case 'products':
        const products = value.map(
          (v) => new ProductModel({ uuid: v.value, name: v.label })
        );
        this.category.products = products;
        this.category = new AvenidaCategoryModel(this.category);
        break;
      default:
        this.category[prop] = value;
    }
  }

  /**Cria nova categoria com dados padrão */
  emptyCategory() {
    const type = categoryTypes[0].value;
    const merchant = this.rootStore.usersStore.userMerchant;
    this.category = new AvenidaCategoryModel({ type, merchant });
  }

  /**Salva nova categoria. */
  async save() {
    this.loading = true;
    const list = [].concat(this.category.files);
    this.category.files = [];
    let data = new AvenidaCategoryModel(this.category);
    if(this.bannerSeo)
      data.bannerSeo = {
        "tag": "BANNER_SEO",
        "nameTag": "BANNER SEO",
      };
    data = JSON.stringify(data);
    const response = await CategoryAPI.save(data);
    
    if (response.error) {
      if (
        (response.error.includes('Order of the existing category!') && response.status === 500)
        ||
        (response.error.includes('Order already exist') && response.status === 409)
      ) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Ordenação já existente');
      } else {
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      }
      this.loading = false;
    } else {
      this.emptyCategory();
      this.sendNewFiles(list, response.uuid);
      if(response.bannerSeo)
        await this.sendSeoFiles(response.bannerSeo.uuid);
      this.loading = false;
     
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Categoria incluida com sucesso'
      );
    }
    return response;
  }

  /**Atualiza categoria categoria. */
  async update() {
    this.loading = true;
    this.category.class = 'br.com.stoom.avenida.model.dto.AvenidaCategoryDTO';

    let category = new AvenidaCategoryModel(this.category);
    if(!category.bannerSeo){
      category.bannerSeo = {
        "tag": "BANNER_SEO",
        "nameTag": "BANNER SEO",
      };
    }

    const data = JSON.stringify(category);
    const response = await CategoryAPI.update(this.category.uuid, data);
    if (response.error) {
      if (
        response.error.includes('Order of the existing category!') &&
        response.status === 500
      ) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Ordenação já existente');
      } else {
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      }
      this.loading = false;
    } else {
      
      this.handleFiles(this.category.files);

      if(this.bannerSeo == undefined){
        if(response.bannerSeo && response.bannerSeo.files.length > 0)  {
          await BannerAPI.deleteImageBanner(response.bannerSeo.uuid, response.bannerSeo.files[0].uuid)
        }
      }else {
        if(response.bannerSeo)  {
          if(response.bannerSeo.files && response.bannerSeo.files.length > 0){
            await this.updateSeoFiles(response.bannerSeo.files[0].uuid);
          }else {
            await this.sendSeoFiles(response.bannerSeo.uuid);
          }
        }
      }
      
      this.loading = false;
      this.category = new AvenidaCategoryModel(response);
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Dados atualizados');
    }
    return response;
  }

  /**Busca categorias em arvores*/
  async getTree() {
    this.loading = true;
    const response = await CategoryAPI.getTree();
    this.loading = false;
    if (!response.error) {
      this.categories = response.map((cat) => new AvenidaCategoryModel(cat));

      this.collectionCategories = this.categories.filter(
        (cat) => cat.type === CategoriesEnum.COLECAO
      );

      this.showCaseCategories = this.categories.filter(
        (cat) => cat.type === CategoriesEnum.VITRINE
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return [];
  }

  /**Busca categoria por uuid */
  async get(uuid) {
    this.loading = true;
    const response = await CategoryAPI.get(uuid);
    if (!response.error) {
      this.category = new AvenidaCategoryModel(response);
      if(this.category.bannerSeo &&  this.category.bannerSeo.files.length)
        this.bannerSeo = {...this.category.bannerSeo.files[0], file:[this.category.bannerSeo.files[0].file]};
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);

    this.loading = false;
  }

  /**Deleta categoria */
  async delete(uuid) {
    this.loading = true;
    const response = await CategoryAPI.delete(uuid);
    if (!response.error) this.toastHelper.notify('info', 'Categoria removida');
    else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Envia arquivo de proposta que foi anexo */
  async handleFiles(files) {
    this.loading = true;

    const newFiles = files.filter((file) => !file.uuid);
    const updateds = files.filter((file) => file.uuid && file.file);
    const deleteds = files.filter((file) => file.uuid && !file.path);

    newFiles.length > 0 &&
      (await this.sendNewFiles(newFiles, this.category.uuid));
    updateds.length > 0 && (await this.updateFiles(updateds));
    deleteds.length > 0 && (await this.deleteFiles(deleteds));

    this.loading = false;
    return true;
  }

  /**Funcão útil paradá feedbeack sobre envio de arquivos. */
  async filesService(promise, message) {
    const response = await Promise.all(promise);
    const errors = response.filter((r) => r && r.error !== undefined);
    if (errors && errors.length > 0) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, message);
    }
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    return response;
  }

  /**Atualiza imagem da categoria */
  async deleteFiles(files) {
    const promise = files.map(async (file) => {
      await UploadAPI.removeFile(file.uuid);
    });
    return await this.filesService(promise, 'Falha o deletar imagens');
  }

  /**Atualiza imagem da categoria */
  async updateFiles(files) {
    const promise = files.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.updateFile(file);
    });
    await this.filesService(promise, 'Falha ao atualizar imagens');
    setTimeout(() => {
      files.forEach((file) => this.notificationStore.removeItemUpload(file));
    }, 3300);
    return true;
  }

  /**Envia imagem da categoria. */
  async sendNewFiles(files, categoryUuid) {
    const promise = files.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.uploadCategory(categoryUuid, file);
    });
    await this.filesService(promise, 'ao incluir imagens');
    setTimeout(() => {
      files.forEach((file) => this.notificationStore.removeItemUpload(file));
    }, 3300);
    return true;
  }

  handleFileCategory(dropedFiles) {
    const { category } = this;
    this._handleImages(dropedFiles, 'category', category.fileCategory);
  }

  handleIconCategory(dropedFiles) {
    const { category } = this;
    this._handleImages(dropedFiles, 'icon-category', category.iconCategory);
  }

  async sendSeoFiles(bannerUuid) {
    if(this.bannerSeo.file.length > 0) {
      const bannerData = {
        "name": this.bannerSeo.name,
        "ordering": "1",
        "links": this.bannerSeo.links,
        "description": this.bannerSeo.description,
        "subDescription": ""
      };
      
      await UploadAPI.uploadBannerSEOCategory(bannerUuid, bannerData, this.bannerSeo.file);
    }
  }

  async updateSeoFiles(bannerFileUuid) {
    
    if(this.bannerSeo.file.length > 0) {
      const bannerData = {
        "name": this.bannerSeo.name,
        "ordering": "1",
        "links": this.bannerSeo.links,
        "description": this.bannerSeo.description,
        "subDescription": "",
        "fileUuid": this.bannerSeo.file[0].uuid
      };

      await UploadAPI.updateUploadBannerSEOCategory(bannerFileUuid, bannerData, this.bannerSeo.file);
    }
  }

  handleBannerSeo(bannerSeo) {
    this.bannerSeo = bannerSeo;
  }

  /**Adiciona imagem ao produto */
  _handleImages(dropedFiles, tag, fileItem) {
    /**Altera arquivo. */
    const dropedFile = dropedFiles.length > 0 ? dropedFiles[0] : undefined;
    if (!fileItem && dropedFile) {
      const newFileItem = new FileModel({
        file: dropedFile,
        ...dropedFile,
        metaTags: [tag],
      });
      this.category.files.push(newFileItem);
    } else {
      fileItem.updateFile(dropedFile); //FileItem existe, Atualiza valores do arquivo recebido
    }
  }

  /**Retorna lista de categorias para uso no select */
  getListSelect(excludes = {}) {
    if (!excludes) return;

    const localFilter = (category) => {
      const hasChildren = category.childrenCategory.length > 0;
      if (hasChildren) {
        category.childrenCategory =
          category.childrenCategory.filter(localFilter);
      }
      return !excludes.some((cat) => cat.uuid === category.uuid);
    };

    if (Array.isArray(excludes)) {
      return this.categories.filter(localFilter);
    }

    return this.categories.filter((cat) => cat.type === this.category?.type);
    //        return list.map(mCat => ({ value: mCat.uuid, label: mCat.categoryName }));
  }

  flattenDeep(array) {
    return array.reduce((acc, val) => {
      acc.push(val);

      return (
        Array.isArray(val.childrenCategory) &&
        acc.concat(this.flattenDeep(val.childrenCategory))
      );
    }, []);
  }

  flatCategories() {
    const flattedList = this.flattenDeep(this.categories);

    this.flatedCategories = flattedList;
  }

  async getCategories(
    sort = 'created,desc',
    size = this.size,
    setCategoryListToDropdown = false
) {
    this.loading = true;
    const params = { page: this.page, size: size ?? this.size, sort: sort ?? 'created,desc' };
    const response = await CategoryAPI.getList(params,this.paramsToString !== '' && this.paramsToString);
    this.loading = false;

    try {
        if (!response.error) {
            this.categories = await response.content.map(category => new AvenidaCategoryModel(category));
            this.totalPages = response.totalPages;
            this.page = response.number;
        } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } catch (err) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, err.message);
    }

    if(setCategoryListToDropdown){
      this.catedoryDropdownOptions = response.content.map(cat => ({ value: cat.slug, label: cat.slug }));
    }

    return response;
  }
}

decorate(CategoryStore, {
  get: action,
  getTree: action,
  save: action,
  update: action,
  delete: action,
  handleFiles: action,
  handleBannerSeo: action,
  getListSelect: action,
});

export default CategoryStore;
