import React, { useState, useEffect } from 'react';
import { maskCEP } from '~/helpers/utils/Masks';
import { yupValidator } from '~/helpers/validators/yupValidator';
import { documentSchema } from '~/helpers/yupSchemas/documentSchema';
import InputComponent from '../InputComponent';
/**
 * CEP Component
 *  */
export default function InputCEPComponent(props) {
  const [error, setError] = useState();

  const validate = async (value) => {
    await yupValidator(setError, documentSchema, {
      postcode: value,
    });
  };

  const { name = 'cep', id = 'cep', mask = maskCEP, required } = props;
  return (
    <>
      <InputComponent
        {...props}
        mask={mask}
        name={name}
        id={id}
        onKeyUp={(e) => validate(e.target.value)}
        type="text"
        required={required}
      />

      {error}
    </>
  );
}
