import RestrictionsModel from '../Restrictions';

export class ProductCategoryRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.kernel.model.persistence.restriction.ProductCategoryRestriction';
    this.categories = data.categories;
    this.products = data.products;
    this.excludes = data.excludes;
  }
}

export class DateRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.kernel.model.persistence.restriction.DateRestriction';
    this.minDate = data.minDate ? new Date(data.minDate) : undefined;
    this.maxDate = data.maxDate ? new Date(data.maxDate) : undefined;
  }
}

export class CustomerRestriction extends RestrictionsModel {
  constructor(data = { emails: [], documents: [] }) {
    super(data);
    this['@class'] =
      'br.com.stoom.kernel.model.persistence.restriction.CustomerRestriction';
    this.emails = data.emails;
    this.documents = data.documents;
    this.excludes = data.excludes;
  }
}

export class FirstPurchaseRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.kernel.model.persistence.restriction.FirstPurchaseRestriction';
  }
}

export class PriceRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.kernel.model.persistence.restriction.PriceRestriction';
    this.minPrice = data.minPrice;
    this.maxPrice = data.maxPrice;
  }
}

export class BuyXProductRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.kernel.model.persistence.restriction.BuyXProductRestriction';
    this.amount = data.amount;
  }
}

export class CustomerGroupRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.avenida.model.persistence.restriction.AvenidaGroupRestriction';
    this.customerGroups = data.customerGroups || [];
    this.excludes = data.excludes;
  }
}

export class ZipCodeRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.avenida.model.persistence.restriction.AvenidaZipCodeRestriction';
    this.initialZipCode = data.initialZipCode;
    this.finalZipCode = data.finalZipCode;
    this.excludes = data.excludes;
  }
}

export class AvenidaPointOfSaleRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.avenida.model.persistence.restriction.AvenidaPointOfSaleRestriction';
    this.excludes = data.excludes;
    this.pointsOfSale = data.pointsOfSale;
  }
}

export class AvenidaDeliveryRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.avenida.model.persistence.restriction.AvenidaDeliveryRestriction';
    this.excludes = data.excludes ? data.excludes : false;
    this.optionTypes = data.optionTypes;
  }
}