import BaseModel from './BaseModel';
import FileModel from './FileModel';
import ProductLookModel from './ProductLookModel';

class LookModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.name = data.name;
    this.price = data.price;
    this.status = data.status;
    this.description = data.description;
    this.files = data.files
      ? data.files.reduce((list, file) => {
          if (!list.some((lFile) => lFile.metaTags[0] === file.metaTags[0])) {
            list.push(new FileModel(file));
          }
          return list;
        }, [])
      : [];
    this.products = data.products
      ? data.products.map((prod) => new ProductLookModel(prod))
      : [];
    this.itemsDiscount = data.itemsDiscount
      ? data.itemsDiscount.map((disc) => new ProductLookModel(disc))
      : [];
    this.active = data.active;
  }

  getFile(tag) {
    return this.files.find((file) => file.path && file.metatag === tag);
  }

  getFiles(tag) {
    return this.files.filter(
      (file) => file.path && file.metaTags.some((metaTag) => metaTag === tag)
    );
  }
}

export default LookModel;
