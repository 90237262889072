import MerchantModel from '../MerchantModel';

class AvenidaMerchantModel extends MerchantModel {
  constructor(data = {}) {
    super(data);
    // this['@class'] = 'br.com.stoom.lumini.model.LuminiMerchant';
    this.buildTimeDays = data.buildTimeDays;
    this.percentage = data.percentage;
    this.percentageValue = data.percentageValue;
    this.zipCode = data.zipCode;
    this.street = data.street;
    this.block = data.block;
    this.number = data.number;
    this.city = data.city;
    this.state = data.state;
    this.addressComplement = data.addressComplement;
    this.country = data.country;
    this.externalId = data.externalId;
  }
}

export default AvenidaMerchantModel;
