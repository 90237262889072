import React, { forwardRef, useEffect, useState } from 'react';

const CheckboxComponent = forwardRef((props, ref) => {
  //Props
  const {
    defaultChecked,
    name = 'checkbox',
    id = 'checkbox',
    large,
    medium,
    small,
    dataOff,
    dataOn,
    onChange,
    checked,
    disabled = false,
    className,
  } = props;

  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  // const handleChecked = (e) => {
  //   let value;
  //   if (checked === undefined) {
  //     value = !isChecked;
  //     setChecked(value);
  //   } else {
  //     value = !checked;
  //   }

  //   onChange(value);
  // };

  const handleChecked = e => {
		const value = !isChecked;
		setChecked(value);
		onChange(value);
	}

  return (
    <div
      className={`${className} checkbox d-flex ${medium ? 'switches_m' : ''} ${
        small ? 'switches_s' : ''
      } ${large ? 'switches_l' : ''} `}
    >
      <input
        type="checkbox"
        disabled={disabled}
        name={name}
        id={id}
        ref={ref}
        className="ios-toggle checkbox2"
        checked={checked !== undefined ? checked && isChecked : isChecked}
        onChange={handleChecked}
      />
      <label
        htmlFor={id}
        className="checkbox-label"
        data-off={dataOff}
        data-on={dataOn}
        disabled={disabled}
      />
    </div>
  );
});

export default CheckboxComponent;
