import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { inject, observer } from 'mobx-react';
import { ToastPosition } from 'react-toastify';

import TextareaComponent from '~/components/TextareaComponent/TextareaComponent';
import DropzoneComponent from '~/components/DropzoneComponent/DropzoneComponent';
import FormGroupComponent from '~/components/FormGroupComponent/FormGroupComponent';
import LabelComponent from '~/components/LabelComponent/LabelComponent';
import CollumComponent from '~/components/CollumComponent/CollumComponent';
import InputComponent from '~/components/InputComponent/InputComponent';
import FormComponent from '~/components/FormComponent/FormComponent';
import ButtonComponent from '~/components/ButtonComponent/ButtonComponent';
import CheckboxComponent from '~/components/CheckboxComponent/CheckboxComponent';
import AsyncSelectComponent from '~/components/AsyncSelectComponent';
import LoadingComponent2 from '~/components/LoadingComponent2';
import ListProductsComponent from './components/ListProductsComponent';
import ListDiscountComponent from './components/ListDiscountsComponent';
import ListProductsAlternative from './components/ListProductsAlternativeComponent';
import CardPainelComponent from '~/components/CardPainelComponent/CardPainelComponent';
import { InputNumbersComponent } from '~/components/InputComponent';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';

class LookUpdateContainer extends React.Component {
  uuidLook = '';

  constructor(props) {
    super(props);
    this.lookStore = props.lookStore;
    this.productStore = props.productStore;
    this.toastHelper = new ToastHelper();
    this.unlisten = this.props.history.listen((location) => {
      if (location.pathname === '/cadastrar-look') {
        this.lookStore.reset();
        this.setlook();
      }
    });
    this.state = {
      isVisibleProductSelected: false,
      ordering: '',
      percentage: '',
      deleteOrdering: '',
      toogleActive: false,
      productSelected: null,
      productSelectedDetails: null,
      productAlternativeSelected: null,
      loadingProductInScreen: false,
      updateProductLook: false, // Indica se na tela de adicionar produto está sendo criado ou atualizado
    };
  }

  componentDidMount() {
    this.lookStore.reset();
    this.setlook();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  resetProductLook() {
    this.setState({
      productSelected: null,
      productSelectedDetails: null,
      productAlternativeSelected: null,
    });
    this.lookStore.resetProductLook();
  }

  /**Verifica se esta recebendo uma nova prop do look */
  async setlook() {
    const nextUuid = this.props.match.params.uuidLook;
    if (this.uuidLook !== nextUuid) {
      this.uuidLook = nextUuid;
      if (nextUuid) {
        //Busca produto e ativa os toggles conforme as informações existirem.
        await this.lookStore.getLook(this.uuidLook);
        const look = this.lookStore.look;
        if (!look) return;
        this.setState({ toogleActive: look.status });
      } else {
        // this.isEditProduct = false;
        // Aqui é a condição quando não é update
      }
    }
  }

  /**Retorna os componentes originais */
  handleNotVisibleProductSelected() {
    this.setState({ isVisibleProductSelected: false });
  }

  /**Vincula imagem ao look. */
  handleUploadImage = (tag, files) => {
    const file = files.length > 0 ? files[0] : undefined;
    this.lookStore.handleLookImages(tag, file);
  };

  /**Adiciona uma ordenação de desconto */
  handleAddDiscount = () => {
    if (this.lookStore.look.itemsDiscount.length > 0) {
      let isError = false;

      this.lookStore.look.itemsDiscount.map((d) => {
        if (d.ordering == this.state.ordering) {
          isError = true;
        }
      });

      if (isError) {
        const message = `A ordenação de ${this.state.ordering} produto(s) já está cadastrada.`;

        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          message,
          5000,
          ToastPosition.BOTTOM_RIGHT,
          false
        );

        return;
      }
    }

    this.props.lookStore.handleAddDiscount(
      this.state.ordering,
      this.state.percentage
    );
    this.setState({ ordering: '', percentage: '' });
  };

  /**Remove uma ordenação do desconto */
  handleRemoveOrdering = (ordering) => {
    this.props.lookStore.handleRemoveOrdering(ordering);
    this.setState({ ordering: '', percentage: '' });
  };

  // Ação quando o usuário está digitando no async select do look
  handleSearchProduct = async (query) => {
    // Verifica se a busca tem 3 caracteres ou mais
    if (query && query.length >= 3) {
      let data = await this.lookStore.searchProductActiveByNameSKU(query);
      return data;
    } else {
      this.lookStore.resetProductOption();
      return null;
    }
  };

  // Ação quando o usuário está digitando no async select do look
  handleSearchProductAlternative = async (query) => {
    // Verifica se a busca tem 3 caracteres ou mais
    if (query && query.length >= 3) {
      let data = await this.lookStore.searchProductActiveByNameSKU(query);

      if (data.length > 0) {
        data = data.filter((e) => e.value !== this.state.productSelected.value);
        data = data.filter(
          (e) =>
            !this.lookStore.productsAlternative
              .map((pr) => pr.productUuid)
              .includes(e.value)
        );
      }

      return data;
    } else {
      this.lookStore.resetProductOption();
      return null;
    }
  };

  // Ação quando o usuário seleciona uma opção do async select do look
  handleSelectProduct = async (e) => {
    this.setState({ loadingProductInScreen: true });
    const product = await this.props.lookStore.get(e.value);

    if (!product.active) {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'O produto selecionado está inativo',
        5000,
        ToastPosition.BOTTOM_RIGHT,
        false
      );
      this.setState({ loadingProductInScreen: false });
      return;
    }

    this.setState({
      productSelected: e,
      productSelectedDetails: product,
      loadingProductInScreen: false,
      productAlternativeSelected: null,
    });
  };

  // Ação quando o usuário escolhe o produto alternativo no async select
  handleSelectProductAlternative = async (e) => {
    this.setState({ productAlternativeSelected: e });
  };

  // Ação para adicionar o produto do async select no grid
  handleAddProductAlternative = () => {
    if (
      this.state.productAlternativeSelected.value ===
      this.state.productSelected.value
    ) {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'O produto alternativo não pode ser igual ao produto principal',
        5000,
        ToastPosition.BOTTOM_RIGHT,
        false
      );

      return;
    }

    this.lookStore.handleAddProductAlternative(
      this.state.productAlternativeSelected.value,
      this.state.productAlternativeSelected.label
    );

    this.setState({ productAlternativeSelected: null });
  };

  // Remove o produto alternativo do array do produto que está ativo
  handleRemoveProductAlternative = (productAlternative) => {
    const uuidLook = this.props.lookStore?.look?.uuid;
    let productUuid = '';
    const productsLook = this.lookStore.look.products;
    productsLook.map((pdt) => {
      if (pdt.productUuid === this.state.productSelected.value) {
        productUuid = pdt.uuid;
      }
    });
    this.props.lookStore.handleRemoveProductAlternative(
      productAlternative,
      uuidLook,
      productUuid
    );
  };

  // Remove o produto que está no array de produtos do look
  handleRemoveProductLook = (product) => {
    const uuidLook = this.props.lookStore?.look?.uuid;
    this.props.lookStore.handleRemoveProductLook(product, uuidLook);
    this.resetProductLook();

    if (this.lookStore.look.products.length === 0) {
      this.handleActiveLook(false);
    }
  };

  // Ação de adicionar o produto no look
  handleSaveProductInLook = () => {
    if (this.state.updateProductLook) {
      this.lookStore.onUpdateProductInLook(this.state.productSelected);
    } else {
      this.lookStore.onSaveProductInLook(this.state.productSelected);
    }
    this.setState({ isVisibleProductSelected: false });
    this.lookStore.handleChangeComponentProduct();
    this.resetProductLook();
  };

  // Função que carrega as informações do produto ao ser clicado no grid
  handleClickLoadingProductLook = (product) => {
    const productSelected = {
      label: product.productName,
      value: product.productUuid,
    };
    this.handleSelectProduct(productSelected);
    this.lookStore.handleChangeComponentProduct();
    this.lookStore.setCurrentProductsAlternative(product.productUuid);
    this.setState({ updateProductLook: true });
  };

  /**Envio de formulário */
  onSave = async (e) => {
    e.preventDefault();

    let promise;

    const { look } = this.lookStore;
    let message = '';
    let isValid = true;

    if (!look.name) {
      message += 'Nome obrigatório; ';
      isValid = false;
    }

    if (!look.description) {
      message += 'Descrição obrigatória; ';
      isValid = false;
    }

    if (look.files.length === 0 || !look.files[0].path) {
      message += 'Imagem obrigatória; ';
      isValid = false;
    }

    if (!isValid) {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        message,
        5000,
        ToastPosition.BOTTOM_RIGHT,
        false
      );

      return;
    }

    if (this.uuidLook) {
      promise = this.lookStore.update();
    } else {
      promise = this.lookStore.save();
    }

    const response = await promise;

    if (!response.error) this.props.history.push('/looks');
  };

  /**Vincula imagem ao produto. */
  handleUploadImage = (tag, files) => {
    const file = files.length > 0 ? files[0] : undefined;
    if (file === undefined) {
      this.handleActiveLook(false);
    }
    this.lookStore.handleLookImages(tag, file);
  };

  handleActiveLook = (e) => {
    if (e) {
      const { look } = this.lookStore;
      let message = '';
      let isValid = true;

      if (!look.name) {
        message += 'Nome obrigatório; ';
        isValid = false;
      }

      if (!look.description) {
        message += 'Descrição obrigatória; ';
        isValid = false;
      }

      if (look.files.length === 0 || !look.files[0].path) {
        message += 'Imagem obrigatória; ';
        isValid = false;
      }

      if (look.products.length === 0) {
        message += 'Adicione pelo menos um produto no look; ';
        isValid = false;
      }

      if (!isValid) {
        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          message,
          5000,
          ToastPosition.BOTTOM_RIGHT,
          false
        );

        return;
      }
      this.setState({ toogleActive: e });
      this.lookStore.handleLookChange('status', e);
    } else {
      this.setState({ toogleActive: e });
      this.lookStore.handleLookChange('status', e);
    }
  };

  render() {
    const {
      look,
      isVisibleProductSelected,
      optionsProducts,
      loadingSearchProduct,
      productsAlternative,
      loading,
    } = this.lookStore;

    return !look || loading ? (
      <CardPainelComponent
        title={`Look`}
        large
        subtitle="Carregando informações..."
      >
        <LoadingComponent2></LoadingComponent2>
      </CardPainelComponent>
    ) : (
      <div className="UpdateLookContainer">
        {!isVisibleProductSelected && (
          <FormComponent
            className="form-horizontal"
            onSubmit={(e) => this.onSave(e)}
            ref={(ref) => (this.formRef = ref)}
          >
            <div className="row box-look">
              <div className="col-lg-7 title-look-container">
                <h3>LOOK</h3>
                <span>
                  {this.uuidLook ? 'Atualização de look' : 'Cadastro de look'}
                </span>
              </div>
              <div className="flex-img">
                <div className="info-conteiner">
                  <FormGroupComponent className="form-group row">
                    {/* NOME */}
                    <LabelComponent name="Nome" required htmlFor="name" />
                    <CollumComponent size="lg" cols="12">
                      <InputComponent
                        onBlur={(e) => {
                          if (!e.target.value) {
                            this.handleActiveLook(false);
                          }
                          this.lookStore.handleLookChange(
                            e.target.name,
                            e.target.value
                          );
                        }}
                        type="text"
                        name="name"
                        className="form-control input-largest"
                        placeholder="Nome do look"
                        defaultValue={look.name}
                        id="name"
                        maxlength="255"
                        onInvalid={(e) =>
                          e.target.setCustomValidity('Digite o nome do look')
                        }
                      />
                    </CollumComponent>
                  </FormGroupComponent>
                  <FormGroupComponent className="form-group row">
                    {/* DESCRIÇÃO */}
                    <LabelComponent
                      name="Descrição"
                      required
                      htmlFor="description"
                    />
                    <CollumComponent size="lg" cols="12">
                      <TextareaComponent
                        onChange={(e) =>
                          this.lookStore.handleLookChange('description', e)
                        }
                        className="form-control textarea-look"
                        name="description"
                        id="description"
                        rows="12"
                        maxlength="500"
                        defaultValue={look.description}
                        placeholder="Digite uma descrição para o look"
                      />
                    </CollumComponent>
                  </FormGroupComponent>
                  <FormGroupComponent className="form-group row">
                    {/* LINK DA IMAGEM */}
                    <LabelComponent
                      name="Link da Imagem"
                      required
                      htmlFor="pathImage"
                      className="col-5"
                    />
                    <CollumComponent size="lg" cols="12">
                      <InputComponent
                        onBlur={(e) => {
                          this.lookStore.handleChangeImageLook(e.target.value);
                        }}
                        type="text"
                        name="pathImage"
                        className="form-control input-largest"
                        placeholder="Link da Imagem do Look"
                        defaultValue={
                          look.files && look.files.length > 0
                            ? look.files[0].path
                            : ''
                        }
                        id="pathImage"
                      />
                    </CollumComponent>
                  </FormGroupComponent>
                  <FormGroupComponent>
                    <LabelComponent name="Preço" htmlFor="product_price" />
                    <CollumComponent size="lg" cols="12">
                      <InputComponent
                        type="text"
                        name="product_price"
                        className="form-control input-smaller w-50"
                        value={look.price ? `R$ ${look.price}` : ''}
                        id="product_price"
                        placeholder="Preço"
                        disabled
                      />
                    </CollumComponent>
                  </FormGroupComponent>
                  <div className="divider" />
                </div>
                <div>
                  <div className="block-look-images box-image-look">
                    <small>Imagem do Look</small>
                    <span className="image-first">
                      {look &&
                        look.files.length > 0 &&
                        look.files[0]?.path !== '' && (
                          <img
                            src={
                              look && look.files.length > 0
                                ? look.files[0].path
                                : null
                            }
                          />
                        )}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/**BLOCO ESCOLHA DE PRODUTO */}
            <div className="row box-table-look">
              <div className="col-lg-7 title-look-container">
                <h3>Lista de produtos selecionados</h3>
              </div>
              <div className="button-container-products">
                <ButtonComponent
                  type="button"
                  click={() => {
                    this.setState({ isVisibleProductSelected: false });
                    this.lookStore.handleChangeComponentProduct();
                  }}
                >
                  Adicionar
                </ButtonComponent>
              </div>
              <div className="table-container-look">
                <ListProductsComponent
                  date={look.products}
                  onDelete={this.handleRemoveProductLook}
                  handleClickLoadingProductLook={
                    this.handleClickLoadingProductLook
                  }
                />
              </div>
            </div>

            {/* *BLOCO DESCONTO
            <div className="box-look">
              <div className="row">
                <div className="col-lg-12 title-look-container">
                  <h3>Aplicar desconto</h3>
                </div>
                <div className="col-lg-4">
                  <FormGroupComponent>
                    <LabelComponent name="Ordenação" htmlFor="ordering" />
                    <CollumComponent size="lg" cols="12">
                      <InputNumbersComponent
                        onChange={(e) => {
                          let value = e.target.value;
                          if (value) {
                            value = parseInt(value);
                          }
                          this.setState({ ordering: value });
                        }}
                        id="ordering"
                        name="ordering"
                        type="number"
                        value={this.state.ordering}
                        placeholder="Digite a ordenação"
                        className="form-control input-largest"
                        step="1"
                      />
                    </CollumComponent>
                  </FormGroupComponent>
                </div>
                <div className="col-lg-5">
                  <FormGroupComponent className="form-group row dropdown">
                    <LabelComponent
                      name="Porcentagem de desconto"
                      htmlFor="percentage"
                      size={12}
                    />
                    <CollumComponent size="lg" cols="12">
                      <InputNumbersComponent
                        onChange={(e) => {
                          let value = e.target.value;
                          if (value) {
                            value = parseFloat(value);
                          }
                          this.setState({ percentage: value });
                        }}
                        id="percentage"
                        className="form-control input-largest"
                        name="stock"
                        value={this.state.percentage}
                        placeholder="Digite a porcentagem do desconto"
                        type="number"
                        step="0.01"
                      />
                    </CollumComponent>
                  </FormGroupComponent>
                </div>
                <div className="col-lg-2">
                  <FormGroupComponent className="form-group row button-size">
                    <CollumComponent size="lg">
                      <ButtonComponent
                        type="button"
                        disabled={
                          !this.state.ordering || !this.state.percentage
                        }
                        click={() => this.handleAddDiscount()}
                      >
                        Cadastrar
                      </ButtonComponent>
                    </CollumComponent>
                  </FormGroupComponent>
                </div>
              </div>
              <div className="table-container-look">
                <ListDiscountComponent
                  date={look.itemsDiscount}
                  onDelete={this.handleRemoveOrdering}
                />
              </div>
            </div> */}
            {/* ==== BLOCO ==== */}
            <div className="box-look row">
              <div className="col-lg-4">
                <div className=" box-col-min">
                  <div className="icon-large">
                    {/* LOOK ATIVO */}
                    <CheckboxComponent
                      id="status"
                      name="status"
                      medium
                      dataOff="Não"
                      defaultChecked={look.status ? look.status : false}
                      checked={look.status ? look.status : false}
                      dataOn="Sim"
                      onChange={(e) => {
                        this.handleActiveLook(e);
                      }}
                    />
                  </div>
                  <h4>Look Ativo?</h4>
                  <p>
                    {this.state.toogleActive
                      ? 'O Look estará ativo. Vai para à loja.'
                      : 'O Look estará inativo.'}
                  </p>
                </div>
              </div>
            </div>
            <div className="save-look-button-container">
              <ButtonComponent type="submit">Salvar</ButtonComponent>
            </div>
          </FormComponent>
        )}

        {
          // Componente de Produto Selecionados
          isVisibleProductSelected && (
            <div className="ProductSelectedComponent look-product-selected-body">
              <div className="title-container">
                <h3>
                  {this.state.updateProductLook
                    ? 'ATUALIZANDO PRODUTO PARA O LOOK'
                    : 'NOVO PRODUTO PARA O LOOK'}
                </h3>
                {this.state.loadingProductInScreen && <LoadingComponent2 />}
                <FaArrowLeft
                  onClick={() => {
                    this.setState({
                      isVisibleProductSelected: false,
                      updateProductLook: false,
                    });
                    this.lookStore.handleChangeComponentProduct();
                    this.resetProductLook();
                  }}
                />
              </div>
              <div className="box-select-product-look">
                <FormGroupComponent className="form-group row">
                  <LabelComponent
                    name="Selecionar o produto"
                    htmlFor="productLook"
                  />
                  <CollumComponent size="lg" cols="12">
                    <AsyncSelectComponent
                      autoFocus={true}
                      items={optionsProducts}
                      placeholder="Digite o nome do produto"
                      findByName={this.handleSearchProduct}
                      isLoading={loadingSearchProduct}
                      isMulti={false}
                      isClearable={false}
                      onSelect={(e) => this.handleSelectProduct(e)}
                      value={this.state.productSelected}
                      isDisabled={
                        this.lookStore.productsAlternative.length > 0
                          ? true
                          : false
                      }
                      id="productLook"
                      name="productLook"
                    />
                  </CollumComponent>
                </FormGroupComponent>
              </div>

              <div className="col-12 container-max">
                <div className="center-form-container">
                  <div className="container-img">
                    <div className="box-img">
                      {this.state.productSelected &&
                      this.state.productSelectedDetails.files.length > 0 ? (
                        <img
                          src={this.state.productSelectedDetails.files[0].path}
                          alt={this.state.productSelectedDetails.name}
                        />
                      ) : (
                        <p>Imagem</p>
                      )}
                    </div>
                  </div>
                  <div className="container-inputs">
                    <div className="box-sku-product-look">
                      <div className="sku-product-look">
                        <FormGroupComponent className="form-group row">
                          <LabelComponent name="SKU" htmlFor="product_sku" />
                          <CollumComponent size="lg" cols="12">
                            <InputComponent
                              onChange={() => {}}
                              className="form-control"
                              name="product_sku"
                              id="product_sku"
                              value={
                                this.state.productSelected
                                  ? this.state.productSelectedDetails.skuCode
                                  : ''
                              }
                              placeholder="SKU"
                              disabled
                            />
                          </CollumComponent>
                        </FormGroupComponent>
                      </div>
                    </div>
                    <FormGroupComponent className="form-group row">
                      <LabelComponent name="Nome" htmlFor="product_name" />
                      <CollumComponent size="lg" cols="12">
                        <InputComponent
                          onChange={() => {}}
                          className="form-control"
                          name="product_name"
                          id="product_name"
                          value={
                            this.state.productSelected
                              ? this.state.productSelectedDetails.name
                              : ''
                          }
                          placeholder="Nome"
                          disabled
                        />
                      </CollumComponent>
                    </FormGroupComponent>
                    <FormGroupComponent className="form-group row">
                      <LabelComponent
                        name="Categoria"
                        htmlFor="product_category"
                      />
                      <CollumComponent size="lg" cols="12">
                        <InputComponent
                          onChange={() => {}}
                          className="form-control"
                          name="product_category"
                          id="product_category"
                          rows="10"
                          readOnly
                          defaultValue={
                            this.state.productSelected &&
                            this.state.productSelectedDetails.categories &&
                            this.state.productSelectedDetails.categories
                              .length > 0
                              ? this.state.productSelectedDetails.categories.map(
                                  (c, i) => {
                                    if (i > 0) {
                                      return ` ${c.categoryName}`;
                                    }
                                    return c.categoryName;
                                  }
                                )
                              : ''
                          }
                          placeholder="Categoria"
                          disabled
                        />
                      </CollumComponent>
                    </FormGroupComponent>
                    <FormGroupComponent className="form-group row">
                      {/* DESCRIÇÃO */}
                      <LabelComponent
                        name="Descrição"
                        htmlFor="product_description"
                      />
                      <CollumComponent size="lg" cols="12">
                        <TextareaComponent
                          onChange={() => {}}
                          className="form-control"
                          name="product_description"
                          id="product_description"
                          rows="3"
                          readOnly
                          defaultValue={
                            this.state.productSelected
                              ? this.state.productSelectedDetails.description
                              : ''
                          }
                          placeholder="Descrição"
                          disabled
                        />
                      </CollumComponent>
                    </FormGroupComponent>
                    <div className="row">
                      <div className="col-lg-6 sku-center">
                        {/* <FormGroupComponent>
                        <LabelComponent
                          name="Preço"
                          htmlFor="product_price"
                        />
                        <CollumComponent size="lg" cols="12">
                          <InputComponent
                            type="text"
                            name="product_price"
                            className="form-control input-smaller w-50"
                            value={this.state.productSelected && this.state.productSelectedDetails.prices.length > 0 ? this.state.productSelectedDetails.prices[0].price : ''}
                            id="product_price"
                            placeholder="Preço"
                            required
                            disabled
                          />
                        </CollumComponent>
                      </FormGroupComponent> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="alternative-product">
                  <h3>PRODUTO ALTERNATIVO</h3>
                  <div className="row">
                    <div
                      className="box-select-product-look"
                      className="col-lg-9"
                    >
                      <FormGroupComponent className="form-group row">
                        <LabelComponent
                          name="Selecionar o produto alternativo"
                          htmlFor="productAlternative"
                          className="col-lg-7"
                        />
                        <CollumComponent size="lg" cols="12">
                          <AsyncSelectComponent
                            autoFocus={true}
                            items={optionsProducts}
                            placeholder="Digite o nome do produto alternativo"
                            findByName={this.handleSearchProductAlternative}
                            isLoading={loadingSearchProduct}
                            isMulti={false}
                            isClearable={false}
                            onSelect={(e) =>
                              this.handleSelectProductAlternative(e)
                            }
                            value={this.state.productAlternativeSelected}
                            isDisabled={
                              !this.state.productSelected ? true : false
                            }
                            id="productAlternative"
                            name="productAlternative"
                          />
                        </CollumComponent>
                      </FormGroupComponent>
                    </div>
                    <div className="col-lg-3 button-size">
                      <FormGroupComponent className="form-group row ">
                        <CollumComponent size="lg">
                          <ButtonComponent
                            type="button"
                            disabled={
                              !this.state.productAlternativeSelected ||
                              this.state.loadingProductInScreen
                            }
                            click={() => this.handleAddProductAlternative()}
                          >
                            Adicionar
                          </ButtonComponent>
                        </CollumComponent>
                      </FormGroupComponent>
                    </div>
                  </div>
                </div> */}
                {/* <div className="table-container-look">
                  <ListProductsAlternative
                    date={productsAlternative}
                    onDelete={this.handleRemoveProductAlternative}
                  />
                </div> */}
                <div className="button-create-look">
                  <ButtonComponent
                    type="button"
                    click={() => {
                      this.setState({
                        isVisibleProductSelected: false,
                        updateProductLook: false,
                      });
                      this.lookStore.handleChangeComponentProduct();
                      this.resetProductLook();
                    }}
                  >
                    Voltar
                  </ButtonComponent>
                  <ButtonComponent
                    type="button"
                    disabled={!this.state.productSelected}
                    click={() => this.handleSaveProductInLook()}
                  >
                    {this.state.updateProductLook ? 'Atualizar' : 'Salvar'}
                  </ButtonComponent>
                </div>
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

export default inject(({ lookStore, productStore }) => ({
  lookStore,
  productStore,
}))(observer(LookUpdateContainer));
