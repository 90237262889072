import { action, decorate, extendObservable } from 'mobx';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import {
  currencyToNumber,
  onlyUnique,
  onlyUniqueByProp,
  isArrayEmpty,
  validateImgLink,
  sortByDate,
  sortListBy,
} from '~/helpers/utils/Functions';
import GrupoAvenidaCategoryModel from '~/models/avenida/CategoryModel';
import SportbayProductComparativeModel from '~/models/sportbay/SportbayProductComparativeModel';
import { ProductTypeEnum } from '~/helpers/utils/Enums';
import EuroApplicationModel from '~/models/euro/EuroApplicationModel';
import GrupoAvenidaProductModel from '~/models/avenida/ProductModel';
import SupplierModel from '~/models/SupplierModel';
import CategoryAPI from '../services/CategoryAPI';
import ProductAPI from '../services/ProductAPI';
import UploadAPI from '../services/UploadAPI';
import { v4 as uuidv4 } from 'uuid';
import FileModel from '~/models/FileModel';

/**Valores inicias de variaveis observadas */
const initValues = {
  loading: false,
  product: undefined,
  products: [],
  properties: [],
  classes: [],
  pricings: [],
  applications: [],
  config: {},
  suppliers: undefined,
  tabType: undefined,
  loadingSearchCategoryFeature: false,
  initialFeatureValue: true,
  categories_categoryName: null,
  name: null,
  skuCode: null,
  created: null,
  parent: true,
  toDate: null,
  fromDate: null,
};
class ProductStore {
  totalPages = 0;
  page = 0;
  size = 20;
  sort = 'name';
  filter = '';
  categoriesRemove = [];
  categoriesAdd = [];
  collectionsRemove = [];
  collectionsAdd = [];
  variations = [];
  princingsRemove = [];
  categoryFeatureValues = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rootStore.customerTypeStore.getList();
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  reset() {
    this.product = null;
    this.totalPages = 0;
    this.page = 0;
    this.products = [];
    this.size = 20;
    this.sort = 'name';
    this.filter = '';
    this.categoriesRemove = [];
    this.categoriesAdd = [];
    this.collectionsRemove = [];
    this.collectionsAdd = [];
    this.variations = [];
    this.princingsRemove = [];
    this.applications = [];
    this.propertiesStore.reset();
    this.categoryFeatureValues = [];
  }

  /**Retorna instancia da notificationStore  */
  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Retorna instancia da propertiesStore */
  get propertiesStore() {
    return this.rootStore.propertiesStore;
  }

  /**Retorna o merchant do usuário atual */
  get merchant() {
    return this.rootStore.usersStore.user.merchant;
  }

  getCustomerType(customerType) {
    return this.rootStore.customerTypeStore.getCustomerType(customerType);
  }

  createNewPrices() {
    const customerType = this.getCustomerType('A');

    let price = { price: 0, customerType: customerType };

    return [price];
  }

  /**Cria novo produto */
  createEmptyProduct() {
    this.product = undefined;
    const merchant = this.rootStore.usersStore.userMerchant;
    this.product = new GrupoAvenidaProductModel({
      prices: this.createNewPrices(),
      merchant: merchant,
    });

    this.product.active = false;
    this.product.parent = true;
  }

  /**
   * Retorna atributos do produto de acordo com o tipo
   * @param {('colors'|'units'|'sizes')} type
   */
  async getProductsProps(type) {
    this.loading = true;

    const response = await ProductAPI.getProductsProps(type);
    if (response.error) {
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.config = { ...this.config, [type]: response.content };

    this.loading = false;
  }

  /**Atualiza propriedades do produto. */
  async updateProp(prop, value) {
    const product = this.product;
    switch (prop) {
      case 'name':
        product.name = value;
        if (!product.shortName) product.shortName = value;
        break;
      case 'description':
        product.description = value;
        if (!product.shortDescription || product.shortDescription.length < 20)
          product.shortDescription = value;
        break;
      case 'fakePrice':
        product.fakePrice = currencyToNumber(value);
        if (!product.price) product.price = currencyToNumber(value);
        break;
      case 'supplier':
        product.setSupplier({ uuid: value.value });
        break;

      case 'active':
        const productType = product.parent
          ? ProductTypeEnum.MODEL
          : ProductTypeEnum.VARIATION;

        if (productType === ProductTypeEnum.VARIATION) {
          delete this.product.display;
        } else {
          delete this.product.displayVariationWindow;
        }

        product.active = value;

        break;

      /*  case 'productProperties':
                 const variations = [new ProductPropertyModel({ uuid: value.value, propertyValue: value.label })];
                 product.productProperties = variations.length > 0 ? variations : undefined;
                 break; */
      case 'price':
        if (product.prices.length > 0) {
          product.prices[0].price = currencyToNumber(value);
        } else if (product.prices.length === 0) {
          product.prices.push({
            price: currencyToNumber(value),
            customerType:
              this.rootStore.customerTypeStore.getDefaultCustomerType(),
          });
        }
        break;
      case 'absoluteDiscount':
      case 'percentualDiscount':
      case 'weight':
      case 'height':
      case 'width':
      case 'length':
      case 'shippingWeight':
      case 'shippingHeight':
      case 'shippingWidth':
      case 'shippingLength':
      case 'stock':
      case 'committedStock':
      case 'securityStock':
      case 'deliveryTime':
        const number = currencyToNumber(value);
        number > 0 ? (product[prop] = number) : (product[prop] = 0);
        break;
      default:
        product[prop] = value;
    }
    this.product = new GrupoAvenidaProductModel(product);
  }

  /**Manipula lista de categorias selecionadas ou descelecionadas */
  setCategories(selecteds) {
    this.categoriesRemove = this.product.categories.filter(
      (cat) => !selecteds.some((mCat) => mCat.uuid === cat.uuid)
    );
    this.categoriesAdd = selecteds.filter(
      (cat) => !this.product.categories.some((mCat) => mCat.uuid === cat.uuid)
    );

    this.product.categories = [
      ...this.product.categories,
      ...this.categoriesAdd,
    ]
      .filter((f) => this.categoriesRemove.indexOf(f) < 0)
      .filter(onlyUnique);

    return [...this.product.categories, ...this.categoriesAdd]
      .filter((f) => this.categoriesRemove.indexOf(f) < 0)
      .filter(onlyUnique);
  }

  /**Manipula lista de categorias selecionadas ou descelecionadas */
  setCollections(selecteds) {
    this.collectionsRemove = this.product.collections.filter(
      (cat) => !selecteds.some((mCat) => mCat.uuid === cat.uuid)
    );
    this.collectionsAdd = selecteds.filter(
      (cat) => !this.product.collections.some((mCat) => mCat.uuid === cat.uuid)
    );

    return [...this.product.collections, ...this.collectionsAdd]
      .filter((f) => this.collectionsRemove.indexOf(f) < 0)
      .filter(onlyUnique);
  }

  async handleAddFeatureClickCategory(categoryUuid) {
    this.loading = true;
    const response = await CategoryAPI.getAllFeaturesCategory(categoryUuid);
    if (!response.error) {
      this.products = await response.content.map(
        (prd) => new GrupoAvenidaProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /* Adição de aplicação */
  addAppl(data) {
    this.product.applications.push(
      new EuroApplicationModel({ uuid: data.value, name: data.label })
    );
  }

  addSuppl(data) {
    this.product.supplier = new SupplierModel({ uuid: data.value });
  }

  /**Adiciona imagem ao produto */
  handleProductImages(tag, file) {
    if (!this.product) this.createEmptyProduct();
    const product = this.product;

    const fileItem = product.files.find((file) => file.metaTags[0] === tag);
    //Se fileItem for null é uma nova imagem.
    if (!fileItem) {
      const newFileItem = new FileModel({
        file,
        ...file,
        metaTags: [tag],
      });
      product.files.push(newFileItem);
    }
    //FileItem existe, Atualiza valores do arquivo recebido
    else fileItem.updateFile(file);
  }

  async addPricingForProduct(propertyGroup) {
    this.loading = true;
    const response = await ProductAPI.addProcuctPricing(
      this.product.uuid,
      propertyGroup
    );
    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async getParentProducts(name) {
    const response = await ProductAPI.getParentProducts(name);

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);

    return response;
  }

  /**Vincula princig no produto */
  async removePricingForProduct(propertyGroup) {
    this.loading = true;
    const response = await ProductAPI.removeProcuctPricing(
      this.product.uuid,
      propertyGroup.uuid
    );
    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 20, productType, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = sort ? sort : 'created,desc';
    this.tabsPage = this.tabType ? this.tabType : ProductTypeEnum.MODEL;

    await this.getListByType(this.tabsPage);
  }

  async searchBySku(inputValue, size = 12) {
    this.page = 0;
    this.sort = 'name';
    this.filter = inputValue;
    this.size = size;
    await this.getListBySku(inputValue);
    return this.getListSelect();
  }

  async searchByName(inputValue, size = 12) {
    this.page = 0;
    this.sort = 'name';
    this.filter = inputValue;
    this.size = size;
    await this.getListByType(this.tabType);
    return this.getListSelect();
  }

  /**Salva um novo produto */
  async save() {
    this.loading = true;

    this.product.categories = this.categoriesAdd.map(({ uuid }) => ({ uuid }));

    this.product.productProperties = this.product.productProperties.map(
      (props) =>
        this.setValueOfCategoriesProps(props.uuid, props.propertyClass.uuid)
    );

    this.product.productVariations = this.product.productVariations.map(
      ({ uuid }) => ({ uuid })
    );

    delete this.product.config;

    if (this.product.part) {
      this.product.itemsCombo = [];
    }
    const merchant = await this.rootStore.merchantStore.getDefaultMerchant();
    this.product.merchant = merchant;
    const data = JSON.stringify(this.product);
    let response = await ProductAPI.save(data);

    if (!response.error) {
      const product = new GrupoAvenidaProductModel(response.data);
      await this.handleCategories(product.uuid);
      if (this.categoryFeatureValues.length > 0) {
        await this.saveProductFeatureValue(product.uuid);
      }
      this.createEmptyProduct();
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Produto Cadastrado');
    } else {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Não foi possível cadastrar o produto'
      );
    }
    this.loading = false;
    return response;
  }

  /**Envia propriedades */
  async handlePricings(productUuid) {
    const groups = [];
    this.propertiesStore.classesSelecteds.map((cls) =>
      groups.push(...cls.groups)
    );

    //Pega todos os princings ativos ( > 0)
    const arrayAdd = [];
    let arrayRemoveds = [];

    //Se grupos forem  > 0 verifica o que é novo e o que foi removido.
    if (groups.length > 0) {
      groups.map((pricing) => {
        if (pricing.priceIncrease > 0) arrayAdd.push(pricing);
        else if (pricing.uuid && pricing.priceIncrease <= 0)
          arrayRemoveds.push(pricing);
        return pricing;
      });
      //Verifica variações que foram removidas.
      arrayRemoveds = [
        ...arrayRemoveds,
        ...this.variations.filter(
          (variation) => !groups.some((group) => group.uuid === variation.uuid)
        ),
      ];
    }
    //Senão todas as variações foram deletadas.
    else arrayRemoveds = this.variations;
    let promises = [];
    promises.push(
      arrayAdd.map(
        async (property) =>
          await ProductAPI.addProcuctPricing(productUuid, property)
      )
    );
    promises.push(
      arrayRemoveds.map(
        async (pricing) =>
          await ProductAPI.removeProcuctPricing(productUuid, pricing.uuid)
      )
    );

    promises.length > 0 &&
      (await this.handlePromises(promises, 'Falha ao desvincular categorias'));
  }

  /**Vincula produto em categorias. */
  async handleCategories(productUuid) {
    let promises = [];
    if (this.categoriesAdd.length > 0) {
      promises = this.categoriesAdd.map(
        async (category) =>
          await CategoryAPI.addProductsCategory(category.uuid, {
            uuid: productUuid,
          })
      );
    }
    if (this.categoriesRemove.length > 0) {
      promises = [
        ...promises,
        this.categoriesRemove.map(
          async (category) =>
            await CategoryAPI.removeProductsCategory(category.uuid, {
              uuid: productUuid,
            })
        ),
      ];
    }

    if (this.collectionsAdd.length > 0) {
      promises = this.collectionsAdd.map(
        async (category) =>
          await CategoryAPI.addProductsCategory(category.uuid, {
            uuid: productUuid,
          })
      );
    }
    if (this.collectionsRemove.length > 0) {
      promises = [
        ...promises,
        this.collectionsRemove.map(
          async (category) =>
            await CategoryAPI.removeProductsCategory(category.uuid, {
              uuid: productUuid,
            })
        ),
      ];
    }
    promises.length > 0 &&
      (await this.handlePromises(promises, 'Falha ao desvincular categorias'));
  }

  /**Busca todos os produtos */
  async getList() {
    this.loading = true;
    const params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
      name: this.filter,
    };
    const response = await ProductAPI.list(params);

    if (!response.error) {
      this.products = await response.content.map(
        (prd) => new GrupoAvenidaProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  headSort(sort) {
    if (this.products.length) {
      sort = sort ?? 'name';

      const sortsTypes = {
        name: (list) => sortListBy(list, 'name'),
        created: sortByDate,
        active: (list) => sortListBy(list, 'active'),
      };

      const products = this.products.slice();

      const sorted = sortsTypes[sort](products);

      this.products = sorted;
    }
  }

  /**Busca todos os produtos */
  async getListByType(params = { search: '' }) {
    this.loading = true;

    params.sort = this.sort;

    const response = await ProductAPI.listByType(params);

    if (!response.error) {
      this.products = await response.content.map(
        (prd) => new GrupoAvenidaProductModel(prd)
      );

      //this.headSort(this.sort);

      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Quando selecionar uma tab faz a busca para setar no grid/tabela */
  async setTabContent(productType) {
    this.totalPages = 0;
    this.page = 0;
    this.products = [];
    this.size = 10;
    this.sort = 'created,desc';
    this.filter = '';
    this.tabType = productType;
    await this.getListByType();
  }

  async getListProductsToCampaign(term) {
    const params = { search: `skuCode:*${term}*,parent:false,active:true` };

    const response = await ProductAPI.listByType(params);

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);

    return response.content;
  }

  async getListBySku(skuCode = this.filter) {
    this.loading = true;

    const params = {
      search: {
        skuCode,
      },
      withStockOnly: false,
      page: 0,
      sort: 'name',
    };
    const response = await ProductAPI.getProductBySku(skuCode);
    if (!response.error) {
      this.products = await response.content.map(
        (prd) => new GrupoAvenidaProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Atualiza Produto */
  async update(productUuid) {
    this.loading = true;

    const categories = this.product.categories;

    this.product.productProperties = this.product.productProperties.map(
      (props) =>
        this.setValueOfCategoriesProps(props.uuid, props.propertyClass.uuid)
    );

    this.product.categories = categories.map(({ uuid }) => ({
      uuid,
    }));

    this.product.productVariations = this.product.productVariations.map(
      ({ uuid }) => ({ uuid })
    );

    delete this.product.collections;

    const data = JSON.stringify(this.product);

    const response = await ProductAPI.update(this.product.uuid, data);

    if (!response.error) {
      await this.handleCategories(this.product.uuid);
      if (this.categoryFeatureValues.length > 0) {
        await this.updateProductFeatureValue(this.product.uuid, productUuid);
      }
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Alterações gravadas com sucesso.'
      );
    } else {
      // this.product.files = files;
      this.product.categories = categories;

      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loading = false;
    return response;
  }

  /**Deleta produto */
  async delete(uuid) {
    this.loading = true;
    const response = await ProductAPI.delete(uuid);
    if (!response.error) {
      this.getList();
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Produto deletado');
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);
    this.loading = false;
  }

  /**Desassocia a variação */
  async disableVariation(variationUuid) {
    const response = await ProductAPI.disableVariation(variationUuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Variação desabilitada');
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);

    return response;
  }

  /**Desassocia a variação */
  async activateVariation(variationUuid) {
    const response = await ProductAPI.activateVariation(variationUuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Variação habilitada');
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);

    return response;
  }

  /**Busca categorias do produto */
  async getCategories(productUuid) {
    const response = await ProductAPI.getCategories(productUuid);
    let categories = [];
    if (!response.error)
      categories = response.map((cat) => new GrupoAvenidaCategoryModel(cat));
    else this.toastHelper.notify(STATUS_HELPER.error, response.error);
    return categories;
  }

  /**Busca apenas um determinado produto */
  async get(value, prop = 'uuid') {
    this.loading = true;
    const response = await ProductAPI.get(prop, value);
    if (!response.error) {
      const product = new GrupoAvenidaProductModel(response);
      product.categories = await this.getCategories(product.uuid);
      this.product =
        product.prices.length === 0
          ? new GrupoAvenidaProductModel({
              ...product,
              prices: this.createNewPrices(),
            })
          : new GrupoAvenidaProductModel(product);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);

    this.loading = false;

    return response;
  }

  /**Envia arquivo de proposta que foi anexo */
  async sendFiles(productUuid, files) {
    const newFiles = files.filter((file) => !file.uuid);
    const updateds = files.filter(
      (file) => file.uuid && file.localModified && file.file
    );
    const deleteds = files.filter(
      (file) => file.uuid && !file.file && file.localModified
    );

    newFiles.length > 0 && (await this.sendNewFiles(newFiles, productUuid));
    updateds.length > 0 && (await this.updateFiles(updateds));
    deleteds.length > 0 && (await this.deleteFiles(deleteds));
  }

  /**Funcão útil para dar feedbeack sobre envio de arquivos. */
  async handlePromises(promises, message) {
    const response = await Promise.all(promises);
    const errors = response.filter((r) => r && r.error !== undefined);
    if (errors && errors.length > 0) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, message);
    }
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Atualiza imagem da categoria */
  async deleteFiles(files) {
    const promise = files.map(async (file) => {
      await UploadAPI.removeFile(file.uuid);
    });
    return await this.handlePromises(promise, 'Falha o deletar arquivo');
  }

  async updateFiles(updatedFiles) {
    const promiseUpdate = updatedFiles.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.updateFile(file);
    });
    const response = await this.handlePromises(
      promiseUpdate,
      'Falha atualizar arquivos.'
    );
    setTimeout(() => {
      updatedFiles.forEach((file) =>
        this.notificationStore.removeItemUpload(file)
      );
    }, 3300);
    return response;
  }

  async sendNewFiles(newFiles, productUuid) {
    const promiseNew = newFiles.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.uploadProduct(productUuid, file);
    });
    const response = await this.handlePromises(
      promiseNew,
      'Falha anexar arquivos.'
    );
    setTimeout(() => {
      newFiles.forEach((file) => this.notificationStore.removeItemUpload(file));
    }, 3300);
    return response;
  }

  /**Retorna lista para uso no select */
  getListSelect(products = this.products) {
    return products.map((prd) => ({
      value: prd.uuid,
      label: prd.name,
    }));
  }

  /**Retorna lista de categoria para uso no select */
  getListCategorySelect(categories) {
    if (!categories) {
      return [];
    } else {
      return categories.map((ctg) => ({
        value: ctg.uuid,
        label: ctg.categoryName,
      }));
    }
  }

  changeToApplicationsSelect(app) {
    return app.map((m) => ({ value: m.uuid, label: m.getNameSelect }));
  }

  async checkCSV(newFiles) {
    this.loading = true;
    let promiseNew = [await this.notificationStore.addItemUpload(newFiles)];
    promiseNew = [...promiseNew, await UploadAPI.checkCSV(newFiles)];
    const response = await this.handlePromises(
      promiseNew,
      'Falha anexar arquivos.'
    );
    setTimeout(
      () => (newFiles) => this.notificationStore.removeItemUpload(newFiles),
      3300
    );
    this.loading = false;
    return response[1];
  }

  async sendCSV(newFiles) {
    this.loading = true;
    let promiseNew = [await this.notificationStore.addItemUpload(newFiles)];
    promiseNew = [...promiseNew, await UploadAPI.sendCSV(newFiles)];
    const response = await this.handlePromises(
      promiseNew,
      'Falha anexar arquivos.'
    );
    if (response[1].data.sucess) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Atualizado com sucesso');
    }
    setTimeout(
      () => (newFiles) => this.notificationStore.removeItemUpload(newFiles),
      3300
    );
    this.loading = false;
    return response[1];
  }

  async getCsvProducts() {
    this.loading = true;
    let response = await UploadAPI.getProductsCSV();
    if (!response.error) {
      this.loading = false;
      return response.data;
    }
    this.loading = false;
    return;
  }

  /**Adiciona Propriedade no product */
  addPropertieSelected(propertie) {
    this.product.productProperties.push(propertie);
  }

  /**Remove a propriedade selecionada */
  removePropertieSelected(element) {
    this.loading = true;
    const productProperties = this.product.productProperties.filter(
      (e) => e.uuid !== element.uuid
    );
    this.product.productProperties = productProperties;
    this.loading = false;
  }

  /**Adiciona Parte/Produto no itemsCombo */
  addComboSelected(partProduct) {
    this.product.itemsCombo.push(partProduct);
  }

  /**Remove a Parte/Produto no itemsCombo */
  removeComboSelected(element) {
    this.loading = true;
    const itemsCombo = this.product.itemsCombo.filter(
      (e) => e.product.uuid !== element.product.uuid
    );
    this.product.itemsCombo = itemsCombo;
    this.loading = false;
  }

  //**Valida se o produto possui categoria ativa no cadastro */
  checkProductHasCategory() {
    if (this.product.categories && this.product.categories.length > 0) {
      if (this.categoriesRemove.length === this.product.categories.length) {
        if (this.categoriesAdd.length > 0) {
          return true;
        } else {
          return false;
        }
      } else return true;
    } else if (this.categoriesAdd.length > 0) {
      return true;
    }

    return false;
  }

  handleChangeImageModel(path) {
    const product = this.product;
    const metaTags = ['card'];

    let file = {
      path,
      metaTags,
      size: 1,
      name: product.name ? product.name + uuidv4() : uuidv4(),
    };
    file = new FileModel(file);

    if (product.files && product.files.length > 0) {
      product.files = [file];
    } else if (product.files && product.files.length === 0) {
      product.files.push(file);
    }

    this.product = new GrupoAvenidaProductModel(product);
  }

  // Deixa a variação no padrão do model
  handleTransformProductVariation(product) {
    const transformProduct = new GrupoAvenidaProductModel(product);
    return transformProduct;
  }

  // Remove uma variação dentro do produto modelo
  removeProductVariation(variation) {
    if (!isArrayEmpty(this.product.productVariations)) {
      this.product.productVariations = this.product.productVariations.filter(
        (f) => f.uuid !== variation.uuid
      );

      this.product = new GrupoAvenidaProductModel(this.product);

      this.groupPropertyClassOfVariations(variation, true);
    }
  }

  // Adiciona uma variação no modelo
  addProductVariation(variation) {
    if (isArrayEmpty(this.product.productVariations)) {
      this.product.productVariations = [];
    }

    const alreadyExists = this.product.productVariations.filter(
      (f) => f.uuid === variation.uuid
    )[0];

    if (!alreadyExists) {
      this.product.productVariations.push(variation);
      this.product = new GrupoAvenidaProductModel(this.product);
    }

    this.groupPropertyClassOfVariations(variation);
  }

  groupPropertyClassOfVariations(variation, isDelete = false) {
    let propertyClassses = [];

    if (isArrayEmpty(this.product.productVariations)) {
      this.product.productVariations = [];
    }

    if (isArrayEmpty(this.variationsAttributesGrouped)) {
      this.variationsAttributesGrouped = [];
    }

    if (!isArrayEmpty(variation.productProperties)) {
      variation.productProperties.forEach((property) => {
        propertyClassses.push(property.propertyClass);
      });
    }

    if (isDelete) {
      propertyClassses.forEach((propertyClass) => {
        // se ainda existir variação com a property class proposta a removoer, não remove
        const yetExistsVariation = this.product.productVariations.some((f) =>
          f.productProperties.some(
            (s) => s.propertyClass.uuid === propertyClass.uuid
          )
        );

        if (yetExistsVariation) {
          propertyClass.needToRemove = false;
        } else {
          propertyClass.needToRemove = true;
        }
      });

      const propertyClassesToRemove = propertyClassses.filter(
        (f) => f.needToRemove
      );

      this.variationsAttributesGrouped =
        this.variationsAttributesGrouped.filter(
          (f) => !propertyClassesToRemove.some((s) => s.uuid === f.uuid)
        );
    } else {
      this.variationsAttributesGrouped = [
        ...this.variationsAttributesGrouped,
        ...propertyClassses,
      ].filter((f, index, self) => onlyUniqueByProp(f, index, self, 'uuid'));
    }
  }

  // Adiciona Imagem na variação
  handleAddImageVariation(path) {
    let validate = validateImgLink(path);
    if (validate) {
      const product = this.product;
      const files = this.product.files;

      const metaTagName = files.length ? `carousel-${files.length}` : 'card';

      const metaTags = [metaTagName];

      const file = {
        deleted: false,
        active: true,
        name: product.name ? product.name + uuidv4() : uuidv4(),
        path,
        metaTags,
        size: 1,
        _preview: path,
      };

      product.files.push(file);
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'URL invalida');
    }
  }

  handleRemoveImageVariation(index) {
    const product = this.product;
    const files = [];

    product.files.map((f, i) => {
      if (index !== i) {
        files.push(f);
      }
    });

    product.files = files.map((f, i) => ({
      ...f,
      metaTags: i === 0 ? ['card'] : [`carousel-${i}`],
    }));

    this.product = new GrupoAvenidaProductModel(product);
  }

  /**Retorna variações filtradas de acordo com o term */
  async getAvailableProductVariations(term) {
    const response = await ProductAPI.getAvailableProductVariations(term);

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);

    return response;
  }

  /**Desassocia a variação */
  async disassociateVariation(variationUuid) {
    const response = await ProductAPI.disassociateVariation(variationUuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Variação desassociada');
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);

    return response;
  }

  /**Busca os comparatiivos da categoria selecionada pelo usuário */
  async listCategoryFeatures(categoryUuid) {
    this.loadingSearchCategoryFeature = true;
    const response = await ProductAPI.listCategoryFeatures(categoryUuid);
    if (!response.error) {
      if (response.length > 0) {
        const categoryFeatureValues = [];
        response.map((ft) => {
          categoryFeatureValues.push(
            new SportbayProductComparativeModel({
              uuid: ft.categoryFeatureUUID,
              name: ft.feature,
              type: ft.type,
              value: ft.type === 'TEXT' ? '' : false,
            })
          );
        });
        this.categoryFeatureValues = categoryFeatureValues;
      } else {
        this.categoryFeatureValues = [];
      }
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);
    this.loadingSearchCategoryFeature = false;
    this.initialFeatureValue = true;
    return response;
  }

  /**Altera o valor de um input ou select dentro do array de categoryFeatureValues */
  handleChangeCategoryFeatureValue(value, index) {
    let categoryFeatureValues = this.categoryFeatureValues;

    categoryFeatureValues[index].value = value;

    this.categoryFeatureValues = categoryFeatureValues;
  }

  async setCategoryFeatureValue(uuidProduct) {
    const response = await ProductAPI.listProductFeature(uuidProduct);
    if (!response.error) {
      this.categoryFeatureValues = response.data;

      if (response.data.length > 0) {
        this.initialFeatureValue = false;
      }
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);

    return response;
  }

  async saveProductFeatureValue(productUUID) {
    const data = {
      productUUID,
      categoryFeatureValues: this.categoryFeatureValues,
    };

    let response = await ProductAPI.saveProductFeatureValue(data);

    if (!response.error) {
      return response;
    } else {
      return 'Falha ao salvar atributos para comparativo';
    }
  }

  async updateProductFeatureValue(productUUID, categoryUUID) {
    let categoryFeatureValues = [];

    if (this.initialFeatureValue) {
      categoryFeatureValues = this.categoryFeatureValues;
    } else {
      this.categoryFeatureValues.map((ft) => {
        categoryFeatureValues.push({
          productFeatureUUID: ft.uuid,
          uuid: ft.categoryFeatureUUID,
          value: ft.value,
        });
      });
    }

    const data = {
      productUUID,
      categoryUUID,
      categoryFeatureValues: categoryFeatureValues,
    };

    let response = await ProductAPI.updateProductFeatureValue(data);

    if (!response.error) {
      return response;
    } else {
      return 'Falha ao salvar atributos para comparativo';
    }
  }

  toggleCategoriesProps(data) {
    this.product.productProperties = data;
  }

  setValueOfCategoriesProps(valueAttributeId, attributeId, categoryId) {
    const data = {
      categoryId,
      uuid: valueAttributeId,

      propertyClass: {
        uuid: attributeId,

        merchant: {
          uuid: this.merchant.uuid,
        },
      },
    };

    return categoryId
      ? (this.product.productProperties = [
          ...this.product.productProperties,
          data,
        ])
      : data;
  }

  setProps(propsList) {
    this.product.productProperties = propsList;
  }
}

export default ProductStore;

decorate(ProductStore, {
  getList: action,
  createEmptyProduct: action,
  searchByName: action,
});
