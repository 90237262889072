import { extendObservable } from 'mobx';

import BannerFileModel from '~/models/BannerFileModel';
import BannerModel from '~/models/BannerModel';
import FileModel from '~/models/FileModel';
import CategoryAPI from '../services/CategoryAPI';
import BannerAPI from '../services/BannerAPI';
import UploadAPI from '../services/UploadAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';

const initValues = {
  loading: false,
  banner: {},
  bannerFile: {},
  files: [],
  categories: [],
  disabledBannerFile: false,
  banners: [],
  searchVerifyBanner: false,
  bannerTags: [],
};

class BannerStore {
  totalPages = 0;
  page = 0;
  size = 10;
  sort = 'created,desc';
  filter = '';
  status = 'all';
  tag = 'SLIDESHOW';

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
    this.banner = new BannerModel();
    this.bannerFile = new BannerFileModel();
  }

  reset() {
    this.banner = new BannerModel();
    this.bannerFile = new BannerFileModel();
    this.files = [];
    this.categories = [];
    this.totalPages = 0;
    this.disabledBannerFile = false;
    this.page = 0;
    this.size = 10;
    this.sort = 'created,desc';
    this.filter = '';
    this.status = 'all';
    this.tag = 'SLIDESHOW';
  }

  // Reseta o banner ao trocar de tag no select
  resetBanner() {
    this.files = [];
    this.banner.categoryUUID = '';
    this.disabledBannerFile = false;
    this.bannerFile = new BannerFileModel();
  }

  async searchByName(inputValue, size = 10) {
    this.page = 0;
    this.sort = this.sort;
    this.filter = inputValue;
    this.size = size;
    await this.getList();
    return this.getListSelect();
  }

  async searchByStatus(selectedValue, size = 10) {
    this.page = 0;
    this.size = size;
    this.status = selectedValue;
    await this.getList();
    return this.getListSelect();
  }

  async searchByTag(selectedValue, size = 10) {
    this.page = 0;
    this.size = size;
    this.tag = selectedValue === 'all' ? '' : selectedValue;
    await this.getList();
    return this.getListSelect();
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = sort ? sort : this.sort;

    await this.getList();
  }

  /** Busca todos os banners */
  async getList() {
    this.loading = true;
    const params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
      tag: this.tag,
      status: this.status,
      name: this.filter,
    };
    const response = await BannerAPI.list(params);

    if (!response.error) {
      this.banners = await response.content.map((bnn) => new BannerModel(bnn));
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Retorna lista para uso no select */
  getListSelect(banners = this.banners) {
    return banners.map((bnn) => ({
      value: bnn.uuid,
      label: bnn.nameTag,
    }));
  }

  // Altera os valores do objeto banner
  handleChangeBanner(prop, value) {
    const { banner } = this;

    banner[prop] = value;

    this.banner = new BannerModel(banner);
  }

  // Altera os valores do objeto da imagem banner
  handleChangeBannerFile(prop, value) {
    const { bannerFile } = this;

    bannerFile[prop] = value;

    this.bannerFile = bannerFile;
  }

  // Adiciona o objeto da imagem no array files
  handleAddBannerFileInBanners() {
    const { files, bannerFile } = this;
    if (files.length > 0) {
      const verifyName = files.filter(
        (i) => i.name.toLowerCase() === bannerFile.name.toLowerCase()
      );
      const verifyOrdering = files.filter(
        (i) => i.ordering == bannerFile.ordering
      );

      if (verifyName.length > 0 || verifyOrdering.length) {
        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          'Já existe uma imagem cadastrada com esse nome ou com essa ordenação'
        );
        return false;
      } else {
        files.push(bannerFile);
        this.files = files;
        this.bannerFile = new BannerFileModel();
        this.toastHelper.notify(
          STATUS_HELPER.INFO,
          'Imagem cadastrada com sucesso'
        );
        return true;
      }
    } else {
      files.push(bannerFile);
      this.files = files;
      this.bannerFile = new BannerFileModel();
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Imagem cadastrada com sucesso'
      );
      return true;
    }
  }

  /** Remove uma imagem que não está salva no backend do banner no array banners */
  handleRemoveImage(bannerFile) {
    let { files } = this;

    const filesBanners = files.filter((e) => e.name !== bannerFile.name);

    this.files = filesBanners;

    if (filesBanners.length === 0) {
      const { banner } = this;

      banner.active = false;

      this.banner = new BannerModel(banner);
    }

    this.toastHelper.notify(STATUS_HELPER.INFO, 'Imagem deletada com sucesso');
  }

  /** Remove uma imagem que está salva no backend do banner no array banners */
  async handleRemoveUpdateImage(bannerFile) {
    this.loading = true;
    const response = await BannerAPI.deleteImageBanner(
      this.banner.uuid,
      bannerFile.uuid
    );

    if (!response.error) {
      let { files } = this;

      const filesBanners = files.filter((e) => e.name !== bannerFile.name);

      this.files = filesBanners;

      if (filesBanners.length === 0) {
        await this.handleInactiveBanner();
      }

      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Imagem deletada com sucesso'
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Desativa uma banner (utilizado quando deleta uma imagem e o array files fica vazio) */
  async handleInactiveBanner() {
    const response = await BannerAPI.inactiveBannerByUuid(this.banner.uuid);

    if (!response.error) {
      const { banner } = this;

      banner.active = false;

      this.banner = new BannerModel(banner);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
  }

  // traz todas as categorias
  async getCategories() {
    this.loading = true;
    const response = await CategoryAPI.getTree();
    this.loading = false;
    if (!response.error) {
      this.categories = response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return [];
  }

  // traz todas as tagts
  async getTags() {
    // this.loading = true;
    const response = await BannerAPI.getTags();
    // this.loading = false;
    if (!response.error) {
      this.bannerTags = response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return [];
  }

  /**Retorna lista para uso no select */
  getListCategorySelect(categories = this.categories) {
    return categories.map((ctg) => ({
      value: ctg.uuid,
      label: ctg.categoryName,
    }));
  }

  /** Valida a quantidade de imagens que foram adicionas de acordo com a tag */
  handleValidateLengthImages() {
    switch (this.banner.tag) {
      case 'IMAGENS_HOME':
        if (this.files && this.files.length === 8) {
          this.disabledBannerFile = true;
        } else {
          this.disabledBannerFile = false;
        }
        return;
      case 'SLIDESHOW_PRINCIPAL':
      case 'SLIDESHOW0_WEB':
      case 'MARCAS':
      case 'SLIDESHOW':
        this.disabledBannerFile = false;
        return;
      case 'SLIDESHOW1_APP':
        this.disabledBannerFile = false;
        return;
      case 'SLIDESHOW2_APP':
        this.disabledBannerFile = false;
        return;
      case 'BLOG':
        if (this.files && this.files.length === 3) {
          this.disabledBannerFile = true;
        } else {
          this.disabledBannerFile = false;
        }
        return;
      default:
        if (this.files && this.files.length === 1) {
          this.disabledBannerFile = true;
        } else {
          this.disabledBannerFile = false;
        }
    }
  }

  /**Salva o file de imagem no bannerFile */
  handleUploadImageBannerFile(file) {
    const bannerFile = this.bannerFile;

    bannerFile.file = new FileModel(file[0]);
    bannerFile.dataFile = file;

    this.bannerFile = bannerFile;
  }

  /**Salva um novo banner */
  async save() {
    const bannerVerify = await this.getBannerVerify();

    if (bannerVerify && bannerVerify.length > 0) {
      if (this.banner.tag === 'VITRINE_PLP') {
        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          `Não é possível salvar esse banner como ativo, pois o banner ${
            bannerVerify[0].nameTag
          } está ativo com a mesma tag, mesma categoria e ${
            this.banner.responsive ? 'responsivo' : 'não responsivo'
          }`
        );
      } else {
        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          `Não é possível salvar esse banner como ativo, pois o banner ${
            bannerVerify[0].nameTag
          } está ativo com a mesma tag e ${
            this.banner.responsive ? 'responsivo' : 'não responsivo'
          }`
        );
      }

      let response = { error: true };
      return response;
    }

    this.loading = true;
    const files = this.files;

    this.files = [];

    const data = JSON.stringify(this.banner);

    let response = await BannerAPI.save(data);

    if (!response.error) {
      const banner = new BannerModel(response.data);
      this.sendNewFiles(files, banner.uuid);
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Banner Cadastrado');
    } else {
      this.files = files;

      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Não foi possível cadastrar o banner'
      );
    }
    this.loading = false;
    return response;
  }

  /**Atualiza um banner */
  async update() {
    const bannerVerify = await this.getBannerVerify();

    if (
      bannerVerify &&
      bannerVerify.length > 0 &&
      bannerVerify[0].uuid !== this.banner.uuid
    ) {
      if (this.banner.tag === 'VITRINE_PLP') {
        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          `Não é possível salvar esse banner como ativo, pois o banner ${
            bannerVerify[0].nameTag
          } está ativo com a mesma tag, mesma categoria e ${
            this.banner.responsive ? 'responsivo' : 'não responsivo'
          }`
        );
      } else {
        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          `Não é possível salvar esse banner como ativo, pois o banner ${
            bannerVerify[0].nameTag
          } está ativo com a mesma tag e ${
            this.banner.responsive ? 'responsivo' : 'não responsivo'
          }`
        );
      }
      let response = { error: true };
      return response;
    }

    this.loading = true;

    const files = [];

    this.files.map((f) => {
      if (!f.uuid) {
        files.push(f);
      }
    });

    const data = JSON.stringify(this.banner);

    let response = await BannerAPI.update(this.banner.uuid, data);

    if (!response.error) {
      if (files.length > 0) {
        this.sendNewFiles(files, this.banner.uuid);
      }
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Banner atualizado');
    } else {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Não foi possível atualizar o banner'
      );
    }
    this.loading = false;
    return response;
  }

  /** Upload de novas imagens */
  async sendNewFiles(newFiles, bannerUuid) {
    const promiseNew = newFiles.map(async (bannerFile) => {
      this.rootStore.notificationStore.addItemUpload(bannerFile.file);
      const objectBannerFile = {
        name: bannerFile.name,
        ordering: bannerFile.ordering,
        link: bannerFile.link,
        promotionUUID: bannerFile.promotionUUID,
        description: bannerFile.description,
        subDescription: bannerFile.subDescription,
      };
      await UploadAPI.sendNewFileBanner(
        bannerUuid,
        objectBannerFile,
        bannerFile.dataFile
      );
    });
    const response = await this.handlePromises(
      promiseNew,
      'Falha anexar arquivos.'
    );
    setTimeout(() => {
      newFiles.forEach((file) =>
        this.rootStore.notificationStore.removeItemUpload(file)
      );
    }, 3300);
    return response;
  }

  /**Funcão útil paradá feedback sobre envio de arquivos. */
  async handlePromises(promises, message) {
    const response = await Promise.all(promises);
    const errors = response.filter((r) => r && r.error !== undefined);
    if (errors && errors.length > 0) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, message);
    }
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Busca apenas um determinado banner */
  async get(uuidBanner) {
    this.loading = true;
    const response = await BannerAPI.getByUuid(uuidBanner);
    if (!response.error) {
      this.banner = new BannerModel(response);
      this.files = response.images ? response.images : [];
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Chamada verificar se o banner que está sendo salvo/atualizado pode ficar ativo */
  async getBannerVerify() {
    if (
      this.banner.tag === 'SLIDESHOW_PRINCIPAL' ||
      !this.banner.active ||
      this.banner.tag === 'BLOG' ||
      this.banner.tag === 'IMAGENS_HOME' ||
      this.banner.tag === 'MARCAS'
    ) {
      return [];
    }
    this.searchVerifyBanner = true;
    const params = {
      tag: this.banner.tag,
      status: 'active',
    };

    const response = await BannerAPI.checkBanner(params);

    if (!response.error) {
      let filterResponsive = [];
      if (response.content && response.content.length > 0) {
        filterResponsive = response.content.filter(
          (e) => e.responsive === this.banner.responsive
        );
        if (this.banner.tag === 'VITRINE_PLP' && filterResponsive.length > 0) {
          filterResponsive = filterResponsive.filter(
            (e) => e.category.uuid === this.banner.categoryUUID
          );
        }
      }
      this.searchVerifyBanner = false;
      return filterResponsive;
    } else {
      this.searchVerifyBanner = false;
      return false;
    }
  }
}

export default BannerStore;
