import BaseModel from './BaseModel';
import AlternativeProductModel from './AlternativeProductModel';

class ProductLookModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.productUuid = data.productUuid;
    this.productName = data.productName;
    this.productsAlternative = data.productsAlternative
      ? data.productsAlternative.map(
          (props) => new AlternativeProductModel(props)
        )
      : [];
  }
}

export default ProductLookModel;
