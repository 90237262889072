/* eslint-disable class-methods-use-this */
import { extendObservable } from 'mobx';

import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';
import ItemMenuModel from '~/models/ItemMenuModel';

// const ManagerPropertyContainer = lazy(() => import('../containers/ManagerPropertyContainer'));

class MenuStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, {
      menus: [],
      dashboard: new ItemMenuModel(this.dashboardData),
      itemMenu: undefined,
      subItemMenu: undefined,
    });
  }

  /** Carrega menu */
  loadMenu() {
    const showMenuStoom = this.rootStore.authStore.isStoomAdmin;
    let items = this.itemsMenu;
    if (!showMenuStoom) {
      items = this.itemsMenu.filter((item) => item.title !== 'Stoom');
    }
    this.menus = items.map((item) => new ItemMenuModel(item));
    if (StorageUtil.getItem(KEYS.PATH_MENU)) {
      this.setCurrentMenu(StorageUtil.getItem(KEYS.PATH_MENU));
    }
  }

  /** Altera o menu atual. */
  setCurrentMenu(path) {
    StorageUtil.setItem(KEYS.PATH_MENU, path);
    this.itemMenu = this.menus.find((item) => {
      const sub = item.subItems.find((subItem) => subItem.path === path);
      if (sub) {
        this.subItemMenu = sub;
        return item;
      }
      return null;
    });
  }

  /** Retorna o titulo do menu selecionado */
  get title() {
    if (!this.itemMenu) return this.dashboard.title;
    return this.itemMenu && this.itemMenu.title;
  }

  /** Retorna o subtitulo do submenu */
  get subtitle() {
    if (!this.subItemMenu) return this.dashboard.subtitle;
    return this.subItemMenu && this.subItemMenu.title;
  }

  /** Retorna se dashboard está visible */
  get dashboardVisible() {
    return !this.itemMenu;
  }

  /** Dados da dashboard */
  get dashboardData() {
    return {
      title: 'Painel de Controle',
      subtitle: 'Um painel completo para controlar seu site',
      icon: 'fas fa-clipboard-list',
      subItems: [],
    };
  }

  /** Items de menu */
  get itemsMenu() {
    return Object.values({
      administrators: {
        title: 'Administradores',
        subtitle: 'Área de administradores',
        icon: 'fas fa-user-shield',
        subItems: [
          {
            path: '/cadastrar-administrador',
            title: 'Cadastro de Administradores',
          },
          { path: '/administradores', title: 'Gerenciar Administradores' },
        ],
      },
      usuarios: {
        title: 'Clientes',
        icon: 'fas fa-users',
        subItems: [
          { path: '/cadastrar-clientes', title: 'Novo Cliente' },
          { path: '/clientes', title: 'Gerenciamento de Clientes' },
          {
            path: '/cadastro-grupo-de-clientes',
            title: 'Novo grupo de clientes',
          },
          {
            path: '/grupos-de-clientes',
            title: 'Gerenciamento de grupo de clientes',
          },
        ],
      },
      vendedores: {
        title: 'Vendedores',
        icon: 'fas fa-chalkboard-teacher',
        subItems: [
          { path: '/cadastrar-vendedor', title: 'Novo Vendedor' },
          { path: '/vendedores', title: 'Gerenciamento de Vendedores' },
        ],
      },
      banners: {
        title: 'Banners',
        icon: 'fas fa-images',
        subItems: [
          { path: '/cadastrar-banner', title: 'Novo Banner' },
          { path: '/banners', title: 'Gerenciamento de Banners' },
        ],
      },
      categorias: {
        title: 'Categorias',
        icon: 'fas fa-th',
        subItems: [
          { path: '/cadastrar-categoria', title: 'Nova Categoria' },
          { path: '/categorias', title: 'Gerenciamento de Categorias' },
        ],
      },
      /*   'Fornecedor': {
                title: 'Fornecedor',
                subtitle: '',
                icon: 'fas fa-industry',
                subItems: [
                        { path: "/cadastrar-fabricante", title: "Novo Fornecedor" },
                        { path: "/fabricantes", title: 'Gerenciamento de Fornecedors'},
                ]
            }, */
      /*
      Fornecedor: {
        title: 'Fornecedor',
        subtitle: '',
        icon: 'fas fa-parachute-box',
        subItems: [
          { path: '/cadastrar-fornecedor', title: 'Novo Fornecedor' },
          { path: '/fornecedores', title: 'Gerenciamento de Fornecedores' },
        ],
      }, */
      /*
      Montadoras: {
        title: 'Montadora',
        subtitle: '',
        icon: 'fas fa-industry',
        subItems: [
          { path: '/cadastrar-montadora', title: 'Novo Montadora' },
          { path: '/montadoras', title: 'Gerenciamento de Montadoras' },
          { path: '/cadastrar-aplicacao', title: 'Nova Aplicação' },
          { path: '/aplicacoes', title: 'Gerenciamento de Aplicações' },
        ],
      }, */
      pedidos: {
        title: 'Pedidos',
        subtitle: '',
        icon: 'fas fa-clipboard-list',
        subItems: [{ path: '/pedidos', title: 'Gerenciamento de pedidos' }],
      },
      /* preOrders: {
        title: 'Orçamentos',
        subtitle: '',
        icon: 'fas fa-clipboard-list',
        subItems: [
          { path: '/orcamentos-pendentes', title: 'Pedentes' },
          { path: '/orcamentos-analise', title: 'Aguardando Análise' },
          { path: '/orcamentos-liberado', title: 'Orçamentos Liberados' },
          { path: '/orcamentos-analise-frete', title: 'Análise de frete' },
          { path: '/orcamentos', title: 'Todos orçamentos' },
        ],
      }, */
      produtos: {
        title: 'Produtos',
        icon: 'fab fa-product-hunt',
        subItems: [
          { path: '/cadastrar-produtos', title: 'Novo Produto' },
          { path: '/produtos', title: 'Gerenciamento de Produtos' },
          { path: '/estoques', title: 'Gerenciamento de Estoque' },
        ],
      },
      looks: {
        title: 'Looks',
        icon: 'fa fa-cubes',
        subItems: [
          { path: '/cadastrar-look', title: 'Novo Look' },
          { path: '/looks', title: 'Gerenciamento de Looks' },
        ],
      },
      cupons: {
        title: 'Cupons',
        icon: 'fas fa-tags',
        subItems: [
          { path: '/cadastrar-cupom', title: 'Novo Cupom' },
          { path: '/cupons', title: 'Gerenciamento de Cupons' },
        ],
      },
      campanha: {
        title: 'Campanhas',
        icon: 'fas fa-boxes',
        subItems: [
          { path: '/cadastrar-campanha', title: 'Nova Campanha' },
          { path: '/campanhas', title: 'Gerenciamento de Campanhas' },
        ],
      },
      vendas: {
        title: 'Relatórios',
        subtitle: 'Um painel completo para controlar seu site',
        icon: 'fas fa-clone',
        subItems: [{ path: '/vendas/general', title: 'Vendas geral' }],
      },
      propriedades: {
        title: 'Atributos',
        icon: 'fas fa-gift',
        subItems: [
          { path: '/cadastrar-propriedade', title: 'Novo Tipo de Atributo' },
          { path: '/propriedades', title: 'Gerenciamento de Atributos' },
        ],
      },
      globalconfig: {
        title: 'Configurações da loja',
        icon: 'fas fa-cog',
        subItems: [{ path: '/config-store', title: 'Configurações da loja' }],
      },
      branches: {
        title: 'Filiais',
        icon: 'fas fa-store',
        subItems: [
          { path: '/new-branch', title: 'Nova Filial' },
          { path: '/branches', title: 'Gerenciamento de Filiais' },
        ],
      },
      /*   logistica: {
        title: 'Logistica',
        subtitle: '',
        icon: 'fas fa-truck',
        subItems: [
          {
            path: '/cadastrar-transportadora',
            title: 'Nova transportadora',
          },
          {
            path: '/transportadoras',
            title: 'Gerenciar transportadoras',
          },
          {
            path: '/cadastrar-faixa-frete',
            title: 'Nova faixa de Frete',
          },
          {
            path: '/faixas-frete',
            title: 'Gerenciar Faixas de Frete',
          },
        ],
      }, */
      /*   'institucional': {
                title: 'Institucionais',
                subtitle: '',
                icon: 'fas fa-university',
                subItems: [
                    { path: "/cadastrar-institucional", title: "Cadastrar Institucional" },
                    { path: "/gerenciar-institucionais", title: "Gerenciar Institucionais" }
                ]
            }, */
      stoom: {
        title: 'Stoom',
        subtitle: 'Área Staff',
        icon: 'fas fa-hammer',
        subItems: [
          { path: '/cadastrar-merchant', title: 'Cadastro de Merchant' },
          { path: '/merchants', title: 'Gerenciar Merchants' },
        ],
      },
      newsLetter: {
        title: 'Newsletter',
        icon: 'fas fa-envelope',
        subItems: [
          { path: '/newsletter', title: 'Nova Newsletter' },
          { path: '/newsletters', title: 'Gerenciar Newsletter' },
        ],
      },

      /*  'interfaces': {
                title: 'Interfaces',
                subtitle: '',
                icon: 'fas fa-paint-brush',
                subItems: []
            } */
    });
  }
}

export default MenuStore;
