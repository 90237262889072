import { extendObservable } from 'mobx';

import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import BranchModel from '~/models/avenida/BranchModel';
import BranchAPI from '../services/BranchAPI';

const initValues = {
  loading: false,
  totalPages: 0,
  page: 0,
  size: 10,
  branch: undefined,
  branches: [],
  sort: 'name',
};

class BranchStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
    this.toastHelper = new ToastHelper();
  }

  /**Cadastra uma nova filial */
  async createBranch(data) {
    this.loading = true;
    const branch = data;
    const response = await BranchAPI.create(branch);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Filial cadastrada com sucesso'
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Atualiza os dados de uma filial */
  async updateBranch(uuid, data) {
    this.loading = true;
    const branch = new BranchModel(data);
    const response = await BranchAPI.update(uuid, branch);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Filial atualizada com sucesso'
      );
      this.branch = new BranchModel(response);
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Busca as filiais com paginação */
  async getAll() {
    this.loading = true;
    const params = { pageSize: this.size, page: this.page, sort: this.sort };
    const response = await BranchAPI.getAll(params);
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.branches = response.content.map((branch) => new BranchModel(branch));
      this.totalPages = response.totalPages;
    }
    this.loading = false;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;
    sort && (this.sort = sort);
    await this.getAll();
    return true;
  }

  /**Busca uma filial pelo seu uuid */
  async getBranchByUuid(uuid) {
    this.loading = true;
    const response = await BranchAPI.getBranchByUuid(uuid);
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.branch = new BranchModel(response);
      this.loading = false;
      return this.branch;
    }
  }
}

export default BranchStore;
