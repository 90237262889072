import React, { useState, useEffect } from 'react';

/** 
  * @Name: SearchComponent
  * @Data: 2019
  * @Desc: Componente de Busca
  * @props:
  *        
  */

const SearchComponent = (props) => {

    // Recebe as propriedades
    const { placeholder = 'O que você procura?', changeSearch, value = '' } = props;
    const [inputValue, setValue] = useState('');

    useEffect(() => {
        setValue(value);
    }, [value]);

    const handleEnter = (event) => {
        event.key === 'Enter' && changeSearch(event.target.value)
    }

    return (
        <React.Fragment>
            <div className='search-area'>
                {/* <div className="ic-input-search"><i className="fas fa-search"></i></div> */}
                <input className="form-control form-search-special"
                    onKeyPress={(e) => handleEnter(e)}
                    onChange={e => setValue(e.target.value)}
                    value={inputValue}
                    type="text"
                    placeholder={placeholder}
                >
                </input>
            </div>


        </React.Fragment>
    )
}

export default SearchComponent;