import BaseModel from './BaseModel';

class MerchantModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.name = data.name;
    this.nickname = data.nickname;
    this.entity = data.entity;
    this.document = data.document;
    this.description = data.description;
    this.shortDescription = data.shortDescription;
    this.domain = data.domain;
    this.mobile = data.mobile;
  }
}

export default MerchantModel;
