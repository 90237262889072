import { extendObservable, decorate, action } from 'mobx';

import PropertiesAPI from '../services/PropertiesAPI';
import UploadAPI from '../services/UploadAPI';

import ProductPropertyModel from '~/models/ProductPropertyModel';

import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import { removeItemList, currencyToNumber } from '~/helpers/utils/Functions';
import FileModel from '~/models/FileModel';
import PropertyClassAvenidaModel from '~/models/avenida/PropertyClassModel';

/**Valores inicias de variaveis observadas */
const initValues = {
  loading: false,
  property: undefined,
  clazz: undefined,
  group: undefined,
  className: undefined,
  required: false,
  properties: [],
  classes: [],
  groups: [],
  classesSelecteds: [],
  productProperties: [],
  attributes: [],
  name: null,
  colors: [],
  color: undefined,
  sizes: [],
  productSize: undefined,
  units: [],
  unit: undefined,
};
class PropertiesStore {
  totalPages = 0;
  page = 0;
  size = 10;
  last = false;

  totalPagesColors = 0;
  pageColors = 0;
  sizeColors = 10;
  lastColors = false;

  totalPagesSizes = 0;
  pageSizes = 0;
  sizeSizes = 10;
  lastSizes = false;

  totalPagesUnits = 0;
  pageUnits = 0;
  sizeUnits = 10;
  lastUnits = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
    this.toastHelper = new ToastHelper();
  }

  reset() {
    this.property = undefined;
    this.clazz = undefined;
    this.properties = [];
    this.classes = [];
    this.classesSelecteds = [];
  }

  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Retorna o merchant do usuário atual */
  get merchant() {
    //return this.rootStore.usersStore.user.merchant;
    this.rootStore.customerTypeStore.getDefaultCustomerType();
  }

  handleChangeClassName(className) {
    this.className = className;
  }
  handleRequiredAttr(required) {
    this.required = required;
  }

  /**Atualiza propriedade do tipoe valoriação */
  updateTypePropertyProp(prop, value) {
    const type = this.clazz
      ? this.clazz
      : new PropertyClassAvenidaModel({ merchant: this.merchant });
    type[prop] = value;

    this.clazz = new PropertyClassAvenidaModel(type);
  }

  /**Atualiza propertyClass */
  async updatePropertyClass(params) {
    this.loading = true;

    params['@class'] =
      'br.com.stoom.avenida.model.dto.AvenidaPropertyClassUpdateDTO';

    const response = await PropertiesAPI.updatePropertyClass(
      this.clazz.uuid,
      params
    );
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    else {
      this.clazz = new PropertyClassAvenidaModel(response);
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Alteração realizada com sucesso!'
      );
    }
    this.loading = false;
    return response;
  }

  /**Delete class */
  async deleteProperty(group, property) {
    this.loading = true;
    const response = await PropertiesAPI.deleteProperty(
      this.clazz.uuid,
      group.uuid,
      property.uuid
    );
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Variação deletada com sucesso!'
      );
      removeItemList(group.properties, property);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Delete class */
  async deleteClass(uuid) {
    this.loading = true;
    const response = await PropertiesAPI.deleteClass(uuid);
    if (!response.error) {
      const cls = this.classes.find((cls) => cls.uuid === uuid);
      removeItemList(this.classes, cls);
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Atributo excluído com sucesso!'
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca propertyClass por uuid */
  async getClass(uuid) {
    this.loading = true;
    const response = await PropertiesAPI.getPropertyClass(uuid);
    if (!response.error) this.clazz = new PropertyClassAvenidaModel(response);
    else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca as opções proveniente do atributo especifico */
  async getAllOptions(uuid) {
    this.loading = true;
    const response = await PropertiesAPI.getAllOptions(uuid);
    if (!response.error) this.attributes = response;
    else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Salva nova propriedade(variação) de produto */
  async createProperty(
    propertyValue,
    displayString = this.property.displayString,
    withImage
  ) {
    if (withImage && !this.property) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Imagem inválida!');
      return { error: 'Imagem' };
    }
    this.loading = true;
    const property = new ProductPropertyModel({
      propertyValue,
      displayString,
      propertyClass: this.clazz,
    });
    const response = await PropertiesAPI.createProperty(this.clazz.uui, [
      property,
    ]);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Propriedade cadastrada com sucesso.'
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Salva novo Tipo propriedade(variação) do produto */
  async createClassProperty() {
    if (!this.clazz.isValid) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Formulário inválido!');
      return { error: 'form inválido' };
    }
    this.loading = true;
    const data = JSON.stringify(this.clazz);
    const response = await PropertiesAPI.createClassProperty(data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Tipo de propriedade salvo com sucesso'
      );
      this.clazz = new PropertyClassAvenidaModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Salva novo atributo no sistema */
  async createAttribute() {
    if (!this.className) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Preencha todos os campos');
      return false;
    }
    this.loading = true;

    const merchant = await this.rootStore.merchantStore.getList();

    if (merchant.length === 0) {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Erro ao buscar o comerciante'
      );
      this.loading = false;
      return false;
    }

    const classAttribute = {
      className: this.className,
      required: this.required,
      viewType: this.clazz.displayString,
      merchant: {
        uuid: merchant[0].uuid,
      },
    };

    classAttribute['@class'] =
      'br.com.stoom.avenida.model.persistence.AvenidaPropertyClass';

    const data = JSON.stringify(classAttribute);

    let response = await PropertiesAPI.createClassProperty(data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Atributo criado com sucesso'
      );
      this.loading = false;
      return response;
    } else {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Não foi cadastrar o atributo'
      );
      this.loading = false;
      return false;
    }
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, search) {
    this.page = numPage;
    this.size = size;
    this.name = search;
    await this.getListClasses();
    return true;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPageColors(numPage, size = 10, search) {
    this.pageColors = numPage;
    this.sizeColors = size;
    await this.getListColors();
    return true;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPageSizes(numPage, size = 10, search) {
    this.pageSizes = numPage;
    this.sizeSizes = size;
    await this.getListSizes();
    return true;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPageUnits(numPage, size = 10, search) {
    this.pageUnits = numPage;
    this.sizeUnits = size;
    await this.getListUnits();
    return true;
  }

  /**Busca todos os produtos */
  async getListClasses() {
    this.loading = true;
    const params = { name: this.name ?? '', size: this.size, page: this.page };
    const response = await PropertiesAPI.listClasses(params);
    if (!response.error) {
      this.classes = await response.content.map(
        (cls) => new PropertyClassAvenidaModel(cls)
      );

      this.totalPages = response.totalPages;
      this.page = response.number;
      this.last = response.last;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Remove class selecionado */
  removeClass(classUuid) {
    const cls = this.classesSelecteds.find((cls) => cls.uuid === classUuid);
    removeItemList(this.classesSelecteds, cls);
  }

  /**Atualiza o pricing */
  setPricing(classUuid, groupUuid, pricing) {
    const pricingNumber = currencyToNumber(pricing);
    const cls = this.classesSelecteds.find((cls) => cls.uuid === classUuid);
    cls.groups.map((propertyPricing) => {
      if (propertyPricing.group.uuid === groupUuid) {
        propertyPricing.priceIncrease = pricingNumber;
      }
      return propertyPricing;
    });
  }

  /**Envia arquivo de proposta que foi anexo */
  async sendFile(property) {
    this.loading = true;
    const file = this.property.fileProperty;
    let promise = file.uuid
      ? this.updateFile(file)
      : this.sendNewFile(property, file);
    return await promise;
  }

  /**Atualiza imagem da categoria */
  async updateFile(file) {
    this.notificationStore.addItemUpload(file);
    const response = await UploadAPI.updateFile(file);
    this.loading = false;
    if (response.error)
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Falha ao atualizar imagens.'
      );
    setTimeout(() => {
      this.notificationStore.removeItemUpload(file);
    }, 3300);
    return response;
  }

  /**Envia imagem da categoria. */
  async sendNewFile(property, file) {
    this.notificationStore.addItemUpload(file);
    const response = await UploadAPI.uploadProperty(property.uuid, file);
    this.loading = false;
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Falha anexar arquivos.');
    setTimeout(() => {
      this.notificationStore.removeItemUpload(file);
    }, 3300);
    return response;
  }

  /**Adiciona imagem ao produto */
  handleImages(files) {
    /**se files for vazio apenas atualiza o files da propriedade */
    if (files.length === 0 && this.property) {
      this.property.file = undefined;
    } else {
      /**Altera arquivo. */
      const property = this.property
        ? this.property
        : new ProductPropertyModel();
      const file = files[0];
      if (!property.file) {
        property.file = new FileModel({
          file,
          ...file,
          metaTags: ['property'],
        });
        property.displayString = property.fileProperty.name;
      } else this.classes = property.file.updateFile(file);
      this.property = property;
    }
  }

  /**Retorna lista de class para o select */
  getClassesSelect() {
    return this.classes
      ? this.classes.map((cls) => ({ value: cls.uuid, label: cls.className }))
      : [];
  }

  /**Adiciona atributo no array do grid/tabela */
  addAttribute(attribute) {
    this.attributes.push(attribute);
  }

  /**Remove a opção da propriedade selecionada */
  async removeAttribute(element) {
    this.loading = true;

    let response = await PropertiesAPI.deleteProperty(element.uuid);

    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Opção deletada com sucesso');
      const attributes = this.attributes.filter((e) => e.uuid !== element.uuid);
      this.attributes = attributes;
    } else
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Não foi deletar a opção do atributo'
      );
    this.loading = false;
    return response;
  }

  async saveAttribute(attribute) {
    this.loading = true;

    const data = JSON.stringify(attribute);
    let response = await PropertiesAPI.saveAttribute(data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Atributo atualizado com sucesso'
      );
      this.addAttribute(response.data);
    } else
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Não foi possível atualizar o atributo'
      );
    this.loading = false;
    return response;
  }

  /**Cadastra uma cor*/
  async createColor(colorData) {
    this.loading = true;
    const response = await PropertiesAPI.createColor(colorData);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Cor cadastrada com sucesso');
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Busca todas as cores */
  async getListColors() {
    this.loading = true;
    const params = {
      name: this.name ?? '',
      size: this.sizeColors,
      page: this.pageColors,
    };
    const response = await PropertiesAPI.listColors(params);
    if (!response.error) {
      this.colors = response.content;
      this.totalPagesColors = response.totalPages;
      this.pageColors = response.number;
      this.lastColors = response.last;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca uma cor a partir do seu uuid */
  async getColorByUuid(uuid) {
    this.loading = true;
    const response = await PropertiesAPI.getColorByUuid(uuid);
    if (!response.error) {
      this.color = response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Atualiza uma cor*/
  async updateColor(uuid, colorData) {
    this.loading = true;
    const response = await PropertiesAPI.updateColor(uuid, colorData);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Cor atualizada com sucesso');
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Deleta uma cor*/
  async deleteColor(uuid) {
    this.loading = true;
    const response = await PropertiesAPI.deleteColor(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Cor removida com sucesso');
      this.getListColors();
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Cadastra um tamanho*/
  async createSize(sizeData) {
    this.loading = true;
    const response = await PropertiesAPI.createSize(sizeData);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Tamanho cadastrado com sucesso'
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Busca todos os tamanhos */
  async getListSizes() {
    this.loading = true;
    const params = {
      name: this.name ?? '',
      size: this.sizeSizes,
      page: this.pageSizes,
    };
    const response = await PropertiesAPI.listSizes(params);
    if (!response.error) {
      this.sizes = response.content;
      this.totalPagesSizes = response.totalPages;
      this.pageSizes = response.number;
      this.lastSizes = response.last;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca um tamanho a partir do seu uuid */
  async getSizeByUuid(uuid) {
    this.loading = true;
    const response = await PropertiesAPI.getSizeByUuid(uuid);
    if (!response.error) {
      this.productSize = response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Atualiza um tamanho*/
  async updateSize(uuid, sizeData) {
    this.loading = true;
    const response = await PropertiesAPI.updateSize(uuid, sizeData);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Tamanho atualizado com sucesso'
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Deleta um tamanho*/
  async deleteSize(uuid) {
    this.loading = true;
    const response = await PropertiesAPI.deleteSize(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Tamanho removido com sucesso');
      this.getListSizes();
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Cadastra uma unidade de medida*/
  async createUnit(unitData) {
    this.loading = true;
    const response = await PropertiesAPI.createUnit(unitData);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Unidade de medida cadastrada com sucesso'
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Busca todas as unidades de medida */
  async getListUnits() {
    this.loading = true;
    const params = {
      name: this.name ?? '',
      size: this.sizeUnits,
      page: this.pageUnits,
    };
    const response = await PropertiesAPI.listUnits(params);
    if (!response.error) {
      this.units = response.content;
      this.totalPagesUnits = response.totalPages;
      this.pageUnits = response.number;
      this.lastUnits = response.last;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca uma unidade de medida a partir do seu uuid */
  async getUnitByUuid(uuid) {
    this.loading = true;
    const response = await PropertiesAPI.getUnitByUuid(uuid);
    if (!response.error) {
      this.unit = response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Atualiza uma unidade de medida*/
  async updateUnit(uuid, unitData) {
    this.loading = true;
    const response = await PropertiesAPI.updateUnit(uuid, unitData);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Unidade de medida atualizada com sucesso'
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Deleta uma unidade de medida*/
  async deleteUnit(uuid) {
    this.loading = true;
    const response = await PropertiesAPI.deleteUnit(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Unidade de medida removida com sucesso');
      this.getListUnits();
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }
}

export default PropertiesStore;

decorate(PropertiesStore, {
  get: action,
  getClass: action,
  getGroupsForSupplier: action,
});
