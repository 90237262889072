import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import LoadingComponent from '~/components/LoadingComponent/LoadingComponent';

import LookCreateContainer from '~/containers/LookCreateContainer';
import LookUpdateContainer from '~/containers/LookUpdateContainer';
import ManagerLookContainer from '~/containers/ManagerLookContainer';
import ConfigStore from '~/containers/ConfigStore/ConfigStore';
import Stocks from '~/containers/Stocks/Stocks';

//Imports apenas importados caso forem requisitados pela aplicação.
const HomePage = lazy(() => import('~/pages/HomePage/HomePage'));
const NotFound = lazy(() => import('~/pages/NotFound/NotFound'));
const LoginCenterPage = lazy(() =>
  import('~/pages/LoginCenterPage/LoginCenterPage')
);

const ManagerSalesPersonContainer = lazy(() =>
  import('~/containers/ManagerSalesPersonContainer')
);

const SalesPersonContainer = lazy(() =>
  import('~/containers/SalesPersonContainer')
);

const UpdateCSVContainer = lazy(() =>
  import('~/containers/UpdateCSVContainer')
);

//Componenentes de acordo ao item de menu
const DashboardsContainer = lazy(() =>
  import('~/containers/DashboardsContainer')
);
const CampaignContainer = lazy(() => import('~/containers/CampaignContainer'));
const ProductCreateContainer = lazy(() =>
  import('~/containers/ProductCreateContainer')
);
const ProductUpdateContainer = lazy(() =>
  import('~/containers/ProductUpdateContainer')
);
const CustomerContainer = lazy(() => import('~/containers/CustomerContainer'));
const AdministratorContainer = lazy(() =>
  import('~/containers/AccountContainer')
);
const MerchantContainer = lazy(() => import('~/containers/MerchantContainer'));
const SupplierContainer = lazy(() => import('~/containers/SupplierContainer'));
const ApplicationContainer = lazy(() =>
  import('~/containers/ApplicationContainer')
);
const AutomakerContainer = lazy(() =>
  import('~/containers/AutomakerContainer')
);
const PropertyGroupContainer = lazy(() =>
  import('~/containers/PropertyGroupContainer')
);
const ColorContainer = lazy(() => import('~/containers/ColorContainer'));
const SizeContainer = lazy(() => import('~/containers/SizeContainer'));
const UnitContainer = lazy(() => import('~/containers/UnitContainer'));
const PropertyClassContainer = lazy(() =>
  import('~/containers/PropertyClassContainer')
);
const CategoryContainer = lazy(() => import('~/containers/CategoryContainer'));
const PreOrderContainer = lazy(() => import('~/containers/PreOrderContainer'));
const ShippingServiceContainer = lazy(() =>
  import('~/containers/ShippingServiceContainer')
);

const ManagerCustomersGroupContainer = lazy(() =>
  import('~/containers/ManagerCustomersGroupContainer')
);
const CustomersGroupContainer = lazy(() =>
  import('~/containers/CustomersGroupContainer')
);
const ShippingClassContainer = lazy(() =>
  import('~/containers/ShippingClassContainer')
);
const HomeStoreContainer = lazy(() =>
  import('~/containers/HomeStoreContainer')
);
const InstitutionalContainer = lazy(() =>
  import('~/containers/InstitutionalContainer')
);
const CouponContainer = lazy(() => import('~/containers/CouponContainer'));
const ManagerPreOrderContainer = lazy(() =>
  import('~/containers/ManagerPreOrderContainer')
);
const ManagerProductsContainer = lazy(() =>
  import('~/containers/ManagerProductContainer')
);
const ManagerCategoryContainer = lazy(() =>
  import('~/containers/ManagerCategoryContainer')
);
const ManagerSupplierContainer = lazy(() =>
  import('~/containers/ManagerSupplierContainer')
);
const ManagerApplicationContainer = lazy(() =>
  import('~/containers/ManagerApplicationContainer')
);
const ManagerAutomakerContainer = lazy(() =>
  import('~/containers/ManagerAutomakerContainer')
);
const ManagerAccountContainer = lazy(() =>
  import('~/containers/ManagerAccountContainer')
);
const ManagerMerchantContainer = lazy(() =>
  import('~/containers/ManagerMerchantContainer')
);
const ManagerClientContainer = lazy(() =>
  import('~/containers/ManagerCustomerContainer')
);
const ManagerPropertiesContainer = lazy(() =>
  import('~/containers/ManagerPropertiesContainer')
);
const ManagerOrderContainer = lazy(() =>
  import('~/containers/ManagerOrderContainer')
);
const ManagerShippingServiceContainer = lazy(() =>
  import('~/containers/ManagerShippingServiceContainer')
);
const ManagerShippingClassContainer = lazy(() =>
  import('~/containers/ManagerShippingClassContainer')
);
const ManagerZipCodeRangeContainer = lazy(() =>
  import('~/containers/ManagerZipCodeRangerContainer')
);
const ZipCodeRangeContainer = lazy(() =>
  import('~/containers/ZipCodeRangerContainer')
);
const ManagerInstitutionContainer = lazy(() =>
  import('~/containers/ManagerInstitutionContainer')
);
const ManagerBannerContainer = lazy(() =>
  import('~/containers/ManagerBannerContainer')
);
const ManagerCouponContainer = lazy(() =>
  import('~/containers/ManagerCouponContainer')
);
const OrderDetailContainer = lazy(() =>
  import('~/containers/OrderDetailContainer')
);

const KitContainer = lazy(() => import('~/containers/KitContainer'));

const ComparativeContainer = lazy(() =>
  import('~/containers/ComparativeContainer')
);
const KitCreateContainer = lazy(() =>
  import('~/containers/KitCreateContainer')
);
const KitUpdateContainer = lazy(() =>
  import('~/containers/KitUpdateContainer')
);

const BannerCreateContainer = lazy(() =>
  import('~/containers/BannerCreateContainer')
);
const BannerUpdateContainer = lazy(() =>
  import('~/containers/BannerUpdateContainer')
);

const ManagerKitContainer = lazy(() =>
  import('~/containers/ManagerKitContainer')
);

const ManagerComparativeContainer = lazy(() =>
  import('~/containers/ManagerComparativeContainer')
);

const ConnectComparativeContainer = lazy(() =>
  import('~/containers/ConnectComparativeContainer')
);

const ManagerAssociateContainer = lazy(() =>
  import('~/containers/ManagerAssociateContainer')
);

const UpdateComparativeContainer = lazy(() =>
  import('~/containers/ComparativeUpdateContainer')
);

const BrandCreateContainer = lazy(() =>
  import('~/containers/BrandCreateContainer')
);

const BrandUpdateContainer = lazy(() =>
  import('~/containers/BrandUpdateContainer')
);

const ManagerBrandContainer = lazy(() =>
  import('~/containers/ManagerBrandContainer')
);

const NewsLetterContainer = lazy(() =>
  import('~/containers/NewsLetterContainer')
);

const NewsLetterEditorContainer = lazy(() =>
  import('~/containers/NewsLetterEditorContainer')
);

const ManagerSalesContainer = lazy(() =>
  import('~/containers/ManagerSalesContainer')
);

const BranchCreateContainer = lazy(() =>
  import('~/containers/BranchCreateContainer')
);

const BranchManagerContainer = lazy(() =>
  import('~/containers/BranchManagerContainer')
);

const ManagerCampaignContainer = lazy(() =>
  import('~/containers/ManagerCampaignContainer')
);

//Rotas globais de nossa aplicação manager.
export default inject(({ authStore }) => ({ authStore }))(
  withRouter(
    observer((props) => {
      //função logout
      const logout = () => {
        props.authStore.logout().then(() => props.history.push('/'));
      };

      return (
        <Suspense fallback={<LoadingComponent />}>
          <Switch>
            <Route exact path="/error-page" component={NotFound} />
            <Route exact path="/login" render={() => <Redirect to="/" />} />
            <Route exact path="/logout" render={() => logout()} />
            <PrivateRoute
              path="/"
              component={HomePage}
              isAuthenticated={props.authStore.isAuthenticated}
            />
            <Redirect to="/error-page" />
          </Switch>
        </Suspense>
      );
    })
  )
);

export const HomeRoutes = () => (
  <Switch>
    <Route exact path="/config-store" component={ConfigStore} />
    <Route exact path="/estoques" component={Stocks} />
    <Route exact path="/looks" component={ManagerLookContainer} />
    <Route exact path="/cadastrar-look" component={LookCreateContainer} />
    <Route
      exact
      path="/grupos-de-clientes"
      component={ManagerCustomersGroupContainer}
    />
    <Route
      exact
      path="/cadastro-grupo-de-clientes"
      component={CustomersGroupContainer}
    />
    <Route
      exact
      path="/editar-grupo-de-clientes/:customerGroupUuid"
      component={CustomersGroupContainer}
    />
    <Route
      exact
      path="/editar-look/:uuidLook"
      component={LookUpdateContainer}
    />

    <Route exact path="/update-csv/:type" component={UpdateCSVContainer} />

    <Route exact path="/cadastrar-clientes" component={CustomerContainer} />
    <Route
      exact
      path="/cadastrar-produtos"
      component={ProductCreateContainer}
    />
    <Route exact path="/cadastrar-marcas" component={BrandCreateContainer} />
    <Route
      exact
      path="/cadastrar-propriedade"
      component={PropertyClassContainer}
    />
    <Route exact path="/cadastrar-categoria" component={CategoryContainer} />
    <Route exact path="/cadastrar-fornecedor" component={SupplierContainer} />
    <Route exact path="/cadastrar-montadora" component={AutomakerContainer} />
    <Route exact path="/cadastrar-aplicacao" component={ApplicationContainer} />
    <Route exact path="/cadastrar-merchant" component={MerchantContainer} />
    <Route exact path="/cadastrar-campanha" component={CampaignContainer} />
    <Route exact path="/cadastrar-vendedor" component={SalesPersonContainer} />
    <Route
      exact
      path="/cadastrar-comparativo"
      component={ComparativeContainer}
    />
    <Route exact path="/comparativos" component={ManagerComparativeContainer} />
    <Route
      exact
      path="/gerenciar-associados"
      component={ManagerAssociateContainer}
    />
    <Route exact path="/gerenciar-home-store" component={HomeStoreContainer} />
    <Route exact path="/cadastrar-kit" component={KitCreateContainer} />
    <Route exact path="/cadastrar-banner" component={BannerCreateContainer} />
    <Route exact path="/cadastrar-cupom" component={CouponContainer} />
    <Route exact path="/cadastrar-cupom/:id" component={CouponContainer} />
    <Route
      exact
      path="/cadastrar-administrador"
      component={AdministratorContainer}
    />
    <Route
      exact
      path="/cadastrar-servico"
      component={ShippingServiceContainer}
    />
    <Route
      exact
      path="/cadastrar-transportadora"
      component={ShippingClassContainer}
    />
    <Route
      exact
      path="/cadastrar-forma-envio"
      component={ShippingServiceContainer}
    />
    <Route
      exact
      path="/cadastrar-institucional"
      component={InstitutionalContainer}
    />

    <Route
      exact
      path="/editar-cliente/:uuidCustomer"
      component={CustomerContainer}
    />
    <Route
      exact
      path="/editar-produto/:uuidProduct"
      component={ProductUpdateContainer}
    />
    <Route
      exact
      path="/editar-propriedade/:classUuid"
      component={PropertyGroupContainer}
    />
    <Route exact path="/editar-cor/:colorUuid" component={ColorContainer} />
    <Route exact path="/editar-tamanho/:sizeUuid" component={SizeContainer} />
    <Route exact path="/editar-unidade/:unitUuid" component={UnitContainer} />
    <Route
      exact
      path="/editar-campanha/:campaignUuid"
      component={CampaignContainer}
    />
    <Route
      exact
      path="/editar-vendedor/:salesPersonUuid"
      component={SalesPersonContainer}
    />
    <Route
      exact
      path="/editar-categoria/:uuidCategory"
      component={CategoryContainer}
    />
    <Route
      exact
      path="/editar-fornecedor/:uuidSupplier"
      component={SupplierContainer}
    />
    <Route
      exact
      path="/editar-montadora/:uuidAutomaker"
      component={AutomakerContainer}
    />
    <Route
      exact
      path="/editar-aplicacao/:uuidApplication"
      component={ApplicationContainer}
    />
    <Route
      exact
      path="/editar-merchant/:uuidMerchant"
      component={MerchantContainer}
    />
    <Route
      exact
      path="/editar-administrador/:uuidAdministrator"
      component={AdministratorContainer}
    />
    <Route exact path="/editar-kit/:uuidKit" component={KitUpdateContainer} />
    <Route exact path="/editar-cupom/:uuidCoupon" component={CouponContainer} />
    <Route
      exact
      path="/editar-banner/:uuidBanner"
      component={BannerUpdateContainer}
    />
    <Route
      exact
      path="/editar-comparativo/:uuidComparative"
      component={UpdateComparativeContainer}
    />
    <Route
      exact
      path="/editar-associados/:uuidComparative"
      component={ConnectComparativeContainer}
    />
    <Route
      exact
      path="/detalhes-pedido/:uuidOrder/user/:uuidUser"
      component={OrderDetailContainer}
    />
    <Route
      exact
      path="/editar-servico/:uuidShipping"
      component={ShippingServiceContainer}
    />
    <Route
      exact
      path="/editar-transportadora/:uuidShipping"
      component={ShippingClassContainer}
    />
    <Route
      exact
      path="/detalhes-orcamento/:uuidPreOrder"
      component={PreOrderContainer}
    />
    <Route
      exact
      path="/editar-institucional/:uuidInstitutional"
      component={InstitutionalContainer}
    />

    <Route
      exact
      path="/editar-marca/:uuidBrand"
      component={BrandUpdateContainer}
    />

    <Route exact path="/clientes" component={ManagerClientContainer} />
    <Route exact path="/produtos" component={ManagerProductsContainer} />
    <Route exact path="/propriedades" component={ManagerPropertiesContainer} />
    <Route exact path="/categorias" component={ManagerCategoryContainer} />
    <Route exact path="/fornecedores" component={ManagerSupplierContainer} />
    <Route exact path="/montadoras" component={ManagerAutomakerContainer} />
    <Route exact path="/aplicacoes" component={ManagerApplicationContainer} />
    <Route exact path="/merchants" component={ManagerMerchantContainer} />
    <Route exact path="/administradores" component={ManagerAccountContainer} />
    <Route exact path="/pedidos" component={ManagerOrderContainer} />
    <Route exact path="/orcamentos" component={ManagerPreOrderContainer} />
    <Route exact path="/kits" component={ManagerKitContainer} />
    <Route exact path="/banners" component={ManagerBannerContainer} />
    <Route exact path="/cupons" component={ManagerCouponContainer} />
    <Route exact path="/vendedores" component={ManagerSalesPersonContainer} />
    <Route exact path="/marcas" component={ManagerBrandContainer} />
    <Route exact path="/newsletters" component={NewsLetterContainer} />
    <Route path="/newsletter/:id?" component={NewsLetterEditorContainer} />
    <Route
      exact
      path="/transportadoras"
      component={ManagerShippingClassContainer}
    />
    <Route
      exact
      path="/faixas-frete"
      component={ManagerZipCodeRangeContainer}
    />
    <Route
      exact
      path="/cadastrar-faixa-frete"
      component={ZipCodeRangeContainer}
    />
    <Route
      exact
      path="/editar-faixa-frete/:uuidZipCodeRange"
      component={ZipCodeRangeContainer}
    />
    <Route exact path="/servicos" component={ManagerShippingServiceContainer} />
    <Route
      exact
      path="/gerenciar-institucionais"
      component={ManagerInstitutionContainer}
    />
    <Route
      exact
      path="/orcamentos-pendentes"
      render={() => (
        <ManagerPreOrderContainer
          title="Orçamentos pendentes"
          pendents
          subtitle="Orçamentos que estão aguardando análise"
        />
      )}
    />
    <Route
      exact
      path="/orcamentos-analise"
      render={() => (
        <ManagerPreOrderContainer
          title="Orçamentos em análise"
          analysis
          subtitle="Orçamentos que estão em análise"
        />
      )}
    />
    <Route
      exact
      path="/orcamentos-liberado"
      render={() => (
        <ManagerPreOrderContainer
          title="Orçamentos liberados para o cliente"
          cart
          subtitle="Orçamentos liberados para o cliente analisar"
        />
      )}
    />
    <Route
      exact
      path="/orcamentos-analise-frete"
      render={() => (
        <ManagerPreOrderContainer
          title="Análise de frete"
          shippingAnalisys
          subtitle="Orçamentos que estão em análise de frete"
        />
      )}
    />
    <Route exact path="/" component={DashboardsContainer} />
    <Route exact path="/vendas/general" component={ManagerSalesContainer} />
    <Route exact path="/new-branch" component={BranchCreateContainer} />
    <Route exact path="/branches" component={BranchManagerContainer} />
    <Route
      exact
      path="/edit-branch/:uuidBranch"
      component={BranchCreateContainer}
    />
    <Route exact path="/campanhas" component={ManagerCampaignContainer} />
  </Switch>
);

/*Private route é responsável por verificar se o usuário está logado ou não. */
const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <LoginCenterPage {...props} />
      )
    }
  />
);
