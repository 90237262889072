import React, { useEffect, useState } from 'react';
import { validateEmail } from '~/helpers/utils/Validators';
import InputComponent from '../InputComponent';

/**
 * Input email
 * */
export default (props) => {
  const {
    onBlur,
    isValid,
    autoComplete = 'off',
    id = 'email',
    name = 'email',
    required = true,
  } = props;
  const [status, setStatus] = useState(isValid);
  const checkEmail = (e) => {
    const isValidEmail = validateEmail(e.target.value).isValid;
    setStatus(isValidEmail);
    onBlur && onBlur(e, isValidEmail);
  };
  // Use effect é necessário neste componente, pois a camada superior pode estar passando alguma info para o comp.
  // Por exemplo no caso, da comparação se e-mails são iguais no form de cadastro.
  useEffect(() => {
    setStatus(isValid);
  }, [isValid]);

  return (
    <InputComponent
      {...props}
      onBlur={checkEmail}
      isValid={status}
      autoComplete={autoComplete}
      id={id}
      name={name}
      type="email"
      required={required}
    />
  );
};
