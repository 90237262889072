import { extendObservable } from 'mobx';
import BaseModel from '../BaseModel';

class FileModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.name = data.name;
    this.size = data.size;
    this._preview = data.preview;
    this.path = data.path;
    this.metaTags = data.metaTags;
    this.metatag = data.metatag;
    this.file = data.file;
    this.localModified = data.localModified || false;
    extendObservable(this, {
      percentCompleted: 0,
    });
  }

  /**Atualiza valores do model de acordo com o file */
  updateFile(file) {
    this.localModified = true;
    this.file = file;
    this.path = file && file.path;
    this.name = file && file.name;
    this.size = file && file.size;
    this._preview = file && file.preview;
  }

  get preview() {
    return this._preview ? this._preview : this.path;
  }

  getFormData() {
    const metaData = JSON.stringify({ metaTags: this.metaTags });
    const form = new FormData();
    form.append('file', this.file);
    if (!this.uuid)
      form.append(
        'metadata',
        new Blob([metaData], { type: 'application/json' }),
        this.name
      );
    return form;
  }
}

export default FileModel;
