import BaseModel from '../BaseModel';

class GlobalConfigModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.daysToShowProductsWithoutStock = data.daysToShowProductsWithoutStock;
    this.daysToShowHighlightedProducts = data.daysToShowHighlightedProducts;
    this.minimumQuantityOfProductsDisplayedOnShowcase = data.minimumQuantityOfProductsDisplayedOnShowcase;
    this.name = 'Global';
  }
}

export default GlobalConfigModel;
