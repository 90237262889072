import React from 'react';

/**
 * @Name: LabelComponent
 * @Data: 2019
 * @Desc: Label padrão do sistema. 
 * @param  {string} name - Nome do Label
 * @param  {string} className - classe css
 * @param  {string} htmlFor - nome referente ao input
 * @param  {boolean} required - obrigatório ou não
 * @param  {string} size - tamanho do comp.
 */
const LabelComponent = (props) => {
    const { name, className, htmlFor, required, size, children } = props;
    const col = size ? size : '2';
    return (
        <label
            htmlFor={htmlFor ? htmlFor : name}
            className={className ? className : `col-lg-${col} control-label`}
            {...props}>
            {children}
            {name}
            {required && <em>*</em>}
        </label>
    );
}
export default LabelComponent;
