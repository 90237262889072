import BaseModel from "../BaseModel";

import DiscountModel from '~/models/voucher/Discount';

class VoucherBatchModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this['@class'] = "br.com.stoom.kernel.model.persistence.VoucherBatch";
    this.name = data.name;
    if(!isNaN(Number(data.maxUses)))
      this.maxUses = Number(data.maxUses);
    this.discount = new DiscountModel(data.discount);
    this.restrictions = [];
    this.vouchers = [];
    this.active = data.active;
  }
}

export default VoucherBatchModel;
