import React, { forwardRef } from 'react';
import InputComponent from '../InputComponent';
import { maskDate } from '~/helpers/utils/Masks';

/**Input de datas */
export default forwardRef((props, ref) => {
  const {
    name = 'date',
    type = 'text',
    id = 'date',
    maxLength = '10',
    minLength = '10',
    mask = maskDate,
  } = props;
  return (
    <InputComponent
      {...props}
      mask={mask}
      type={type}
      name={name}
      id={id}
      maxLength={maxLength}
      minLength={minLength}
      ref={ref}
    />
  );
});
