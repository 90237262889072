import React, { useEffect, useRef, memo } from 'react';
import { useField } from '@unform/core';
import InputMask from 'react-input-mask';
import BlockErrorComponent from '../BlockErrorComponent/BlockErrorComponent';

const UnformInputComponent = ({
  name,
  className,
  label,
  hidden,
  mask,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = '', registerField, error } = useField(name);
  const { type } = rest;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  return (
    <>
      {label && type !== 'hidden' && <label htmlFor={fieldName}>{label}</label>}
      {mask ? (
        <InputMask
          className={
            className
              ? !error
                ? className
                : `${className} input-error`
              : error
              ? 'input-error'
              : ''
          }
          autoComplete="off"
          ref={inputRef}
          id={fieldName}
          name={name}
          defaultValue={defaultValue}
          mask={mask}
          {...rest}
        />
      ) : (
        <input
          className={
            className
              ? !error
                ? className
                : `${className} input-error`
              : error
              ? 'input-error'
              : ''
          }
          autoComplete="off"
          ref={inputRef}
          id={fieldName}
          name={name}
          defaultValue={defaultValue}
          {...rest}
        />
      )}
      <BlockErrorComponent message={error} visible={error} />
    </>
  );
};

export default memo(UnformInputComponent);
