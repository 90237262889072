import { extendObservable, decorate, action } from 'mobx';
import AuthAPI from '../services/AuthAPI';
import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';

/**
 * Store que trata toda lógica de autenticação do usuário.
 */
class AuthStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, {
      isAuthenticated: false,
      loading: false,
      authoritie: false,
    });
    const rememberLogin = StorageUtil.getItem(KEYS.REMEMBER);
    if (rememberLogin === 'true') this.autoLogin();
  }

  /**Verifica se os dados salvos na storage são válidos e faz o login automaticamente.*/
  async autoLogin() {
    this.loading = true;
    //busca usuário salvo no localstorage,
    //caso exista verifica se as credenciais ainda são válidas
    const savedUser = StorageUtil.getItem(KEYS.USER_KEY);

    if (savedUser) {
      const result = await this.rootStore.usersStore.get(savedUser);

      this.isAuthenticated =
        !result.error &&
        (this.isSuperAdmin || this.isAdmin || this.isStoomAdmin);

      if (!this.isAuthenticated) {
        StorageUtil.cleanAll();
      }
    }
    this.loading = false;
  }

  get isStoomAdmin() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === 'STOOM';
  }

  /**Verifica se a credencial do usuário é de superadmin */
  get isSuperAdmin() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === 'SUPERADMIN';
  }

  /**Verifica se a credencial do usuário é de admin */
  get isAdmin() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === 'ADMIN';
  }

  /** Faz o login */
  async login(username, password) {
    this.loading = true;
    const result = await AuthAPI.login(username, password);
    this.isAuthenticated = !result.error && true;

    if (!this.isAuthenticated) {
      StorageUtil.cleanAll();
    }
    this.loading = false;
    return this.isAuthenticated;
  }

  /**Logout */
  async logout() {
    this.loading = true;
    await AuthAPI.logout();
    this.isAuthenticated = false;
    this.loading = false;
    return this.isAuthenticated;
  }

  /**Envia */
  async redefinePass(email) {
    const response = await AuthAPI.redefinePass(email);
    return response;
  }

  async validateToken(token) {
    const response = await AuthAPI.validateToken(token);
    return response;
  }

  async changePassword(customerUUID, token, newPassword) {
    const response = await AuthAPI.changePassword(
      customerUUID,
      token,
      newPassword
    );
    return response;
  }
}

export default AuthStore;

decorate(AuthStore, {
  autoLogin: action,
});
