import { extendObservable } from 'mobx';

import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import InterestRateModel from '~/models/InterestRateModel';
import InterestRateAPI from '~/services/InterestRateAPI';

const initValues = {
  loading: false,
  installments: [],
  page: 0,
  totalPages: 0,
  totalElements: 0,
  size: 12,
  sort: 'installment,asc'
};

class InterestRateStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
    this.toastHelper = new ToastHelper();
  }

  /**Adiciona uma parcela de pagamento */
  async handleAddInstallment(interest) {
    const installment = this.totalElements + 1;

    const interestCard = { installment, interest: interest/100 };

    const response = await InterestRateAPI.save(interestCard);

    if (!response.error) {
      await this.getList();
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Parcela adicionada com sucesso!');
      return true;
    }

    this.toastHelper.notify(STATUS_HELPER.ERROR, 'Erro ao adicionar a parcela.');
    return false;
  }

  /**Remove a parcela selecionada */
  async handleRemoveInstallment(interestCard) {
    const response = await InterestRateAPI.delete(interestCard.uuid);

    if (!response.error) {
      await this.setInstallmentPage();
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Parcela deletada com sucesso.');
      return true;
    }

    this.toastHelper.notify(STATUS_HELPER.ERROR, 'Erro ao deletar a parcela.');
    return false;
  }

  async handleUpdateInstallment(interestCard, interest) {
    const interestCardObject = interestCard;

    interestCardObject.interest = interest/100;

    const response = await InterestRateAPI.update(interestCard, interestCard.uuid);

    if (!response.error) {
      await this.getList();
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Parcela atualizada com sucesso!');
      return true;
    }

    this.toastHelper.notify(STATUS_HELPER.ERROR, 'Erro ao atualizar a parcela.');
    return false;
  }

  /**Buscas as parcelas */
  async setInstallmentPage(page = 0, size = 12) {
    this.page = page;
    this.size = size;
    await this.getList();
  }

  /**Busca lista de parcelas */
  async getList() {
    this.loading = true;
    const params = { size: this.size, page: this.page, sort: this.sort };
    const response = await InterestRateAPI.getList(params);
    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return [];
    }
    this.installments = response.content.map((ins) => new InterestRateModel(ins));
    this.totalPages = response.totalPages;
    this.totalElements = response.totalElements;
    this.page = response.number;
  }
}

export default InterestRateStore;
