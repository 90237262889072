import axios from 'axios';
import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';
import { decodeJWT } from '~/helpers/utils/Functions';
import NotificationStore from '~/stores/NotificationStore';

/** Classe base que fará a comunicação com o backend.
 * Esta classe trata as configurações de headers, paths e todas as urls(endpoints) deverão ser inseridos aqui
 */
class BaseAPI {
  /** Métodos get */
  static async get(endpoint, data = {}) {
    const requestConfig = { params: data };
    return await api.get(endpoint, requestConfig);
  }

  /** Métodos post */
  static async post(endpoint, data = {}) {
    return await api.post(endpoint, data);
  }

  /** Método Put */
  static async put(endpoint, data = {}) {
    return await api.put(endpoint, data);
  }

  /** Método delete */
  static async delete(endpoint, data = {}) {
    return await api.delete(endpoint, { data });
  }

  /** Método Patch */
  static async patch(endpoint, data = {}) {
    return await api.patch(endpoint, data);
  }
}

/** URL de acordo ao protocolo, hostname e endpoint */
export const baseURL = () => process.env.REACT_APP_API_URL;
// export const baseURL = () => 'https://avenida-api.dev.stoomlab.com.br/';

// Cria uma instancia de api
const api = axios.create({ baseURL: baseURL(), withCredentials: true });
api.interceptors.request.use(
  (config) => {
    const token = StorageUtil.getItem(KEYS.TOKEN_KEY);
    config.headers = { 'Content-Type': 'application/json; charset=utf-8' };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    if (error.response) {
      let message;

      if (error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }

      if (message) {
        if (error.response.status !== 422) {
          const notifier = new NotificationStore();

          notifier.notifyError(message);
        }
      }
    }
    return Promise.reject(error);
  }
);

// Intercepta os retornos.
// TODO fazer a lógica do refresh token.
api.interceptors.response.use(
  (response) => {
    const token = response.headers.authorization;

    if (token) {
      const currentToken = response.headers.authorization.split(' ')[1];

      const decode = decodeJWT(currentToken);

      StorageUtil.setItem(KEYS.AUTH_KEY, decode.sub.toUpperCase());
      StorageUtil.setItem(KEYS.TOKEN_KEY, currentToken);
    }
    return response;
  },
  (error) => {
    if (error.response) {
      let message = 'Erro inesperado';
      const notifier = new NotificationStore();

      switch (error.response.status) {
        case 400:
        case 500:
        case 505:
        case 503:
          notifier.notifyError(error?.response?.data?.message || message);
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

// Lista de todos os endpoints. Todos endpoints deverão ser inseridos aqui.
const MANAGER = '/manager';
export const URLS = {
  MANAGER,
  LOGIN: '/login',
  FILES: '/files',
  ORDERS: `${MANAGER}/orders`,
  SEARCHORDERS: `${MANAGER}/orders/search`,
  CUSTOMERS: `${MANAGER}/customers`,
  MERCHANT: `${MANAGER}/merchants`,
  SUPPLIER: `${MANAGER}/suppliers`,
  AUTOMAKER: `${MANAGER}/automakers`,
  SHIPPING: `${MANAGER}/shipping-company`,
  APPLICATION: `${MANAGER}/applications`,
  PRODUCTS: `${MANAGER}/avenida-products`,
  PRODUCTS_KERNEL: 'manager/products',
  CATEGORIES: `${MANAGER}/categories`,
  CUSTOM_CATEGORIES: `${MANAGER}/avenidaCategories`,
  CATEGORY: `${MANAGER}/category`,
  ACCOUNTS: `${MANAGER}/accounts`,
  CUSTOM_ACCOUNTS: `${MANAGER}/avenida-accounts`,
  PROPERTY: `${MANAGER}/properties`,
  CLASSES: `${MANAGER}/properties/classes`,
  CUSTOM_CLASSES: `${MANAGER}/avenida-properties/classes`,
  PRE_ORDERS: `${MANAGER}/preorders`,
  COMPARATIVE: `${MANAGER}/feature`,
  INSTITUTIONAL: `${MANAGER}/institutional`,
  LOOK: `${MANAGER}/looks`,
  FEATURE: `${MANAGER}/feature`,
  GLOBAL_CONFIG: `${MANAGER}/global-configuration`,
  STOCKS: `${MANAGER}/avenida-products/stocks`,
  BRANCHES: `${MANAGER}/branches`,
  COLORS: `${MANAGER}/colors`,
  SIZES: `${MANAGER}/sizes`,
  UNITS: `${MANAGER}/units`,
  NEWS_LETTER: `${MANAGER}/newsletters`,
  VOUCHERBATCH: `${MANAGER}/voucherBatches`,
  CAMPAIGNS: `${MANAGER}/campaigns`,
  BANNER: `${MANAGER}/banners`,
  ZIPCODERANGE: `${MANAGER}/zipcoderange`,
  SALES: `${MANAGER}/reports/sales-general`,
  SALES_DETAILS: `${MANAGER}/reports/sales-details`,
  SALES_PERSON: `${MANAGER}/salespersons`,
  ZIPCODERANGE: `${MANAGER}/zipcode-range`,
  SALES_PERSON: `${MANAGER}/salespersons`,
  INTEREST_RATE: `${MANAGER}/interest-rates`,
  CUSTOMER_GROUP: `${MANAGER}/customergroups`,
  CUSTOMER_AVENIDA: `${MANAGER}/avenida-customer`,
  FORGET_PASSWORD: '/forget-password',
  EXPORT_CSV: '/transfer/export-csv',
};

export default BaseAPI;
